import React from 'react';
import { useQuery } from '@apollo/client';

import {
  FetchPrivacyQuery,
  FetchPrivacyQueryVariables,
} from './__generated__/PrivacyValue.gql';
import { PrivacyValuePropsType } from './PrivacyValue.types';
import { FETCH_PRIVACY } from './PrivacyValue.gql';
import styles from './PrivacyValue.module.scss';

export default function PrivacyValue({ children }: PrivacyValuePropsType) {
  const { data } = useQuery<FetchPrivacyQuery, FetchPrivacyQueryVariables>(
    FETCH_PRIVACY
  );

  if (!children) {
    return null;
  }

  const privacy = data?.profile.privacy;

  if (!privacy) {
    return <>{children}</>;
  }

  return <div className={styles.privacy}>{children}</div>;
}
