import { useCallback, useEffect, useMemo, useState } from 'react';
import { history } from '@appclose/core';

import {
  PopupModalProviderPropsType,
  PopupModalProviderStateType,
} from './PopupModalProvider.types';
import { PopupModalProviderContext } from './PopupModalProvider.context';

export default function PopupModalProvider({
  children,
}: PopupModalProviderPropsType) {
  const [modals, setModals] = useState<PopupModalProviderStateType[]>([]);

  const handleOnOpen = useCallback(
    (id: any, Modal: any, onComplete: any, onCancel: any, data: any) => {
      setModals((currentModals) => [
        ...currentModals,
        {
          id,
          Modal,
          onComplete,
          onCancel,
          data,
        },
      ]);
    },
    []
  );

  const handleOnClose = useCallback((closeModalId: string) => {
    setModals((openModals) =>
      openModals.filter((modal) => modal.id !== closeModalId)
    );
  }, []);

  const handleOnClosehandleOnCancel = useCallback(() => {
    setModals([]);
  }, []);

  const contextValue = useMemo(
    () => ({
      onOpen: handleOnOpen,
      onClose: handleOnClose,
      onCancel: handleOnClosehandleOnCancel,
    }),
    [handleOnClose, handleOnOpen, handleOnClosehandleOnCancel]
  );

  useEffect(() => history.listen(handleOnClosehandleOnCancel), [
    handleOnClosehandleOnCancel,
  ]);

  return (
    <PopupModalProviderContext.Provider value={contextValue}>
      {children}
      {modals.map(({ Modal, onComplete, data, id }) => {
        const modalProps = {
          id,
          onComplete: (modalData: unknown) => {
            onComplete?.(modalData);
            handleOnClose(id);
          },
          onCancel: () => {
            handleOnClose(id);
          },
          ...(data || {}),
        };

        // @ts-ignore
        return <Modal key={id} {...modalProps} />;
      })}
    </PopupModalProviderContext.Provider>
  );
}
