import React from 'react';
import { SelectFormField } from '@appclose/core';

import useGenderTypes from 'hooks/useGenderTypes';

import { GenderSelectFormFieldPropsType } from './GenderSelectFormField.types';

export default function GenderSelectFormField({
  name = 'gender',
  label = 'Gender',
  ...props
}: GenderSelectFormFieldPropsType) {
  const { genderTypesOptions } = useGenderTypes();

  return (
    <SelectFormField
      name={name}
      label={label}
      options={genderTypesOptions}
      {...props}
    />
  );
}
