import * as Yup from 'yup';

import { FILES_SCHEMA } from 'schemas/validations/files';

import { ContactAndEntityFormValuesType } from './ContactForm.types';
import { ContactFieldsetSchema } from './components/ContactFieldset';
import { EntityFieldsetSchema } from './components/EntityFieldset';
import { ContactModalModalTabs } from '../../ContactModal.types';

export const ContactFormSchema = Yup.object().shape<
  Partial<ContactAndEntityFormValuesType>
>({
  contact: Yup.object<any>().when('selectedTab', {
    is: ContactModalModalTabs.INDIVIDUAL,
    then: ContactFieldsetSchema,
  }),
  entity: Yup.object<any>().when('selectedTab', {
    is: ContactModalModalTabs.ENTITY,
    then: EntityFieldsetSchema,
  }),
  files: FILES_SCHEMA,
});
