import gqlClient from 'gql';
import Analytics, { AnalyticsInstance } from 'analytics';
import { parse } from 'bowser';
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager';
import { dateManager, permissionProvider } from '@appclose/core';

import { GOOGLE_TAG_MANAGER_CONTAINER_ID } from 'constants/env';
import { EventNames } from 'constants/analytics';

import { TRACK_ANALYTICS_EVENT } from './analytics.gql';
import {
  TrackAnalyticsEventMutation,
  TrackAnalyticsEventMutationVariables,
} from './__generated__/analytics.gql';
import { EventType, DataType } from './analytics.types';
import { PermissionActions, PermissionResources } from 'constants/permissions';

let _analytics: AnalyticsInstance | null = null;

export function analytics() {
  if (!_analytics) {
    const plugins = [];

    if (GOOGLE_TAG_MANAGER_CONTAINER_ID) {
      plugins.push(
        googleTagManager({
          containerId: GOOGLE_TAG_MANAGER_CONTAINER_ID,
        })
      );
    }

    _analytics = Analytics({
      plugins,
    });
  }

  return _analytics;
}

export async function track(event: EventType, data?: DataType) {
  if (
    !permissionProvider().hasPermission(
      PermissionResources.ANALYTIC_EVENT,
      PermissionActions.CREATE
    )
  ) {
    return;
  }

  try {
    await gqlClient.mutate<
      TrackAnalyticsEventMutation,
      TrackAnalyticsEventMutationVariables
    >({
      mutation: TRACK_ANALYTICS_EVENT,
      variables: {
        event,
        timestamp: dateManager().parse().utc().unix(),
        data: {
          ...(data ? { data } : {}),
        },
      },
      errorPolicy: 'ignore',
    });
  } catch (e) {}
}

export async function trackSessionStatusChange(status: 'start' | 'end') {
  await track(
    status === 'start' ? EventNames.SESSION_START : EventNames.SESSION_END,
    getAdditionalCharacteristics()
  );
}

const getAdditionalCharacteristics = () => {
  const { browser, os, platform } = parse(window.navigator.userAgent);
  const { width, height } = window.screen;

  return {
    browser_name: browser.name,
    browser_version: browser.version,
    os_name: os.name,
    os_version: os.version,
    platform_type: platform.type,
    platform_vendor: platform.vendor,
    platform_model: platform.model,
    screen: `${width}x${height}`,
  };
};
