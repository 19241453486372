import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from 'components/common/Address';

export const FETCH_FIRM = gql`
  query FetchFirm {
    firm: getFirm {
      address {
        ...Address
      }
      banners
      businessStartedSince
      description
      email
      entityType
      id
      legalName
      name
      paymentLink
      phoneNumber {
        isPrimary
        number
        type
      }
      principal {
        firstName
        lastName
        phoneNumber
        dateOfBirth
        title
        address {
          ...Address
        }
      }
      logo {
        original
      }
      size
      stripeKybErrors
      stripeKybStatus
      timezone {
        full
        short
      }
      website
    }
  }
  ${ADDRESS_FRAGMENT}
`;
