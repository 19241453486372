import { useMemo } from 'react';
import classNames from 'classnames';
import VerificationInputBase, {
  VerificationInputProps,
} from 'react-verification-input';

import styles from './Verification.module.scss';

const defaultClasses = {
  container: styles.container,
  character: styles.input,
  characterInactive: styles.inactive,
  characterSelected: styles.focus,
  characterFilled: styles.input,
};

type VerificationProps = VerificationInputProps & {
  error?: boolean;
};

export const Verification = ({
  length = 6,
  placeholder = '0',
  error,
  ...rest
}: VerificationProps) => {
  const classes = useMemo(() => {
    if (error) {
      return {
        ...defaultClasses,
        character: classNames(styles.input, styles.error),
      };
    }

    return defaultClasses;
  }, [error]);

  return (
    <VerificationInputBase
      length={length}
      placeholder={placeholder}
      classNames={classes}
      {...rest}
    />
  );
};
