import { Amount, FormGroup, StaticField, Fieldset } from '@appclose/core';

import { BankAccountClasses } from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import InvoiceStatus from 'components/common/InvoiceStatus';
import StaticFilesField from 'components/common/StaticFilesField';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import { sanitizeAmount } from 'controllers/amount';
import { getContactName } from 'controllers/contact';
import useBankAccountClasses from 'hooks/useBankAccountClasses';
import { useIntl } from 'i18n';
import { InfoPropsType } from './Info.types';

export default function Info({
  invoice: {
    id,
    contact,
    invoiceNumber,
    destinationAccount,
    dueDate,
    internalMemo,
    requestedAmount,
    originalAmount,
    discount: rawDiscount,
    percentageDiscount,
    status,
    qboSyncState,
  },
  files,
}: InfoPropsType) {
  const { getBankAccountClassLabel } = useBankAccountClasses();
  const { t } = useIntl();
  const isOperatingAccount =
    destinationAccount === BankAccountClasses.OPERATING;

  const discount = sanitizeAmount(rawDiscount);

  return (
    <>
      <Fieldset title={t('modal.invoiceDetails.info.fieldset.details')}>
        <FormGroup>
          <FormGrid>
            <StaticField title={t('modal.invoiceDetails.info.field.contact')}>
              {getContactName(contact)}
            </StaticField>
            <StaticField
              title={t('modal.invoiceDetails.info.field.referenceId')}
            >
              {invoiceNumber}
            </StaticField>
            <StaticField
              title={t('modal.invoiceDetails.info.field.destinationAccount')}
            >
              {getBankAccountClassLabel(destinationAccount)}
            </StaticField>
            <StaticField title={t('modal.invoiceDetails.info.field.dueDate')}>
              <Date value={dueDate} />
            </StaticField>
          </FormGrid>
        </FormGroup>

        <FormGroup>
          <StaticField
            title={t('modal.invoiceDetails.info.fieldset.qboSyncStatus')}
          >
            <EntityQboSyncStatus
              entityId={id}
              entityType="invoice"
              qboSyncState={qboSyncState || undefined}
            />
          </StaticField>
        </FormGroup>
      </Fieldset>
      <Fieldset title={t('modal.invoiceDetails.info.fieldset.amount')}>
        <FormGrid>
          {isOperatingAccount && (
            <>
              <StaticField
                title={t('modal.invoiceDetails.info.field.expenses')}
              >
                <Amount value={originalAmount} />
              </StaticField>
              <StaticField
                title={t('modal.invoiceDetails.info.field.discount')}
              >
                <Amount
                  value={
                    percentageDiscount
                      ? sanitizeAmount((originalAmount / 100) * discount)
                      : discount
                  }
                />
                {percentageDiscount && ` (${discount}%)`}
              </StaticField>
            </>
          )}

          <StaticField
            title={t('modal.invoiceDetails.info.field.requestedAmount')}
          >
            <Amount value={requestedAmount} />
          </StaticField>
          <StaticField title={t('modal.invoiceDetails.info.field.status')}>
            <InvoiceStatus status={status} />
          </StaticField>
        </FormGrid>
      </Fieldset>

      {(internalMemo || !!files?.length) && (
        <Fieldset
          title={t('modal.invoiceDetails.info.fieldset.additionalInfo')}
        >
          {internalMemo && (
            <FormGroup>
              <StaticField
                title={t('modal.invoiceDetails.info.field.internalNotes')}
              >
                {internalMemo}
              </StaticField>
            </FormGroup>
          )}
          {!!files?.length && (
            <FormGroup>
              <StaticFilesField files={files} />
            </FormGroup>
          )}
        </Fieldset>
      )}
    </>
  );
}
