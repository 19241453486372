import React from 'react';
import { Table, TableBody, TableRow, TableCell } from '@appclose/ui';

import NodeIcon from 'components/common/NodeIcon';
import NodeName from 'components/common/NodeName';

import { NodesTablePropsType } from './NodesTable.types';

export default function NodesTable({
  nodes,
  onNodeClick,
}: NodesTablePropsType) {
  return (
    <Table>
      <TableBody>
        {nodes.map(({ id, isFolder, isSystemFolder, attach, name }) => (
          <TableRow onClick={onNodeClick({ id, isFolder })} key={id}>
            <TableCell skin="icon">
              <NodeIcon
                isFolder={isFolder}
                isSystemFolder={isSystemFolder}
                attach={attach}
              />
            </TableCell>
            <TableCell>
              <NodeName name={name} isFolder={isFolder} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
