import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import set from 'lodash/set';
import { useFormContext, EnhancedFormArrayField } from '@appclose/core';

import { useIntl } from 'i18n';
import useProfile from 'hooks/useProfile';
import { SignerTypes } from 'constants/esign';

import {
  SignersFormFieldPropsType,
  SignersFormFieldValuesType,
} from './SignersFormField.types';
import { FETCH_SIGNATURE_TEMPLATE_ROLES } from './SignersFormField.gql';
import {
  FetchSignatureTemplateRolesQuery,
  FetchSignatureTemplateRolesQueryVariables,
} from './__generated__/SignersFormField.gql';
import Signer from './components/Signer';

export default function SignersFormField({
  withoutTemplate = false,
  className,
}: SignersFormFieldPropsType) {
  const { t } = useIntl();
  const { loading: profileLoading, profile } = useProfile();
  const {
    setValues,
    values: { templateId, signers, completeInOrder = false },
  } = useFormContext<SignersFormFieldValuesType>();
  const { loading, data } = useQuery<
    FetchSignatureTemplateRolesQuery,
    FetchSignatureTemplateRolesQueryVariables
  >(FETCH_SIGNATURE_TEMPLATE_ROLES, {
    variables: {
      templateId: templateId as string,
    },
    skip: !templateId,
    fetchPolicy: 'network-only',
  });
  const roles = useMemo(() => data?.roles.signerRoles || [], [
    data?.roles.signerRoles,
  ]);
  const name = 'signers';

  useEffect(() => {
    if (roles.length) {
      setValues((values) =>
        set(
          values,
          name,
          roles.map(({ id }) => ({
            type: SignerTypes.CONTACT,
            roleId: id,
          }))
        )
      );
    }
  }, [roles, setValues]);

  useEffect(() => {
    if (
      profile &&
      signers.find(({ teamMember }) => teamMember?.id === profile.id)
    ) {
      setValues((values) => set(values, 'sendCompletedToCreator', undefined));
    }
  }, [profile, signers, setValues]);

  return templateId || withoutTemplate ? (
    <EnhancedFormArrayField
      name={name}
      isLoading={loading || profileLoading}
      deletable={withoutTemplate}
      sortable={completeInOrder}
      addButtonLabel={
        withoutTemplate
          ? t('modal.signatureDocument.form.signers.addButton')
          : undefined
      }
      allowAddNew={withoutTemplate}
      allowRemoveLast={false}
      initialData={withoutTemplate ? { type: SignerTypes.CONTACT } : undefined}
      className={className}
    >
      {({ index, resolveName }) => (
        <Signer
          role={roles[index]}
          index={index}
          resolveName={resolveName}
        />
      )}
    </EnhancedFormArrayField>
  ) : null;
}
