import { gql } from '@apollo/client';

import { INVOICE_INFO_FRAGMENT } from 'components/common/InvoiceInfo';
import { FIRM_INFO_FRAGMENT } from 'components/common/FirmInfo';

export const FETCH_INVOICE = gql`
  query FetchInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      ...InvoiceInfo
      contact {
        email
        isEntity
        billingContacts {
          id
          firstName
          lastName
          email
          type
        }
      }
      message
      internalMemo
      voidInvoice {
        id
      }
    }
    firm: getFirm {
      ...FirmInfo
    }
  }
  ${INVOICE_INFO_FRAGMENT}
  ${FIRM_INFO_FRAGMENT}
`;

export const SEND_VOID_INVOICE = gql`
  mutation SendVoidInvoice($sendVoidInvoiceInput: SendVoidInvoiceInput!) {
    sendVoidInvoice(sendVoidInvoiceInput: $sendVoidInvoiceInput) {
      id
    }
  }
`;
