import { ChipsInput, ChipsInputPropsType } from '@appclose/ui';

import field, { FiledPropsType } from '../../../../hoc/field';

const ChipsInputField = (props: FiledPropsType<ChipsInputPropsType>) =>
  field<ChipsInputPropsType & Pick<FiledPropsType<undefined>, 'hidden'>>({
    ...props,
  })(ChipsInput);

export default ChipsInputField;
