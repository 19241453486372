import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import variables from '@appclose/ui/src/scss/_export.module.scss';
import { Amount, useFormContext } from '@appclose/core';
import { DueIcon } from '@appclose/icons';

import FinancialBlock from 'components/common/FinancialBlock';
import { sanitizeAmount } from 'controllers/amount';
import useOnFinancialTilesUpdated from 'hooks/useOnFinancialTilesUpdated';
import { useIntl } from 'i18n';

import { FETCH_BANK_ACCOUNT_DUE } from './BankAccountDueStatistic.gql';
import { BankAccountDueStatisticPropsType } from './BankAccountDueStatistic.types';
import styles from './BankAccountDueStatistic.module.scss';
import {
  FetchBankAccountDueQuery,
  FetchBankAccountDueQueryVariables,
} from './__generated__/BankAccountDueStatistic.gql';
import { ReceivePaymentFormValuesType } from '../../PaymentInfoFormsBlock.types';

export default function BankAccountDueStatistic({
  bankAccountClass,
}: BankAccountDueStatisticPropsType) {
  const { t } = useIntl();
  const {
    values: { contact },
  } = useFormContext<ReceivePaymentFormValuesType>();
  const contactId = contact?.id;
  const [
    fetchBankAccountDueStatistic,
    { loading, data, refetch },
  ] = useLazyQuery<FetchBankAccountDueQuery, FetchBankAccountDueQueryVariables>(
    FETCH_BANK_ACCOUNT_DUE,
    {
      fetchPolicy: 'network-only',
    }
  );
  const statistic = sanitizeAmount(data?.statistic.due);

  useEffect(() => {
    fetchBankAccountDueStatistic({
      variables: {
        input: {
          bankAccountClass,
          contactId,
        },
      },
    });
  }, [fetchBankAccountDueStatistic, bankAccountClass, contactId]);

  useOnFinancialTilesUpdated(refetch);

  return (
    <FinancialBlock
      className={styles.block}
      title={t('modal.receivePayment.bankAccountDueStatistic.title.operating')}
      icon={{
        bgColor: variables.colorSystemWarning,
        content: <DueIcon width={28} height={28} fill="#fff" />,
      }}
      value={!loading && <Amount value={statistic} />}
      loading={loading}
    />
  );
}
