import { gql } from '@apollo/client';

export const FETCH_PAYMENT = gql`
  query FetchPayment($id: ID!) {
    payment: getPayment(id: $id) {
      contact {
        id
        name
      }
      id
      amount
      paymentMethod
      refundAmount
      createdAt
      truncatedPAN
    }
  }
`;

export const REFUND_PAYMENT = gql`
  mutation RefundPayment($createRefundPaymentInput: CreateRefundPaymentInput!) {
    createRefundPayment(createRefundPaymentInput: $createRefundPaymentInput) {
      id
      refundPayment {
        id
        amount
        refundAmount
      }
      refundNumber
    }
  }
`;
