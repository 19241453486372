import React from 'react';

import { ModalPageContentPropsType } from './ModalPageContent.types';

export default function ModalPageContent({
  className,
  children,
}: ModalPageContentPropsType) {
  return <div className={className}>{children}</div>;
}
