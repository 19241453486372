import * as Yup from 'yup';
import { URL_VALIDATION_SCHEMA } from '@appclose/core';

import { FirmEntityTypes } from '__generated__/globalTypes';

import { getI18nErrorMessage } from 'i18n';

export const FIRM_LEGAL_NAME_VALIDATION_SCHEMA = Yup.string()
  .min(2, ({ min }) =>
    getI18nErrorMessage('error.firm.legalName.minLength', { min })
  )
  .max(50, ({ max }) =>
    getI18nErrorMessage('error.firm.legalName.maxLength', { max })
  )
  .matches(
    /^[\dA-Za-z\s-.]+$/,
    getI18nErrorMessage('error.firm.legalName.notMatch')
  )
  .required(getI18nErrorMessage('error.firm.legalName.required'));

export const FIRM_OWNERSHIP_TYPE_VALIDATION_SCHEMA = Yup.mixed<
  FirmEntityTypes
>().required(getI18nErrorMessage('error.firm.ownershipType.required'));

export const FIRM_TAX_ID_VALIDATION_SCHEMA = Yup.string()
  .matches(
    /^(\d{3}-?\d{2}-?\d{4})|(\d{2}-?\d{7})$/,
    getI18nErrorMessage('error.firm.taxId.notMatch')
  )
  .required(getI18nErrorMessage('error.firm.taxId.required'));

export const FIRM_BUSINESS_START_DATE_VALIDATION_SCHEMA = Yup.mixed<
  string
>().required(getI18nErrorMessage('error.firm.businessStartedSince.required'));

export const FIRM_WEBSITE_VALIDATION_SCHEMA = URL_VALIDATION_SCHEMA.required(
  getI18nErrorMessage('error.firm.website.required')
).max(50, ({ max }) =>
  getI18nErrorMessage('error.firm.website.maxLength', { max })
);

export const FIRM_DESCRIPTION_VALIDATION_SCHEMA = Yup.string()
  .required(getI18nErrorMessage('error.firm.description.required'))
  .min(2, ({ min }) =>
    getI18nErrorMessage('error.firm.description.minLength', { min })
  )
  .max(50, ({ max }) =>
    getI18nErrorMessage('error.firm.description.maxLength', { max })
  );
