import React from 'react';
import { Flex } from '@appclose/ui';
import { InfiniteScroll, Loader } from '@appclose/core';

import EmptyContent from 'components/common/EmptyContent';

import { EmbedFileExplorerPropsType } from './EmbedFileExplorer.types';
import styles from './EmbedFilesExplorer.module.scss';
import NodesTable from './components/NodesTable';

export default function EmbedFileExplorer({
  breadcrumbs,
  loading,
  nodes,
  onLoadMore,
  onNodeClick,
}: EmbedFileExplorerPropsType) {
  const hasNodes = nodes.length > 0;

  return (
    <Flex direction="column" className={styles.container}>
      {breadcrumbs && <div className={styles.breadcrumbs}>{breadcrumbs}</div>}
      {loading ? (
        <Loader />
      ) : (
        (!hasNodes && <EmptyContent />) || (
          <InfiniteScroll useParentContainer onLoadMore={onLoadMore}>
            <NodesTable nodes={nodes || []} onNodeClick={onNodeClick} />
          </InfiniteScroll>
        )
      )}
    </Flex>
  );
}
