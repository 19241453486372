import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Loader, Modal } from '@appclose/core';
import { Button } from '@appclose/ui';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { I18n, useIntl } from 'i18n';

import { STRIPE_API_KEY } from 'constants/env';
import notification from 'controllers/notification';

import { FetchTrustFeesAccountIntentQuery } from './__generated__/ConnectFeeBankAccountModal.gql';

import { FETCH_TRUST_FEES_ACCOUNT_INTENT } from './ConnectFeeBankAccountModal.gql';
import { ConnectFeeBankAccountModalPropsType } from './ConnectFeeBankAccountModal.types';
import styles from './ConnectFeeBankAccountModal.module.scss';

export default function ConnectFeeBankAccountModal({
  onComplete,
  onCancel,
}: ConnectFeeBankAccountModalPropsType) {
  const { t } = useIntl();
  const stripeRef = useRef<Stripe | null>(null);
  const elementsRef = useRef<StripeElements | null>(null);
  const [confirmInProgress, setConfirmInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { data } = useQuery<FetchTrustFeesAccountIntentQuery>(
    FETCH_TRUST_FEES_ACCOUNT_INTENT
  );

  const { clientSecret } = data?.getTrustFeesAccountIntent || {};

  const handleOnCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSubmit = async () => {
    if (!stripeRef.current || !elementsRef.current) {
      return;
    }

    const { error: submitError } = await elementsRef.current.submit();

    if (submitError) {
      return;
    }

    setConfirmInProgress(true);
    const res = await stripeRef.current.confirmSetup({
      elements: elementsRef.current,
      clientSecret: clientSecret!!,
      confirmParams: {},
      redirect: 'if_required',
    });
    setConfirmInProgress(false);

    if (res.error) {
      notification().error(new Error(res.error.message));

      return;
    }

    onComplete();
  };

  useEffect(() => {
    if (!clientSecret) {
      return;
    }

    loadStripe(STRIPE_API_KEY).then((stripe) => {
      stripeRef.current = stripe;

      elementsRef.current = stripe!.elements({ clientSecret });
      const setupEl = elementsRef.current.create('payment');
      setupEl.on('ready', () => setIsLoading(false));

      setupEl.mount('#payment-form');
    });
  }, [clientSecret]);

  return (
    <Modal
      className={styles.modal}
      title={t('modal.popup.connectFeesBankAccount.title')}
      onClose={handleOnCancel}
    >
      <p className={styles.noteText}>
        <I18n id="modal.popup.connectFeesBankAccount.note" />
      </p>
      {isLoading && <Loader />}
      <div id="payment-form" />
      <Button
        disabled={isLoading}
        skin="brand"
        loading={confirmInProgress}
        onClick={handleSubmit}
      >
        <I18n id="modal.popup.connectFeesBankAccount.actions.submit" />
      </Button>
    </Modal>
  );
}
