import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text4,
  Text5,
} from '@appclose/ui';
import { Ellipsis, EmailExternalLink, useIsTabletDevice } from '@appclose/core';

import { getContactName } from 'controllers/contact';
import Date from 'components/common/Date';
import ContactEntityIcon from 'components/common/ContactEntityIcon';
import Avatar from 'components/common/Avatar';
import DocumentSignerStatus from 'components/common/DocumentSignerStatus';

import { SignersPropsType } from './Signers.types';
import styles from './Signers.module.scss';
import classnames from 'classnames';

export default function Signers({ signers }: SignersPropsType) {
  const isTabletDevice = useIsTabletDevice();

  return (
    <Table>
      <TableBody>
        {signers.map(
          ({ id, teamMember, contact, externalSigner, updatedAt, status }) => {
            const unionContact = teamMember || contact || externalSigner;
            const picture = teamMember?.picture;
            const name = getContactName(unionContact || {});
            const email = unionContact?.email;
            const isEntity =
              (contact?.isEntity && !teamMember && !externalSigner) || false;
            const avatar = isEntity ? (
              <ContactEntityIcon isEntity={true} />
            ) : (
              <Avatar icon={picture?.thumbnail} size={24} />
            );

            return (
              <TableRow key={id} withHover={false}>
                {!isTabletDevice ? (
                  <>
                    <TableCell
                      className={classnames(
                        styles.noWrap,
                        styles.fixedWidthColumn
                      )}
                    >
                      <Grid
                        gap={[8]}
                        columns="24px 1fr"
                        justifyContent="start"
                        align="center"
                      >
                        {avatar}
                        <Ellipsis as="span">{name}</Ellipsis>
                      </Grid>
                    </TableCell>
                    <TableCell className={styles.fixedWidthColumn}>
                      <Ellipsis as="span">
                        <Text4 weight="semibold" color="secondary">
                          <EmailExternalLink email={email} />
                        </Text4>
                      </Ellipsis>
                    </TableCell>
                    <TableCell noWordBreak>
                      <Text5 color="secondary">
                        <Date value={updatedAt} />
                      </Text5>
                    </TableCell>
                    <TableCell>
                      <DocumentSignerStatus status={status} />
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{avatar}</TableCell>
                    <TableCell>
                      {name}
                      <Ellipsis as="span">
                        <Text4 color="secondary" offset={{ top: 10 }}>
                          <EmailExternalLink email={email} />
                        </Text4>
                      </Ellipsis>
                    </TableCell>
                    <TableCell noWordBreak>
                      <Text5 color="secondary" offset={{ bottom: 7 }}>
                        <Date value={updatedAt} />
                      </Text5>
                      <DocumentSignerStatus status={status} />
                    </TableCell>
                  </>
                )}
              </TableRow>
            );
          }
        )}
      </TableBody>
    </Table>
  );
}
