import React, { useCallback } from 'react';
import { Button } from '@appclose/ui';
import {
  FieldError,
  Fieldset,
  FormGroup,
  InputFormField,
  RadioFormField,
  StaticField,
  useFormContext,
  usePopupModal,
} from '@appclose/core';
import { I18n, useIntl } from 'i18n';
import FormGrid from 'components/common/FormGrid';
import SelectGoogleDriveSyncFolderPopupModal from 'components/modals/popups/SelectGoogleDriveSyncFolderPopupModal';
import useGoogleDriveSync from 'hooks/useGoogleDriveSync';
import {
  GoogleDriveSyncOptionsFormValuesType,
  SyncFolderType,
} from '../../GoogleDriveSyncOptionsModal.types';

export default function GoogleFolderFieldset() {
  const { t } = useIntl();
  const { normalizeDriveId } = useGoogleDriveSync();
  const {
    openPopupModal: onOpenSelectGoogleDriveSyncFolderPopupModal,
  } = usePopupModal(SelectGoogleDriveSyncFolderPopupModal);
  const {
    values: { syncFolderType, breadcrumbs, drive, folderId, folderName },
    setValues,
  } = useFormContext<GoogleDriveSyncOptionsFormValuesType>();

  const onSelectFolder = useCallback(() => {
    onOpenSelectGoogleDriveSyncFolderPopupModal({
      breadcrumbs: breadcrumbs || [],
      drive: {
        id: normalizeDriveId(drive?.id),
        name: drive?.name,
      },
      folder:
        folderId && folderName
          ? {
              id: folderId,
              name: folderName,
            }
          : undefined,
      onSelect: (selectedFolder, selectedFolderBreadcrumbs) => {
        setValues(
          (values) => ({
            ...values,
            breadcrumbs: selectedFolderBreadcrumbs,
            folderName: selectedFolder?.name,
            folderId: selectedFolder?.id,
          }),
          true
        );
      },
    });
  }, [
    breadcrumbs,
    drive,
    folderId,
    folderName,
    normalizeDriveId,
    onOpenSelectGoogleDriveSyncFolderPopupModal,
    setValues,
  ]);

  return (
    <Fieldset
      title={<I18n id="modal.googleDriveSyncOptions.fieldset.folder" />}
    >
      <FormGroup>
        <FormGrid>
          <RadioFormField
            name="syncFolderType"
            value={SyncFolderType.NEW}
            label={t('modal.googleDriveSyncOptions.field.folder.new')}
          />
          <RadioFormField
            name="syncFolderType"
            value={SyncFolderType.EXISTING}
            label={t('modal.googleDriveSyncOptions.field.folder.existing')}
          />
        </FormGrid>
      </FormGroup>
      <FormGrid>
        {syncFolderType === SyncFolderType.NEW && (
          <InputFormField
            name="newFolderName"
            label={t('modal.googleDriveSyncOptions.field.folder.label')}
          />
        )}
        {syncFolderType === SyncFolderType.EXISTING && (
          <StaticField
            title={t('modal.googleDriveSyncOptions.field.folder.label')}
          >
            <Button skin="link" onClick={onSelectFolder}>
              {folderName ?? (
                <I18n id="modal.googleDriveSyncOptions.field.folder.placeholder" />
              )}
              <FieldError name="folderId" />
            </Button>
          </StaticField>
        )}
      </FormGrid>
    </Fieldset>
  );
}
