import React from 'react';
import { Form } from '@appclose/core';

import { InvoiceFormPropsType } from './InvoiceForm.types';
import { InvoiceFormSchema } from './InvoiceForm.schema';
import InvoiceFormContent from './components/InvoiceFormContent';

export default function InvoiceForm({
  initialValues,
  isEdit,
  bankAccounts,
  onSubmit,
  onCancel,
  onChange,
}: InvoiceFormPropsType) {
  return (
    <Form
      initialValues={initialValues}
      validationSchema={InvoiceFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {() => (
        <InvoiceFormContent
          isEdit={isEdit}
          bankAccounts={bankAccounts}
          onCancel={onCancel}
        />
      )}
    </Form>
  );
}
