import React from 'react';
import classnames from 'classnames';

import { TextPropsType, TextNPropsType } from './Text.types';
import styles from './Text.module.scss';

export function Text({
  children,
  as: Component = 'p',
  weight = 'regular',
  level,
  color,
  offset,
  align: textAlign,
  opacity,
  className,
  onClick,
  ...rest
}: TextPropsType) {
  const margins = {
    marginTop: offset?.top,
    marginRight: offset?.right,
    marginBottom: offset?.bottom,
    marginLeft: offset?.left,
  };

  return (
    <Component
      onClick={onClick}
      style={{ ...margins, opacity, textAlign }}
      className={classnames(styles.text, className, {
        [styles.textLevel1]: level === 1,
        [styles.textLevel2]: level === 2,
        [styles.textLevel3]: level === 3,
        [styles.textLevel4]: level === 4,
        [styles.textLevel5]: level === 5,
        [styles.light]: weight === 'light',
        [styles.regular]: weight === 'regular',
        [styles.semibold]: weight === 'semibold',
        [styles.bold]: weight === 'bold',
        [styles.extraBold]: weight === 'extraBold',
        [styles.black]: weight === 'black',
        [styles.primary]: color === 'primary',
        [styles.secondary]: color === 'secondary',
        [styles.contrast]: color === 'contrast',
        [styles.gray]: color === 'gray',
        [styles.gray030]: color === 'gray030',
        [styles.warning]: color === 'warning',
        [styles.links]: color === 'links',
      })}
      {...rest}
    >
      {children}
    </Component>
  );
}

export function Text1({ children, ...props }: TextNPropsType) {
  return (
    <Text level={1} {...props}>
      {children}
    </Text>
  );
}

export function Text2({ children, ...props }: TextNPropsType) {
  return (
    <Text level={2} {...props}>
      {children}
    </Text>
  );
}

export function Text3({ children, ...props }: TextNPropsType) {
  return (
    <Text level={3} {...props}>
      {children}
    </Text>
  );
}

export function Text4({ children, ...props }: TextNPropsType) {
  return (
    <Text level={4} {...props}>
      {children}
    </Text>
  );
}

export function Text5({ children, ...props }: TextNPropsType) {
  return (
    <Text level={5} {...props}>
      {children}
    </Text>
  );
}
