import React from 'react';
import { Amount } from '@appclose/core';

import { TransactionTypes } from '__generated__/globalTypes';

import { PaymentAmountPropsType } from './PaymentAmount.types';

export default function PaymentAmount({
  type,
  value,
  className,
}: PaymentAmountPropsType) {
  const isRefund = type === TransactionTypes.REFUND;

  return (
    <Amount
      value={isRefund && value > 0 ? -value : value}
      color={isRefund ? 'warning' : undefined}
      className={className}
    />
  );
}
