import { BANNED_FILES_EXTENSIONS } from 'constants/file';

export const isFileTypeAllowed = (file: File) => {
  if (!file.name.includes('.')) {
    return false;
  }

  const extension = (/[^.]*$/.exec(file.name) || [''])[0];

  return !BANNED_FILES_EXTENSIONS.includes(extension.toLowerCase());
};
