import { ElementType } from 'react';
import { preloader } from '@appclose/core';
import { session } from 'controllers/session';

export default function verifySession(WrappedComponent: ElementType) {
  return preloader(() => {
    if (session.hasAccessToken()) {
      return { isLogged: true };
    }

    return { isLogged: false };
  })(WrappedComponent);
}
