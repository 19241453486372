export const TOUR_SHOWN_STATUS_KEY = 'TOUR_SHOWN_STATUS';
export const ASIDE_MINIFY_STATUS_KEY = 'ASIDE_MINIFY_STATUS';
export const QBO_MAPPINGS_KEY = 'QBO_MAPPINGS_KEY';
export const RECONCILIATION_WARNING_HIDE_KEY = 'RECONCILIATION_WARNING_HIDE';
export const VIEW_MODE_STORAGE_KEY = 'VIEW_MODE_STORAGE';
export const PRIVACY_KEY = 'PRIVACY';
export const DEPOSIT_FEATURE_HIDE_WARNING_STATUS_KEY =
  'DEPOSIT_FEATURE_HIDE_WARNING_STATUS';
export const LAST_USAGE_KEY = 'LAST_USAGE';
export const PROMO_CODE_KEY = 'PROMO_CODE';
export const CALENDARS_SETTINGS = 'CALENDARS_SETTINGS';
export const CHAT_INFO_POPUP = 'CHAT_INFO_POPUP';
export const NO_PRO_CONTENT = 'NO_PRO_CONTENT';
export const TOUR_SEEN = 'TOUR_SEEN';
