import React from 'react';
import { Switch, Route as ReactRouterRoute } from 'react-router-dom';

import {
  SIGN_IN_ROUTE,
  EXCHANGE_TOKENS_ROUTE,
  RESET_PASSWORD_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  SETUP_PASSWORD_ROUTE,
  NOT_FOUND_ROUTE,
  SIGN_ROUTE,
  MOBILE_ROUTE,
  OPERATING_ACCOUNT_COMPLETED,
  KYB_ROUTE,
  SHARE_CHAT_REQUEST_ROUTE,
  EXPORT_CHAT_REQUEST_ROUTE,
  ADMIN_ROUTE,
  FORBIDDEN_ROUTE,
} from 'constants/routes';
import { session } from 'controllers/session';
import Route from 'components/common/Route';
import ProtectedRoute from 'components/common/ProtectedRoute';
import PublicRoute from 'components/common/PublicRoute';
import LayoutAsync from 'components/common/Layout/LayoutAsync';
import SignInPageAsync from 'components/pages/SignInPage/SignInPageAsync';
import ExchangeTokensAsync from 'components/pages/ExchangeTokens/ExchangeTokensAsync';
import ResetPasswordPageAsync from 'components/pages/ResetPasswordPage/ResetPasswordPageAsync';
import ChangePasswordPageAsync from 'components/pages/ChangePasswordPage/ChangePasswordPageAsync';
import SetupPasswordPageAsync from 'components/pages/SetupPasswordPage/SetupPasswordPageAsync';
import NotFoundPageAsync from 'components/common/NotFoundPage/NotFoundPageAsync';
import SignDocumentPageAsync from 'components/pages/SignDocumentPage/SignDocumentPageAsync';
import MobilePageAsync from 'components/pages/MobilePage/MobilePageAsync';
import OperatingAccountCompletedAsync from 'components/pages/OperatingAccountCompleted/OperatingAccountCompletedAsync';
import MerchantApplicationAsync from 'components/pages/OnboardingPage/components/MerchantApplication/MerchantApplicationAsync';
import ShareChatRequestPageAsync from 'components/pages/ShareChatRequestPage/ShareChatRequestPageAsync';
import ExportChatRequestPageAsync from 'components/pages/ExportChatRequestPage/ExportChatRequestPageAsync';
import BackofficePageAsync from 'components/pages/BackofficePage/BackofficePageAsync';
import BlacklistPageAsync from 'components/pages/BlacklistPage/BlacklistPageAsync';

export default function Router(): React.ReactElement {
  return (
    <Switch>
      <ReactRouterRoute
        exact
        path={NOT_FOUND_ROUTE}
        component={NotFoundPageAsync}
      />
      <Route exact path={SIGN_IN_ROUTE} component={SignInPageAsync} />

      <Route
        exact
        path={EXCHANGE_TOKENS_ROUTE}
        component={ExchangeTokensAsync}
      />

      <ReactRouterRoute
        exact
        path={SHARE_CHAT_REQUEST_ROUTE}
        component={ShareChatRequestPageAsync}
      />

      <ReactRouterRoute
        exact
        path={EXPORT_CHAT_REQUEST_ROUTE}
        component={ExportChatRequestPageAsync}
      />

      <ReactRouterRoute
        exact
        path={FORBIDDEN_ROUTE}
        component={BlacklistPageAsync}
      />

      <Route
        exact
        path={RESET_PASSWORD_ROUTE}
        component={ResetPasswordPageAsync}
      />
      <PublicRoute
        exact
        path={SETUP_PASSWORD_ROUTE}
        component={SetupPasswordPageAsync}
      />
      <PublicRoute
        exact
        path={CHANGE_PASSWORD_ROUTE}
        component={ChangePasswordPageAsync}
      />
      <PublicRoute exact path={SIGN_ROUTE} component={SignDocumentPageAsync} />
      <PublicRoute
        path={MOBILE_ROUTE}
        session={session}
        component={MobilePageAsync}
      />
      <ProtectedRoute path={KYB_ROUTE} component={MerchantApplicationAsync} />

      <ProtectedRoute path={ADMIN_ROUTE} component={BackofficePageAsync} />

      <ProtectedRoute
        path={OPERATING_ACCOUNT_COMPLETED}
        component={OperatingAccountCompletedAsync}
      />
      <ProtectedRoute component={LayoutAsync} />
    </Switch>
  );
}
