import { Input, InputType } from '@appclose/ui';
import React, { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import { MaskedInputFiledType } from './MaskedInput.types';

const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputFiledType>(
  ({ maskChar, unmaskValue, onChange, ...props }, ref) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (unmaskValue) {
        e.target.value = unmaskValue(e.target.value);
      }

      onChange && onChange(e);
    };

    return (
      <InputMask {...props} maskChar={maskChar || ''} onChange={handleOnChange}>
        {
          (((inputProps: InputType) => (
            <Input {...inputProps} ref={ref} />
          )) as unknown) as React.ReactNode
        }
      </InputMask>
    );
  }
);

export default MaskedInput;
