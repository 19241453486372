import {
  FormGroup,
  InputFormField,
  SecureInputFormField,
  Fieldset,
} from '@appclose/core';

import { I18n, useIntl } from 'i18n';
import FormGrid from 'components/common/FormGrid/FormGrid';

import styles from './OperatingAccountFieldset.module.scss';
import BankAccountSelectFormField from 'components/fields/BankAccountTypeSelectFormField';

export default function OperatingAccountFieldset() {
  const { t } = useIntl();

  return (
    <Fieldset
      title={t('form.merchantApplication.operatingAccountFieldset.title')}
    >
      <p className={styles.operatingNote}>
        <I18n id="form.merchantApplication.operatingAccountFieldset.note" />
      </p>
      <FormGroup>
        <FormGrid>
          <SecureInputFormField
            name="bankAccount.accountNumber"
            label={t(
              'form.merchantApplication.operatingAccountFieldset.accountNumber'
            )}
            mask="99999999999999999"
          />
          <InputFormField
            name="bankAccount.routingNumber"
            label={t(
              'form.merchantApplication.operatingAccountFieldset.routingNumber'
            )}
            placeholder="XXXXXXXXX"
            mask="999999999"
          />
          <InputFormField
            name="bankAccount.holderName"
            label={t('form.merchantApplication.operatingAccountFieldset.holderName')}
          />
          <BankAccountSelectFormField name='bankAccount.type' />
        </FormGrid>
      </FormGroup>
    </Fieldset>
  );
}
