import { gql } from '@apollo/client';

import { QBO_ACCOUNT_SELECT_FRAGMENT } from './components/QboAccountSelectFormField';
import { QBO_SERVICE_ITEM_SELECT_FRAGMENT } from './components/QboServiceItemSelectFormField';

export const GET_QBO_INFO_PREFERENCES = gql`
  query GetQboMappingsPreferences {
    qboMappings: getQboMappingsPreferences {
      mappings {
        operatingAccount
        trustAccount
        timeEntryItem
        flatFeeItem
        expenseItem
        refundItem
        creditCardFeesAccount
        commonInvoiceItem
        trustDepositAccount
        trustDepositItem
      }
    }
    defaultResources: getQboResourcesDetails {
      defaultDiscountAccount
      accounts {
        ...QboAccountSelect
      }
      serviceItems {
        ...QboServiceItemSelect
      }
    }
    qbo: getQboIntegrationDetails {
      syncStatus
    }
  }
  ${QBO_ACCOUNT_SELECT_FRAGMENT}
  ${QBO_SERVICE_ITEM_SELECT_FRAGMENT}
`;

export const SAVE_QBO_MAPPINGS_PREFERENCES = gql`
  mutation SaveQboMappingsPreferences($input: QboSaveMappingsInput!) {
    saveQboMappingsPreferences(qboSaveMappingsInput: $input) {
      operatingAccount
      trustAccount
      timeEntryItem
      flatFeeItem
      expenseItem
      refundItem
      creditCardFeesAccount
      commonInvoiceItem
      trustDepositAccount
      trustDepositItem
    }
  }
`;
