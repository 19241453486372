import { gql } from '@apollo/client';

export const FETCH_GOOGLE_DRIVES = gql`
  query FetchGoogleDrives($nextPageToken: String) {
    googleDrives: getDrivesFromGoogle(
      getDrivesInput: { nextPageToken: $nextPageToken }
    ) {
      drives {
        id
        name
      }
      nextPageToken
    }
  }
`;
