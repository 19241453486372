import * as Yup from 'yup';

import { PhoneNumberInput, PhoneTypes } from '__generated__/globalTypes';

export const PHONE_FIELDSET_SCHEMA = Yup.array<PhoneNumberInput>(
  Yup.object()
    .shape({
      number: Yup.string(),
      type: Yup.mixed<PhoneTypes>(),
      isPrimary: Yup.boolean(),
    })
    .required()
);
