import { useMemo } from 'react';
import { useIntl } from 'i18n';
import { PersonRelationship } from '__generated__/globalTypes';

export default function useAccountType() {
  const { t } = useIntl();
  const personRelationshipTypes: Record<PersonRelationship, string> = useMemo(
    () => ({
      [PersonRelationship.OWNER]: t('enum.personRelationshipTypes.owner'),
      [PersonRelationship.EXECUTIVE]: t('enum.personRelationshipTypes.executive'),
    }),
    [t]
  );

  const personRelationshipTypesOptions: {
    value: PersonRelationship;
    label: string;
  }[] = useMemo(
    () =>
      Object.entries(personRelationshipTypes).map(([value, label]) => ({
        value: value as PersonRelationship,
        label,
      })),
    [personRelationshipTypes]
  );

  return {
    personRelationshipTypesOptions,
  };
}
