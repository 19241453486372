export const ROOT_ROUTE = '/';

export const NOT_FOUND_ROUTE = '/not-found';

export const SIGN_IN_ROUTE = '/sign-in';

export const CONTACTS_ROUTE = '/contacts';

export const TERMS_OF_SERVICE_ROUTE =
  process.env.REACT_APP_PLATFORM_AGREEMENT_URL || '#';
export const PRIVACY_POLICY_ROUTE =
  process.env.REACT_APP_PRIVACY_POLICY_URL || '#';
export const EXPERT_SUPPORT_ROUTE =
  process.env.REACT_APP_EXPERT_SUPPORT_URL || '#';
export const SUPPORT_PAGE_URL = process.env.REACT_APP_SUPPORT_PAGE_URL || '#';
