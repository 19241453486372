import { gql } from '@apollo/client';

export const QBO_ACCOUNT_SELECT_FRAGMENT = gql`
  fragment QboAccountSelect on QboAccountType {
    id
    name
    type
  }
`;

export const FETCH_QBO_ACCOUNTS = gql`
  query FetchQboAccounts {
    getQboResourcesDetails {
      accounts {
        ...QboAccountSelect
      }
    }
  }
  ${QBO_ACCOUNT_SELECT_FRAGMENT}
`;
