import { Buffer } from 'buffer';

export function isItHttpTransferableData(data: string | any): boolean {
  try {
    JSON.parse(data);

    return true;
  } catch (e) {
    return (
      typeof data === 'string' ||
      data instanceof Buffer ||
      data instanceof ArrayBuffer ||
      data instanceof Int8Array ||
      data instanceof Uint8Array ||
      data instanceof Uint8ClampedArray ||
      data instanceof Int16Array ||
      data instanceof Uint16Array ||
      data instanceof Int32Array ||
      data instanceof Uint32Array ||
      data instanceof Float32Array ||
      data instanceof Float64Array ||
      data instanceof DataView ||
      data instanceof Blob ||
      data instanceof File ||
      data instanceof URLSearchParams ||
      data instanceof FormData
    );
  }
}

export function isHttpAuthorizationErrorStatus(status: number): boolean {
  return [401, 403].includes(status);
}
