import React from 'react';
import { Amount } from '@appclose/core';

import { InvoiceHistoryTypes } from '__generated__/globalTypes';
import { sanitizeAmount } from 'controllers/amount';
import { I18n } from 'i18n';

import { InvoiceHistoryItemDescriptionPropsType } from './InvoiceHistoryItemDescription.types';
import styles from './InvoiceHistoryItemDescription.module.scss';

export default function InvoiceHistoryItemDescription({
  historyItem,
  isFirstSent,
}: InvoiceHistoryItemDescriptionPropsType) {
  const { status } = historyItem;

  switch (status) {
    case InvoiceHistoryTypes.CREATED:
      return (
        <b>
          <I18n id="modal.invoiceDetails.invoiceHistory.created" />
        </b>
      );
    case InvoiceHistoryTypes.CREDIT_MEMO:
      const { creditAmount, requestedAmount, balanceDue } =
        historyItem.creditMemo || {};

      return (
        <>
          <div>
            <b>
              <I18n
                id="modal.invoiceDetails.invoiceHistory.creditMemo.text1"
                values={{
                  amount: <Amount value={sanitizeAmount(creditAmount)} />,
                }}
              />
            </b>
            <div className={styles.subDescription}>
              <span>
                <I18n
                  id="modal.invoiceDetails.invoiceHistory.creditMemo.text2"
                  values={{
                    amount: (
                      <Amount strong value={sanitizeAmount(requestedAmount)} />
                    ),
                  }}
                />
              </span>
              <span>
                <I18n
                  id="modal.invoiceDetails.invoiceHistory.creditMemo.text3"
                  values={{
                    amount: (
                      <Amount strong value={sanitizeAmount(balanceDue)} />
                    ),
                  }}
                />
              </span>
            </div>
          </div>
        </>
      );
    case InvoiceHistoryTypes.PAID:
      return (
        <b>
          <I18n id="modal.invoiceDetails.invoiceHistory.paid" />
        </b>
      );
    case InvoiceHistoryTypes.PARTIALLY_PAID:
      const { paymentAmount, dueAmount } = historyItem.partialPayment || {};

      return (
        <>
          <div>
            <b>
              <I18n id="modal.invoiceDetails.invoiceHistory.partiallyPaid.text1" />
            </b>
            <div className={styles.subDescription}>
              <span>
                <I18n
                  id="modal.invoiceDetails.invoiceHistory.partiallyPaid.text2"
                  values={{
                    amount: (
                      <Amount strong value={sanitizeAmount(paymentAmount)} />
                    ),
                  }}
                />
              </span>
              <span>
                <I18n
                  id="modal.invoiceDetails.invoiceHistory.partiallyPaid.text3"
                  values={{
                    amount: <Amount strong value={sanitizeAmount(dueAmount)} />,
                  }}
                />
              </span>
            </div>
          </div>
        </>
      );
    case InvoiceHistoryTypes.SENT:
      return (
        <I18n
          id={
            isFirstSent
              ? 'modal.invoiceDetails.invoiceHistory.sent'
              : 'modal.invoiceDetails.invoiceHistory.resent'
          }
          values={{
            client: historyItem?.client?.email,
          }}
        />
      );
    case InvoiceHistoryTypes.VIEWED:
      return (
        <b>
          <I18n id="modal.invoiceDetails.invoiceHistory.viewed" />
        </b>
      );
    case InvoiceHistoryTypes.VOIDED:
      return (
        <b>
          <I18n id="modal.invoiceDetails.invoiceHistory.voided" />
        </b>
      );
    case InvoiceHistoryTypes.EDIT:
      return (
        <b>
          <I18n id="modal.invoiceDetails.invoiceHistory.edit" />
        </b>
      );
  }

  return null;
}
