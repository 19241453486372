import React from 'react';
import { Button, Flex } from '@appclose/ui';
import { Fieldset } from '@appclose/core';

import { ReactComponent as SuccessIcon } from 'assets/success.svg';
import { I18n, useIntl } from 'i18n';

import { QboIntegrationSetupResultStepPropsType } from './QboIntegrationSetupResult.types';
import styles from './QboIntegrationSetupResult.module.scss';

export default function QboIntegrationSetupResult({
  onClose,
}: QboIntegrationSetupResultStepPropsType) {
  const { t } = useIntl();

  return (
    <Fieldset
      title={t('modal.qboIntegrationSetup.integrationSetupResult.title')}
    >
      <Flex direction="column" alignItems="center">
        <SuccessIcon className={styles.successIcon} />
        <p className={styles.congratulations}>
          <I18n id="modal.qboIntegrationSetup.integrationSetupResult.congratulations" />
        </p>
        <p className={styles.congratulationsDescription}>
          <I18n id="modal.qboIntegrationSetup.integrationSetupResult.congratulationsDescription" />
        </p>
        <Button onClick={onClose}>
          <I18n id="modal.qboIntegrationSetup.integrationSetupResult.close" />
        </Button>
      </Flex>
    </Fieldset>
  );
}
