import React from 'react';
import lowerCase from 'lodash/lowerCase';

import {
  FormGroup,
  InputFormField,
  SecureInputFormField,
  Fieldset,
} from '@appclose/core';

import { I18n, useIntl } from 'i18n';
import { BankAccountClasses } from '__generated__/globalTypes';

import useBankAccountClasses from 'hooks/useBankAccountClasses';
import BankAccountSelectFormField from 'components/fields/BankAccountTypeSelectFormField';
import FormGrid from 'components/common/FormGrid/FormGrid';

import styles from './AccountFieldset.module.scss';

export default function AccountFieldset({
  accountClass,
}: {
  accountClass: BankAccountClasses;
}) {
  const { t } = useIntl();

  const { getBankAccountClassLabelWithAccount } = useBankAccountClasses();

  const bankAccountName = getBankAccountClassLabelWithAccount(accountClass);

  return (
    <Fieldset title={t('form.bankAccount.accountFieldset.title', { bankAccountName })}>
      <p className={styles.trustNote}>
        <I18n
          id="form.bankAccount.accountFieldset.note"
          values={{
            bankAccountName: accountClass === BankAccountClasses.TRUST ? lowerCase(bankAccountName) : '',
          }}
        />
      </p>
      <FormGroup>
        <FormGrid>
          <SecureInputFormField
            name="accountNumber"
            label={t('form.bankAccount.accountFieldset.accountNumber')}
            mask="99999999999999999"
          />
          <InputFormField
            name="routingNumber"
            label={t('form.bankAccount.accountFieldset.routingNumber')}
            placeholder="XXXXXXXXX"
            mask="999999999"
          />
          <InputFormField
            name="holderName"
            label={t('form.bankAccount.accountFieldset.holderName')}
          />
          <BankAccountSelectFormField name="type" />
        </FormGrid>
      </FormGroup>
    </Fieldset>
  );
}
