import { PeriodTypes } from '__generated__/globalTypes';

export const INVOICE_PERIOD_MIN = {
  [PeriodTypes.DAY]: 7,
  [PeriodTypes.WEEK]: 1,
  [PeriodTypes.MONTH]: 1,
};

export const INVOICE_PERIOD_MAX = {
  [PeriodTypes.DAY]: 90,
  [PeriodTypes.WEEK]: 13,
  [PeriodTypes.MONTH]: 3,
};
