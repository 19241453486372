import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { setTracingUser } from '@appclose/core';

import useFirm from 'hooks/useFirm';

import { FETCH_PROFILE } from './useProfile.gql';
import {
  FetchProfileQuery,
  FetchProfileQueryVariables,
} from './__generated__/useProfile.gql';
import { StripeKybStatus } from '__generated__/globalTypes';

export default function useProfile() {
  const { loading, data, client, error, refetch } = useQuery<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >(FETCH_PROFILE);

  const profile = useMemo(() => data?.profile, [data]);
  const { firm } = useFirm();

  const isKybStatusApproved = firm.stripeKybStatus === StripeKybStatus.APPROVED;
  const hasLimitedAccess = !firm.stripeKybStatus;

  useEffect(() => {
    if (profile?.id) {
      setTracingUser(profile.id, {
        onboardStatus: profile.onboardStatus,
      });
    }
  }, [profile?.id, profile?.onboardStatus]);

  return {
    loading,
    profile,
    client,
    error,
    refetch,
    hasLimitedAccess,
    kybStatus: firm.stripeKybStatus,
    isKybStatusApproved,
    isOwner: profile?.isOwner,
  };
}
