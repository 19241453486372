import React from 'react';
import { Button, ButtonGroup, Offset } from '@appclose/ui';
import {
  Form,
  FormGroup,
  InputFormField,
  TextAreaFormField,
  ToggleFormField,
  Fieldset,
  Loader,
  FormSubmissionError,
} from '@appclose/core';

import {
  SIGNATURE_ATTACH_FILES_MIME_TYPES,
  SIGNATURE_MAX_ATTACH_SIZE_MB,
} from 'constants/file';
import { I18n, useIntl } from 'i18n';
import FormGrid from 'components/common/FormGrid';
import FilesFormField from 'components/fields/FilesFormField';
import { Entities } from 'constants/entities';
import useProfile from 'hooks/useProfile';

import SignersFormField from '../../../SignersFormField';
import NonSignatoryRecipientsFormField from '../../../NonSignatoryRecipientsFormField';
import { SignatureDocumentFromFileFormPropsType } from './SignatureDocumentFromFileForm.types';
import { SignatureDocumentFromFileFormSchema } from './SignatureDocumentFromFileForm.schema';
import styles from './SignatureDocumentFromFileForm.module.scss';

export default function SignatureDocumentFromFileForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: SignatureDocumentFromFileFormPropsType) {
  const { t } = useIntl();
  const { loading } = useProfile();

  if (loading) {
    return <Loader />;
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={SignatureDocumentFromFileFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ isSubmitting }) => (
        <>
          <Fieldset title={t('modal.signatureDocument.form.fromFile.document')}>
            <FormGroup>
              <FilesFormField
                entityType={Entities.SIGNATURE}
                label={t(
                  'modal.signatureDocument.form.fromFile.document.uploadFiles'
                )}
                accept={SIGNATURE_ATTACH_FILES_MIME_TYPES}
                maxSizeMb={SIGNATURE_MAX_ATTACH_SIZE_MB}
              />
            </FormGroup>
            <FormGroup>
              <InputFormField
                name="title"
                label={t(
                  'modal.signatureDocument.form.fromFile.document.title'
                )}
              />
            </FormGroup>
          </Fieldset>
          <FormGroup className={styles.signersGroup}>
            <SignersFormField withoutTemplate className={styles.signers} />
            <FormGrid>
              <ToggleFormField
                name="completeInOrder"
                label={t(
                  'modal.signatureDocument.form.fromFile.completeInOrder'
                )}
                tooltip={t(
                  'modal.signatureDocument.form.fromFile.completeInOrder.tooltip'
                )}
              />
            </FormGrid>
          </FormGroup>
          <Fieldset
            title={t('modal.signatureDocument.form.fromFile.emailInfo')}
          >
            <FormGroup>
              <TextAreaFormField
                name="text"
                label={t(
                  'modal.signatureDocument.form.fromFile.emailInfo.text'
                )}
              />
            </FormGroup>
            <NonSignatoryRecipientsFormField />
          </Fieldset>
          <Offset bottom="m">
            <FormSubmissionError />
          </Offset>
          <ButtonGroup>
            <Button onClick={onCancel}>
              <I18n id="modal.signatureDocument.form.cancel" />
            </Button>
            <Button skin="brand" type="submit" loading={isSubmitting}>
              <I18n id="modal.signatureDocument.form.continue" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
