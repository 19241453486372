import { Entities } from 'constants/entities';

import {
  UPDATE_CONTACT_FILES,
  UPDATE_INVOICE_FILES,
  UPDATE_PAYMENT_FILES,
  UPDATE_VOID_INVOICE_FILES,
} from './useUpdateFiles.gql';

export const Mutations = {
  [Entities.CONTACT]: UPDATE_CONTACT_FILES,
  [Entities.INVOICE]: UPDATE_INVOICE_FILES,
  [Entities.PAYMENT]: UPDATE_PAYMENT_FILES,
  [Entities.VOID_INVOICE]: UPDATE_VOID_INVOICE_FILES,
};
