import { useIntl } from 'i18n';
import { AccessLevels } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useAccessLevels() {
  const { t } = useIntl();
  const accessLevels: Record<AccessLevels, string> = useMemo(
    () => ({
      [AccessLevels.ADMIN]: t('enum.accessLevels.admin'),
      [AccessLevels.LIMITED_USER]: t('enum.accessLevels.limitedUser'),
      [AccessLevels.READ_ONLY_USER]: t('enum.accessLevels.readOnlyUser'),
      [AccessLevels.SUPER_ADMIN]: t('enum.accessLevels.superAdmin'),
    }),
    [t]
  );

  const accessLevelsOptions: {
    label: string;
    value: AccessLevels;
  }[] = useMemo(
    () =>
      Object.entries(accessLevels).map(([value, label]) => ({
        value: value as AccessLevels,
        label,
      })),
    [accessLevels]
  );

  const getAccessLevelLabel = useCallback(
    (type?: AccessLevels | null) => {
      if (!type) {
        return '';
      }

      return accessLevels[type];
    },
    [accessLevels]
  );

  return { accessLevelsOptions, getAccessLevelLabel };
}
