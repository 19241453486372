import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { openConfirmAction } from '@appclose/core';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useIntl } from 'i18n';
import notification from 'controllers/notification';
import { UseQboResyncOptions } from './useQboResync.types';

export default function useQboResync<TData = any, TVariables = any>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { entityName }: UseQboResyncOptions
) {
  const dispatch = useDispatch();
  const { t } = useIntl();

  const [resyncMutation] = useMutation<TData, TVariables>(mutation);

  const resync = (variables: TVariables) => {
    dispatch(
      openConfirmAction({
        name: t('hook.useQboResync.confirm.title', {
          entityName: entityName.toLowerCase(),
        }),
        content: t('hook.useQboResync.confirm.text', { entityName }),
        okButtonSkin: 'brand',
        okButtonTitle: t('hook.useQboResync.confirm.button.ok'),
        cancelButtonTitle: t('hook.useQboResync.confirm.button.cancel'),
        async onConfirm() {
          try {
            await resyncMutation({ variables });

            notification().successAction(
              t('hook.useQboResync.confirm.notification.success', {
                entityName,
              })
            );
          } catch {
            notification().error(
              new Error(
                t('hook.useQboResync.confirm.notification.error', {
                  entityName,
                })
              )
            );
          }
        },
      })
    );
  };

  return [resync];
}
