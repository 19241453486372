import classnames from 'classnames';
import { forwardRef } from 'react';
import { Button } from '../buttons';

import styles from './ContextMenu.module.scss';

export const ContextMenuItem = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean;
    label?: string;
    ['data-prevented']?: boolean;
  }
>(({ children, disabled, className, label, ...props }, ref) => (
  <Button
    {...props}
    className={classnames(styles.contextMenuItem, className)}
    ref={ref}
    disabled={disabled}
    role="menuitem"
    skin="shell"
  >
    {label || children}
  </Button>
));
