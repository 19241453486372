import { gql } from '@apollo/client';

import { SIGNATURE_TEMPLATE_FRAGMENT } from 'components/modals/pages/SignatureTemplateModal';

export const FETCH_SIGNATURE_TEMPLATE = gql`
  query FetchSignatureTemplate($id: ID!) {
    signatureTemplate: getSignatureTemplate(
      getSignatureTemplateInput: { id: $id }
    ) {
      ...SignatureTemplate
    }
  }
  ${SIGNATURE_TEMPLATE_FRAGMENT}
`;
