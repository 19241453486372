import { gql } from '@apollo/client';

export const CANCEL_SIGNATURE = gql`
  mutation CancelSignature($id: ID!) {
    cancelSignature(signatureIdInput: { id: $id }) {
      success
    }
  }
`;

export const FINISH_SIGNATURE = gql`
  mutation FinishSignature($id: ID!) {
    finishSignature(signatureIdInput: { id: $id }) {
      success
    }
  }
`;
