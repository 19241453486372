import React from 'react';
import { Ellipsis, ExternalLink } from '@appclose/core';

import { NodeNamePropsType } from './NodeName.types';

export default function NodeName({ name, url, isFolder }: NodeNamePropsType) {
  const children =
    !isFolder && url ? (
      <ExternalLink link={url} download={name}>
        {name}
      </ExternalLink>
    ) : (
      name
    );

  return <Ellipsis lines={2}>{children}</Ellipsis>;
}
