import React, { createRef, useCallback, useState } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';

import { scrollToErrorField } from '../../../utils/formik';

import { FormSubmissionError, FormPropsType } from './Form.types';
import { FormContext } from './Form.context';
import FormOnChangeEffect from './components/FormOnChangeEffect';

export default function Form<FormValues extends FormikValues>({
  withScrollToError = true,
  unsafeEnableReinitialize = false,
  validateChange = false,
  innerRef,
  className,
  children,
  onSubmit,
  onChange,
  ...props
}: FormPropsType<FormValues>) {
  const formRef = createRef<HTMLFormElement>();
  const [validateOnChange, setValidateOnChange] = useState(validateChange);
  const [processes, setProcesses] = useState<Promise<void>[]>([]);
  const [submissionError, setSubmissionError] = useState<FormSubmissionError>(
    null
  );

  const onAddProcess = useCallback(
    (process: Promise<void>) =>
      setProcesses((prevState) => [...prevState, process]),
    [setProcesses]
  );

  const handleOnSubmit = useCallback(
    async (props: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmissionError(null);

      try {
        await Promise.all(processes);

        if (onSubmit) {
          await onSubmit(props, actions);
        }
      } catch (e) {
        setSubmissionError(e as FormSubmissionError);
      }
    },
    [onSubmit, processes]
  );

  return (
    <FormContext.Provider
      value={{ submissionError, processes, addProcess: onAddProcess }}
    >
      <Formik
        {...props}
        enableReinitialize={unsafeEnableReinitialize}
        onSubmit={handleOnSubmit}
        validateOnBlur={false}
        validateOnChange={validateOnChange}
        // TODO: https://github.com/jaredpalmer/formik/issues/2290 https://github.com/jaredpalmer/formik/pull/2222
        innerRef={innerRef as any}
      >
        {(formProps) => {
          if (withScrollToError && formRef.current) {
            scrollToErrorField(formProps, formRef.current);
          }

          return (
            <form
              ref={formRef}
              onSubmit={(e) => {
                formProps.handleSubmit(e);

                if (!validateOnChange && formProps.submitCount) {
                  setValidateOnChange(true);
                }
              }}
              className={className}
            >
              {children({ ...formProps, submissionError })}
              {onChange && <FormOnChangeEffect onChange={onChange} />}
            </form>
          );
        }}
      </Formik>
    </FormContext.Provider>
  );
}
