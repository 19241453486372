import * as Yup from 'yup';

import { ContactPopupFormValuesType } from './ContactPopupForm.types';
import { ContactFieldsetSchema } from './components/ContactFieldset';
import { EntityFieldsetSchema } from './components/EntityFieldset';
import { ContactPopupModalTabs } from '../../ContactPopupModal.types';

export const ContactPopupFormSchema = Yup.object().shape<
  Partial<ContactPopupFormValuesType>
>({
  contact: Yup.object<any>().when('selectedTab', {
    is: ContactPopupModalTabs.INDIVIDUAL,
    then: ContactFieldsetSchema,
  }),
  entity: Yup.object<any>().when('selectedTab', {
    is: ContactPopupModalTabs.ENTITY,
    then: EntityFieldsetSchema,
  }),
});
