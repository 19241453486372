import { Form, FormGroup, Fieldset } from '@appclose/core';

import { BankAccountClasses } from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import ContactSelectFormField, {
  SelectContactType,
} from 'components/fields/ContactSelectFormField';
import BankAccountDueStatistic from '../BankAccountDueStatistic';
import CommonFormContent from '../CommonFormContent';

import { useIntl } from 'i18n';

import {
  ReceivePaymentFormPropsType,
  ReceivePaymentFormValuesType,
} from '../../PaymentInfoFormsBlock.types';
import { ReceivePaymentFormSchema } from '../../PaymentInfoFormsBlock.schema';

export default function ToOperatingForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
  setContact,
}: ReceivePaymentFormPropsType) {
  const { t } = useIntl();

  return (
    <Form<ReceivePaymentFormValuesType>
      initialValues={initialValues}
      validationSchema={ReceivePaymentFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ setValues, values }) => {
        const onChangeContact = (contact?: SelectContactType) => {
          setValues(values);
          setContact(contact);
        };

        return (
          <>
            <Fieldset
              title={t('modal.receivePayment.form.fieldset.contactAndMatter')}
            >
              <FormGroup>
                <FormGrid>
                  <ContactSelectFormField
                    showLabel="always"
                    onChange={onChangeContact}
                  />
                </FormGrid>
              </FormGroup>
              <FormGroup>
                <FormGrid>
                  <BankAccountDueStatistic
                    bankAccountClass={BankAccountClasses.OPERATING}
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <CommonFormContent onCancel={onCancel} />
          </>
        );
      }}
    </Form>
  );
}
