import React, { useState } from 'react';
import classnames from 'classnames';

import { ReactComponent as Placeholder } from './assets/placeholder.svg';
import { AvatarType } from './Avatar.types';
import styles from './Avatar.module.scss';

export default function Avatar({
  icon,
  size = 40,
  className,
  onShowIconError,
  style,
}: AvatarType): React.ReactElement {
  const [iconVisible, setIconVisible] = useState(false);

  const handleImageLoad = () => setIconVisible(true);

  return (
    <span
      className={classnames(styles.avatar, className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        ...style,
      }}
    >
      {(!icon || !iconVisible) && <Placeholder width={size} height={size} />}
      {icon && (
        <img
          style={{ opacity: iconVisible ? 1 : 0 }}
          className={styles.avatarImage}
          src={icon}
          width={size}
          height={size}
          alt=""
          onLoad={handleImageLoad}
          onError={onShowIconError}
        />
      )}
    </span>
  );
}
