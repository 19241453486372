import React, { ReactElement } from 'react';

import { SignerStatuses } from '__generated__/globalTypes';
import Label, { LabelPropsType } from 'components/common/Label';
import { I18n } from 'i18n';

import { DocumentSignerStatusPropsType } from './DocumentSignerStatus.types';

const DocumentSignerLabels: Record<
  SignerStatuses,
  ReactElement<LabelPropsType>
> = {
  [SignerStatuses.NOT_PREPARED]: (
    <Label theme="info">
      <I18n id="enum.document.signer.status.notPrepared" />
    </Label>
  ),
  [SignerStatuses.PREPARED]: (
    <Label theme="info">
      <I18n id="enum.document.signer.status.prepared" />
    </Label>
  ),
  [SignerStatuses.SENT]: (
    <Label theme="info">
      <I18n id="enum.document.signer.status.sent" />
    </Label>
  ),
  [SignerStatuses.VIEWED]: (
    <Label theme="primary">
      <I18n id="enum.document.signer.status.viewed" />
    </Label>
  ),
  [SignerStatuses.SIGNED]: (
    <Label theme="success">
      <I18n id="enum.document.signer.status.signed" />
    </Label>
  ),
  [SignerStatuses.DECLINED]: (
    <Label theme="warning">
      <I18n id="enum.document.signer.status.declined" />
    </Label>
  ),
};

export default function DocumentSignerStatus({
  status,
}: DocumentSignerStatusPropsType) {
  return DocumentSignerLabels[status] || null;
}
