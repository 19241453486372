import React from 'react';
import { Caption2, Flex, Text5 } from '@appclose/ui';
import { PhoneExternalLink } from '@appclose/core';

import FirmLogo from 'components/common/FirmLogo';

import { FirmInfoPropsType } from './FirmInfo.types';
import styles from './FirmInfo.module.scss';

export default function FirmInfo({ className, firm }: FirmInfoPropsType) {
  if (!firm) {
    return null;
  }

  const { name, address, website, logo, phoneNumber } = firm;

  return (
    <Flex className={className} alignItems="center">
      {logo?.original && (
        <FirmLogo logo={logo?.original} className={styles.logo} />
      )}
      <div>
        <Caption2 weight="bold" offset={{ bottom: 8 }}>
          {name}
        </Caption2>
        {address && (
          <>
            <Text5>{`${address.address1}, ${address.city}, ${address.state}, ${address.zipCode} US`}</Text5>
            <Text5>
              <PhoneExternalLink phone={phoneNumber?.number} />
            </Text5>
            <Text5>{website}</Text5>
          </>
        )}
      </div>
    </Flex>
  );
}
