import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import differenceBy from 'lodash/differenceBy';
import set from 'lodash/set';
import { FieldError, useFormContext, useUpdateEffect } from '@appclose/core';

import AttachFiles, {
  AttachFilesAttachFile,
} from 'components/common/AttachFiles';
import useListFiles from 'hooks/useListFiles';
import useUploadFiles from 'hooks/useUploadFiles';

import {
  FilesFormFieldFilesType,
  FilesFormFieldPropsType,
} from './FilesFormField.types';

const FilesFormField = memo(function FilesFormField({
  name = 'files',
  onChange,
  entityType,
  entityId,
  ...props
}: FilesFormFieldPropsType) {
  const { setValues } = useFormContext<Record<string, any>>();
  const { filesLoading, files } = useListFiles({
    entityType,
    input: {
      filter: { entityIds: entityId ? [entityId] : undefined },
    },
    skip: !entityId,
    useSubscriptions: false,
  });
  const { uploads, onUpload, onRetryUpload, onDelete } = useUploadFiles();
  const [attachedFiles, setAttachedFiles] = useState<
    AttachFilesAttachFile[] | null
  >(null);
  const fileIdsToDelete = useMemo(
    () =>
      files?.items.length && attachedFiles !== null
        ? differenceBy(files.items, attachedFiles || [], 'id').map(
            ({ id }) => id
          )
        : [],
    [files?.items, attachedFiles]
  );

  useEffect(() => {
    if (files?.items.length) {
      setAttachedFiles(files.items);
    }
  }, [files?.items]);

  useUpdateEffect(() => {
    const value: FilesFormFieldFilesType = {
      newFiles: uploads.map(({ file: { name, type: mimetype }, url = '' }) => ({
        name,
        mimetype,
        url,
      })),
      fileIdsToDelete,
      hasError: uploads.find(({ error }) => error) !== undefined,
    };

    setValues((values) => set(values, name, value), true);
    onChange?.(value);
  }, [uploads, name, fileIdsToDelete, onChange]);

  const handleOnChange = useCallback(
    (files?: File[], attaches?: AttachFilesAttachFile[] | null) => {
      const newFiles =
        files?.filter(
          (file) => uploads.find((upload) => upload.file === file) === undefined
        ) || [];
      const uploadsToDelete = files?.length
        ? uploads.filter((upload) => !files.includes(upload.file))
        : uploads;

      onDelete(uploadsToDelete.map(({ id }) => id));
      onUpload(newFiles);

      setAttachedFiles(attaches || []);
    },
    [uploads, onDelete, onUpload]
  );

  const handleOnRetry = useCallback(
    (file: File) => {
      const upload = uploads.find((upload) => upload.file === file);

      if (!upload) {
        return;
      }

      onRetryUpload(upload.id);
    },
    [uploads, onRetryUpload]
  );

  return (
    <>
      <AttachFiles
        {...props}
        loading={filesLoading}
        files={uploads.map(({ file }) => file)}
        processingFiles={uploads.map(({ uploading }) => uploading)}
        failedFiles={uploads.map(({ error }) => error)}
        attaches={attachedFiles}
        onChange={handleOnChange}
        onRetry={handleOnRetry}
      />
      <FieldError name={name} />
    </>
  );
});

export default FilesFormField;
