import { SelectFormField } from '@appclose/core';

import { useIntl } from 'i18n';

import { BankAccountTypeSelectFormFieldPropsType } from './BankAccountTypeSelectFormField.types';
import useBankAccountType from 'hooks/useBankAccountType';

export default function BankAccountSelectFormField({
  name = 'type',
  label,
  ...props
}: BankAccountTypeSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { bankAccountTypesOptions } = useBankAccountType();

  return (
    <SelectFormField
      {...props}
      name={name}
      label={label || t('field.bankAccountSelectFormField.label')}
      options={bankAccountTypesOptions}
    />
  );
}
