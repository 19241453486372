import { gql } from '@apollo/client';
import { INVOICE_INFO_FRAGMENT } from 'components/common/InvoiceInfo';
import { INFO_FRAGMENT } from './components/Info';
import { INVOICE_HISTORY_FRAGMENT } from './components/InvoiceHistory';
import { INVOICE_REMINDERS_FRAGMENT } from './components/InvoiceReminders';

export const FETCH_INVOICE = gql`
  query FetchInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      ...InvoiceInfo
      ...Info
      ...InvoiceHistory
      ...InvoiceReminders
    }
  }
  ${INVOICE_INFO_FRAGMENT}
  ${INFO_FRAGMENT}
  ${INVOICE_HISTORY_FRAGMENT}
  ${INVOICE_REMINDERS_FRAGMENT}
`;
