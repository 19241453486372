import { gql } from '@apollo/client';

export const INVOICE_INFO_FRAGMENT = gql`
  fragment InvoiceInfo on InvoiceType {
    id
    createdAt
    dueDate
    overdueDayQty
    missedPaymentQty
    status
    invoiceNumber
    balanceDue
    paidAmount
    originalAmount
    contact {
      id
      name
    }
    scheduledPayments {
      planApproved
      plan {
        amount
        status
      }
    }
    paymentReminder {
      overdueReminder {
        ongoingOverdueDayQty
      }
      prepaymentReminder {
        dayQtyBefore
      }
    }
  }
`;
