import React from 'react';
import { Hint, StaticField } from '@appclose/core';
import { Flex } from '@appclose/ui';

import { QboMappingTargetFieldPropsType } from './QboMappingTargetField.types';
import styles from './QboMappingTargetField.module.scss';

export default function QboMappingTargetField({
  title,
  tooltip,
}: QboMappingTargetFieldPropsType) {
  return (
    <StaticField className={styles.field}>
      <Flex alignItems="center" className={styles.fieldContent}>
        <p className={styles.title}>{title}</p>
        {tooltip && (
          <Hint
            iconClassName={styles.hint}
            className={styles.tooltip}
            content={tooltip}
          />
        )}
      </Flex>
    </StaticField>
  );
}
