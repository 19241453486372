import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Loader, traceError } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { getContactName } from 'controllers/contact';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';

import {
  FetchDataQuery,
  FetchDataQueryVariables,
  ScheduleInvoiceRemindersMutation,
  ScheduleInvoiceRemindersMutationVariables,
} from './__generated__/InvoiceRemindersModal.gql';
import InvoiceRemindersForm from './components/InvoiceRemindersForm';
import {
  InvoiceStopToRemind,
  InvoiceRemindersFormValuesType,
} from './components/InvoiceRemindersForm/InvoiceRemindersForm.types';
import {
  FETCH_DATA,
  SCHEDULE_INVOICE_REMINDERS,
} from './InvoiceRemindersModal.gql';
import { InvoiceRemindersModalPropsType } from './InvoiceRemindersModal.types';

export default function InvoiceRemindersModal({
  id,
  onClose,
}: InvoiceRemindersModalPropsType) {
  const { t } = useIntl();
  const { loading, data } = useQuery<FetchDataQuery, FetchDataQueryVariables>(
    FETCH_DATA,
    {
      variables: { id },
    }
  );

  const [scheduleInvoiceReminders] = useMutation<
    ScheduleInvoiceRemindersMutation,
    ScheduleInvoiceRemindersMutationVariables
  >(SCHEDULE_INVOICE_REMINDERS);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async ({
      overdueReminderType,
      overdueReminderActive,
      prepaymentReminderActive,
      overdueReminder: formOverdueReminder,
      prepaymentReminder,
    }: InvoiceRemindersFormValuesType) => {
      try {
        const overdueReminder = {
          ...formOverdueReminder,
        } as InvoiceRemindersFormValuesType['overdueReminder'];

        if (overdueReminder) {
          if (overdueReminderType === InvoiceStopToRemind.REMINDERS_SENT) {
            overdueReminder.stopToRemindAfterDayQty = undefined;
          } else {
            overdueReminder.stopToRemindAfterSentQty = undefined;
          }
        }

        await scheduleInvoiceReminders({
          variables: {
            reminders: {
              invoiceId: id,
              paymentReminder: {
                overdueReminder: overdueReminderActive
                  ? overdueReminder
                  : undefined,
                prepaymentReminder: prepaymentReminderActive
                  ? prepaymentReminder
                  : undefined,
              },
            },
          },
        });

        notification().info(t('modal.invoiceReminders.notification.success'));

        onClose();
      } catch (e) {
        traceError(e as Error);
      }
    },
    [onClose, id, scheduleInvoiceReminders, t]
  );

  const invoice = data?.invoice as FetchDataQuery['invoice'];

  const paymentReminder = (invoice?.paymentReminder || {}) as NonNullable<
    FetchDataQuery['invoice']['paymentReminder']
  >;
  const { overdueReminder, prepaymentReminder } = paymentReminder;

  const initialValues: InvoiceRemindersFormValuesType = {
    prepaymentReminderActive: !!prepaymentReminder || false,
    overdueReminderActive: !!overdueReminder || false,
    overdueReminderType: overdueReminder?.stopToRemindAfterSentQty
      ? InvoiceStopToRemind.REMINDERS_SENT
      : InvoiceStopToRemind.DAYS_PASSED,
    prepaymentReminder: {
      dayQtyBefore: prepaymentReminder?.dayQtyBefore || 3,
      emailText:
        prepaymentReminder?.emailText ||
        t('modal.invoiceReminders.paymentReminder.emailText', {
          contact: invoice?.contact && getContactName(invoice.contact),
        }),
    },
    overdueReminder: {
      stopToRemindAfterSentQty: overdueReminder?.stopToRemindAfterSentQty || 7,
      stopToRemindAfterDayQty: overdueReminder?.stopToRemindAfterDayQty || 90,
      emailText:
        overdueReminder?.emailText ||
        t('modal.invoiceReminders.overdueReminder.emailText', {
          contact: invoice?.contact && getContactName(invoice.contact),
        }),
      ongoingOverdueDayQty: overdueReminder?.ongoingOverdueDayQty || 7,
      overdueDayQty: overdueReminder?.overdueDayQty || 7,
    },
  };

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          {invoice &&
            t('modal.invoiceReminders.title', {
              invoiceNumber: invoice.invoiceNumber,
            })}
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <InvoiceRemindersForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
