import React from 'react';
import classnames from 'classnames';

import { NumberPropsType, NumberNPropsType } from './Number.types';
import styles from './Number.module.scss';

export function Number({
  children,
  as: Component = 'p',
  level,
  color,
  offset,
  align: textAlign,
  className,
  onClick,
}: NumberPropsType) {
  const margins = {
    marginTop: offset?.top,
    marginRight: offset?.right,
    marginBottom: offset?.bottom,
    marginLeft: offset?.left,
  };

  return (
    <Component
      onClick={onClick}
      style={{ ...margins, textAlign }}
      className={classnames(styles.number, className, {
        [styles.numberLevel1]: level === 1,
        [styles.numberLevel2]: level === 2,
        [styles.numberLevel3]: level === 3,
        [styles.numberLevel4]: level === 4,
        [styles.numberLevel5]: level === 5,
        [styles.numberLevel6]: level === 6,
        [styles.numberLevel7]: level === 7,
        [styles.primary]: color === 'primary',
        [styles.secondary]: color === 'secondary',
        [styles.contrast]: color === 'contrast',
        [styles.gray]: color === 'gray',
        [styles.gray030]: color === 'gray030',
        [styles.warning]: color === 'warning',
        [styles.links]: color === 'links',
      })}
    >
      {children}
    </Component>
  );
}

export function Number1({ children, ...props }: NumberNPropsType) {
  return (
    <Number level={1} {...props}>
      {children}
    </Number>
  );
}

export function Number2({ children, ...props }: NumberNPropsType) {
  return (
    <Number level={2} {...props}>
      {children}
    </Number>
  );
}

export function Number3({ children, ...props }: NumberNPropsType) {
  return (
    <Number level={3} {...props}>
      {children}
    </Number>
  );
}

export function Number4({ children, ...props }: NumberNPropsType) {
  return (
    <Number level={4} {...props}>
      {children}
    </Number>
  );
}

export function Number5({ children, ...props }: NumberNPropsType) {
  return (
    <Number level={5} {...props}>
      {children}
    </Number>
  );
}

export function Number6({ children, ...props }: NumberNPropsType) {
  return (
    <Number level={6} {...props}>
      {children}
    </Number>
  );
}

export function Number7({ children, ...props }: NumberNPropsType) {
  return (
    <Number level={7} {...props}>
      {children}
    </Number>
  );
}
