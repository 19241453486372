import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { Manager, usePopper } from 'react-popper';
import styles from './Tooltip.module.scss';
import ReactDOM from 'react-dom';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { Text4 } from '@appclose/ui';
import { TooltipPropsType } from './Tooltip.types';

const modifiers = [
  {
    name: 'preventOverflow',
    options: { rootBoundary: 'viewport' },
  },
  {
    name: 'offset',
    options: {
      offset: [0, 15],
    },
  },
];

export default function Tooltip({
  position = 'top',
  title,
  content,
  theme = 'light',
  className,
  targetClassName,
  children,
  trigger = 'hover',
  defaultOpen = false,
}: TooltipPropsType) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(defaultOpen);
  const [referenceElement, setReferenceElement] = useState<Element | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const {
    styles: { popper: popperStyles },
    attributes,
    state,
  } = usePopper(referenceElement, popperElement, {
    modifiers,
    placement: position,
  });

  const onTooltipClose = useMemo(
    () =>
      debounce(() => {
        setIsTooltipOpen(false);
      }, 50),
    []
  );

  const onTooltipOpen = useCallback(() => {
    setIsTooltipOpen(true);
    onTooltipClose.cancel();
  }, [onTooltipClose]);

  if (!content && !title) {
    return <>{children}</>;
  }

  return (
    <Manager>
      <div
        className={classnames(styles.tooltipTarget, targetClassName)}
        onMouseEnter={trigger === 'hover' ? onTooltipOpen : undefined}
        onClick={trigger === 'click' ? onTooltipOpen : undefined}
        onMouseLeave={onTooltipClose}
        ref={(r) => {
          setReferenceElement(r?.firstChild as Element);
        }}
      >
        {children}
      </div>
      {isTooltipOpen &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            style={popperStyles}
            onMouseEnter={onTooltipOpen}
            onMouseLeave={onTooltipClose}
            className={classnames(
              styles.tooltip,
              {
                [styles.dark]: theme === 'dark',
              },
              className
            )}
            data-placement={state?.placement}
            {...attributes}
          >
            {title && (
              <Text4 as="div" weight="bold">
                {title}
              </Text4>
            )}
            {content && <Text4 as="div">{content}</Text4>}
            <ArrowIcon className={styles.arrow} />
          </div>,
          document.body
        )}
    </Manager>
  );
}
