import React, { ReactElement } from 'react';
import { DispatchProp } from 'react-redux';
import { ButtonTypes } from '@appclose/ui';

import { BankAccountClasses } from '__generated__/globalTypes';
import { openModal } from 'controllers/modal';
import { KYB_MODAL } from 'constants/modals';
import { I18n } from 'i18n';

import {
  BankAccountNotLinkedType,
  BankAccountNotLinkedTypes,
} from '../BankAccountNotLinked.types';

export const useConfig = (): Record<
  BankAccountNotLinkedType,
  {
    message: () => ReactElement;
    button?: Omit<ButtonTypes, 'children' | 'onClick'> & {
      message: () => ReactElement;
      onClick?(dispatch: DispatchProp['dispatch']): () => void;
    };
  }
> => {
  // @ts-ignore
  return {
    [BankAccountClasses.OPERATING]: {
      message: () => <I18n id="bankAccountNotLinked.operating" />,
    },
    [BankAccountNotLinkedTypes.LIMITED_ACCESS_OWNER]: {
      message: () => (
        <I18n id="store.profile.limitedAccessConfirm.owner.content" />
      ),
      button: {
        skin: 'primary',
        message: () => (
          <I18n id="store.profile.limitedAccessConfirm.owner.okButton" />
        ),
        onClick: () => () => openModal(KYB_MODAL),
      },
    },
    [BankAccountNotLinkedTypes.LIMITED_ACCESS_TEAM_MEMBER]: {
      message: () => (
        <I18n id="store.profile.limitedAccessConfirm.teamMember.content" />
      ),
      button: {
        skin: 'primary',
        message: () => (
          <I18n id="store.profile.limitedAccessConfirm.teamMember.okButton" />
        ),
      },
    },
  };
};
