import React from 'react';
import { SelectFormField } from '@appclose/core';

import { useIntl } from 'i18n';

import { PrincipalTitleSelectFormFieldPropsType } from './PrincipalTitleSelectFormField.types';
import { useOptions } from './PrincipalTitleSelectFormField.utils';

export default function PrincipalTitleSelectFormField({
  name = 'title',
  label,
  placeholder,
  showLabel,
  showPlaceholder,
  ...props
}: PrincipalTitleSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { options } = useOptions();

  return (
    <SelectFormField
      name={name}
      label={label || t('field.principalTitleSelect.label')}
      placeholder={placeholder}
      showLabel={showLabel}
      showPlaceholder={showPlaceholder}
      {...props}
      options={options}
    />
  );
}
