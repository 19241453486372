import React from 'react';

import { InputFormField } from '../../../Input';
import { USA_ZIP_CODE_MASK } from '../../../../..';
import { ZipCodeFormFieldType } from './ZipCodeFormField.types';

export default function ZipCodeFormField({
  name = 'zipCode',
  label = 'Zip code',
  mask = USA_ZIP_CODE_MASK,
  ...props
}: ZipCodeFormFieldType) {
  return <InputFormField {...props} name={name} label={label} mask={mask} />;
}
