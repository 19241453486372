import { gql } from '@apollo/client';

export const CANCEL_SIGNATURE = gql`
  mutation CancelSignature($id: ID!) {
    cancelSignature(signatureIdInput: { id: $id }) {
      success
    }
  }
`;

export const SEND_SIGNATURE_REMINDER = gql`
  mutation SendSignatureReminder($id: ID!) {
    reminder: sendReminderForSignature(signatureIdInput: { id: $id }) {
      success
    }
  }
`;

export const FETCH_SIGNATURE_URL = gql`
  mutation FetchSignatureUrl($id: ID!) {
    signature: updateSignature(signatureIdInput: { id: $id }) {
      url
    }
  }
`;

export const REMOVE_SIGNATURE_TEMPLATE = gql`
  mutation RemoveSignatureTemplate($id: ID!) {
    removeSignatureTemplate(removeSignatureTemplateInput: { id: $id }) {
      success
    }
  }
`;

export const FETCH_SIGNATURE_TEMPLATE_URL = gql`
  mutation FetchSignatureTemplateUrl($id: ID!) {
    template: updateSignatureTemplate(
      updateSignatureTemplateInput: { id: $id }
    ) {
      templateUrl {
        url
      }
    }
  }
`;
