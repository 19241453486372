import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const TEAM_MEMBER_SELECT_FRAGMENT = gql`
  fragment TeamMemberSelect on UserType {
    id
    firstName
    lastName
    hourlyRate
    role
    accessLevel
    email
    picture {
      ...Avatar
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const FETCH_PROFILE = gql`
  query FetchProfile {
    profile: getProfile {
      ...TeamMemberSelect
    }
  }
  ${TEAM_MEMBER_SELECT_FRAGMENT}
`;

export const FETCH_TEAM_MEMBERS = gql`
  query FetchTeamMembers(
    $filter: FilterTeamMemberInput
    $skip: Int
    $take: Int
  ) {
    users: listTeamMembers(
      listTeamMembersInput: { filter: $filter, skip: $skip, take: $take }
    ) {
      items {
        ...TeamMemberSelect
      }
      total
    }
  }
  ${TEAM_MEMBER_SELECT_FRAGMENT}
`;
