import React from 'react';
import isNumber from 'lodash/isNumber';
import { Flex } from '@appclose/ui';
import { Amount, StaticField } from '@appclose/core';

import { ScheduledPaymentStatuses } from '__generated__/globalTypes';
import { sanitizeAmount } from 'controllers/amount';
import { I18n, useIntl } from 'i18n';

import { AmountInfoPropsType } from './AmountInfo.types';
import styles from './AmountInfo.module.scss';

export default function AmountInfo({
  isMinified,
  invoice: { paidAmount, originalAmount, balanceDue, scheduledPayments },
  mode,
}: AmountInfoPropsType) {
  const { t } = useIntl();
  const withPaidAmount = isNumber(paidAmount) && paidAmount > 0;
  const hasScheduledPayments = Boolean(scheduledPayments?.plan?.length);
  const scheduledPaymentAmount = sanitizeAmount(
    scheduledPayments?.plan?.find(
      ({ status }) => status !== ScheduledPaymentStatuses.PAID
    )?.amount
  );

  let amountInfoContent: JSX.Element | undefined;

  switch (true) {
    case hasScheduledPayments && mode === 'payment':
    case hasScheduledPayments && !withPaidAmount:
      amountInfoContent = (
        <StaticField
          title={t('invoiceInfo.amountInfo.remainingInvoiceBalance')}
          className={styles.amountField}
        >
          <Amount value={balanceDue} className={styles.additionalAmount} />
        </StaticField>
      );

      break;
    case withPaidAmount:
      amountInfoContent = (
        <Flex alignItems="center" justify="flex-start" wrap="wrap">
          <StaticField
            title={t('amountInfo.originalAmount')}
            className={styles.amountField}
          >
            <Amount
              value={originalAmount}
              className={styles.additionalAmount}
            />
          </StaticField>
          <StaticField
            title={t('amountInfo.amountPaid')}
            className={styles.amountField}
          >
            <Amount value={paidAmount} className={styles.additionalAmount} />
          </StaticField>
        </Flex>
      );

      break;
  }

  return (
    <Flex
      className={isMinified ? styles.fieldShortened : styles.field}
      direction="row"
      alignItems="flex-end"
      justify="space-between"
      wrap="wrap"
    >
      <div className={styles.amountInfo}>
        <p className={styles.amountLabel}>
          <I18n
            id="invoiceInfo.amountInfo.due"
            values={{
              mode: mode === 'payment' ? 'Payment' : 'Balance',
            }}
          />
        </p>
        <Amount
          className={styles.amount}
          value={hasScheduledPayments ? scheduledPaymentAmount : balanceDue}
        />
      </div>
      <div className={styles.additionalAmountInfo}>{amountInfoContent}</div>
    </Flex>
  );
}
