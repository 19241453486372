import React from 'react';
import { Button, Flex } from '@appclose/ui';
import { useIsDesktopDevice } from '@appclose/core';
import { CreditMemoIcon, VoidIcon, EditIcon } from '@appclose/icons';
import { PermissionGuard } from 'components/common/PermissionGuard';

import { InvoiceStatusesTypes } from '__generated__/globalTypes';
import {
  INVOICE_CREDIT_MEMO_MODAL,
  RECEIVE_PAYMENT_MODAL,
  INVOICE_VOID_MODAL,
  INVOICE_MODAL,
} from 'constants/modals';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import {
  isCreditMemoAllowed,
  isEditAllowed,
  isReceivePaymentAllowed,
  isVoidAllowed,
} from 'controllers/invoice';
import { openModal } from 'controllers/modal';
import useAccessCheck from 'hooks/useAccessCheck';
import { I18n } from 'i18n';

import { ActionsPropsType } from './Actions.types';
import styles from './Actions.module.scss';

export default function Actions({ invoice: { id, status } }: ActionsPropsType) {
  const isDesktop = useIsDesktopDevice();
  const { withLimitedAccessCheck } = useAccessCheck();

  const openProcessPayment = withLimitedAccessCheck(() => {
    openModal(RECEIVE_PAYMENT_MODAL, { invoiceId: id });
  });

  const openCreditMemo = withLimitedAccessCheck(() => {
    openModal(INVOICE_CREDIT_MEMO_MODAL, { id });
  });

  const openVoidInvoice = withLimitedAccessCheck(() => {
    openModal(INVOICE_VOID_MODAL, { id });
  });

  const openEditInvoice = withLimitedAccessCheck(() => {
    openModal(INVOICE_MODAL, { id });
  });

  return (
    <Flex
      direction={isDesktop ? 'row' : 'column'}
      alignItems={isDesktop ? 'center' : 'flex-start'}
      justify="space-between"
      className={styles.actions}
    >
      <div>
        {isReceivePaymentAllowed(status) && (
          <PermissionGuard
            resource={PermissionResources.PAYMENT}
            action={PermissionActions.CREATE}
          >
            <Button skin="success" onClick={openProcessPayment}>
              <I18n id="invoiceInfo.actions.button.receivePayment" />
            </Button>
          </PermissionGuard>
        )}
        {status === InvoiceStatusesTypes.VOIDED && (
          <p className={styles.noteText}>
            <I18n id="invoiceInfo.actions.invoiceVoided" />
          </p>
        )}
        {status === InvoiceStatusesTypes.PAID && (
          <p className={styles.noteText}>
            <I18n id="invoiceInfo.actions.invoicePaid" />
          </p>
        )}
      </div>
      <Flex className={styles.nonPaymentActions} alignItems="center">
        {isVoidAllowed(status) && (
          <PermissionGuard
            resource={PermissionResources.VOID_INVOICE}
            action={PermissionActions.CREATE}
          >
            <Button
              className={styles.action}
              skin="shell"
              onClick={openVoidInvoice}
            >
              <VoidIcon className={styles.icon} />
              <I18n id="invoiceInfo.actions.button.void" />
            </Button>
          </PermissionGuard>
        )}
        {isEditAllowed(status) && (
          <PermissionGuard
            resource={PermissionResources.INVOICE}
            action={PermissionActions.UPDATE}
          >
            <Button
              className={styles.action}
              skin="shell"
              onClick={openEditInvoice}
            >
              <EditIcon className={styles.icon} />
              <I18n id="invoiceInfo.actions.button.edit" />
            </Button>
          </PermissionGuard>
        )}
        {isCreditMemoAllowed(status) && (
          <PermissionGuard
            resource={PermissionResources.CREDIT_MEMO}
            action={PermissionActions.CREATE}
          >
            <Button
              className={styles.action}
              skin="shell"
              onClick={openCreditMemo}
            >
              <CreditMemoIcon className={styles.icon} />
              <I18n id="invoiceInfo.actions.button.creditMemo" />
            </Button>
          </PermissionGuard>
        )}
      </Flex>
    </Flex>
  );
}
