import { gql } from '@apollo/client';

export const FETCH_FIRM_TIME_ZONE = gql`
  query FetchFirmTimeZone {
    firm: getFirm {
      id
      timezone {
        full
        short
      }
    }
  }
`;
