import React from 'react';
import classnames from 'classnames';

import { ScheduledPaymentStatuses } from '__generated__/globalTypes';

import { ScheduledPaymentStatusPropsType } from './ScheduledPaymentStatus.types';
import styles from './ScheduledPaymentStatus.module.scss';
import Status from './components/Status';

export default function ScheduledPaymentStatus({
  status,
  className,
}: ScheduledPaymentStatusPropsType) {
  return (
    <span
      className={classnames(className, {
        [styles.paid]: status === ScheduledPaymentStatuses.PAID,
        [styles.scheduled]: status === ScheduledPaymentStatuses.SCHEDULED,
        [styles.failed]: status === ScheduledPaymentStatuses.FAILED,
      })}
    >
      <Status status={status} />
    </span>
  );
}
