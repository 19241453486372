import { useCallback, useContext, useMemo } from 'react';
import noop from 'lodash/noop';
import { v1 as uuid } from 'uuid';
import { PopupModalProviderContext } from '../../components/common/PopupModalProvider/PopupModalProvider.context';
import { PopupModalComponentType } from '../../components/common/PopupModalProvider/PopupModalProvider.types';

export default function usePopupModal<
  TReturnData,
  TComponentType extends PopupModalComponentType<TReturnData, any>,
  TData extends Omit<
    Parameters<TComponentType>['0'],
    'onComplete' | 'onCancel'
  >,
>(
  Component: TComponentType,
  onComplete: Parameters<TComponentType>['0']['onComplete'] = noop,
  onCancel: Parameters<TComponentType>['0']['onCancel'] = noop,
) {
  const id = useMemo(() => uuid(), []);
  const { onOpen, onClose } = useContext(PopupModalProviderContext);

  const onOpenModal = useCallback(
    (data?: TData) => {
      onOpen(id, Component, onComplete, onCancel, data);
    },
    [Component, onComplete, onCancel, onOpen, id],
  );

  const onCloseModal = useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  return { openPopupModal: onOpenModal, closePopupModal: onCloseModal };
}
