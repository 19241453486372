import { gql } from '@apollo/client';

export const GET_UPLOAD_FILES_URL = gql`
  mutation GetUploadFilesUrl($files: [FileDataToUploadInput!]!) {
    uploads: getSignedUrlForUploadingFiles(
      getSignedUrlForUploadingFilesInput: { filesToUpload: $files }
    ) {
      files {
        id
        name
        mimetype
        url
      }
    }
  }
`;
