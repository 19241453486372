import React from 'react';
import { Button, ButtonGroup, Flex, Offset } from '@appclose/ui';
import { Form, Tab, TabList, TabPanel, Tabs } from '@appclose/core';

import { I18n } from 'i18n';

import ContactFieldset from './components/ContactFieldset';
import EntityFieldset from './components/EntityFieldset';
import { ContactPopupFormSchema } from './ContactPopupForm.schema';
import { ContactPopupFormPropsType } from './ContactPopupForm.types';

export default function ContactPopupForm({
  initialValues = {} as any,
  onSubmit,
  onCancel,
}: ContactPopupFormPropsType) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ContactPopupFormSchema}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <>
            <Offset bottom="xl">
              <Tabs onSelect={(index) => setFieldValue('selectedTab', index)}>
                <TabList>
                  <Tab>
                    <I18n id="modal.popup.contact.form.tab.individual" />
                  </Tab>
                  <Tab>
                    <I18n id="modal.popup.contact.form.tab.entity" />
                  </Tab>
                </TabList>
                <TabPanel>
                  <ContactFieldset />
                </TabPanel>
                <TabPanel>
                  <EntityFieldset />
                </TabPanel>
              </Tabs>
            </Offset>
            <Flex justify="center">
              <ButtonGroup>
                <Button onClick={onCancel}>
                  <I18n id="modal.popup.contact.form.cancel" />
                </Button>
                <Button loading={isSubmitting} type="submit" skin="brand">
                  <I18n id="modal.popup.contact.form.create" />
                </Button>
              </ButtonGroup>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
