export enum ContactModalModalTabs {
  INDIVIDUAL,
  ENTITY,
}

export type ContactModalPropsType = {
  id?: string;
  onClose(): void;
  defaultTab?: ContactModalModalTabs;
  openNew?: boolean;
};
