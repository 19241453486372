import * as Yup from 'yup';

import {
  AddressInput,
  AddressTypes,
  UsaStates,
} from '__generated__/globalTypes';
import { getI18nErrorMessage } from 'i18n';

export const ADDRESS_INPUT_SCHEMA = Yup.object().shape<AddressInput>({
  zipCode: Yup.string()
    .required(getI18nErrorMessage('error.address.zipCode.required'))
    .min(5, ({ min }) =>
      getI18nErrorMessage('error.address.zipCode.minLength', { min })
    ),
  state: Yup.mixed<UsaStates>().required(
    getI18nErrorMessage('error.address.state.required')
  ),
  city: Yup.string()
    .required(getI18nErrorMessage('error.address.city.required'))
    .min(2, ({ min }) =>
      getI18nErrorMessage('error.address.city.minLength', { min })
    )
    .max(38, ({ max }) =>
      getI18nErrorMessage('error.address.city.maxLength', { max })
    ),
  address1: Yup.string()
    .required(getI18nErrorMessage('error.address.address1.required'))
    .min(2, ({ min }) =>
      getI18nErrorMessage('error.address.address1.minLength', { min })
    )
    .max(50, ({ max }) =>
      getI18nErrorMessage('error.address.address1.maxLength', { max })
    ),
  address2: Yup.string()
    .nullable()
    .min(2, ({ min }) =>
      getI18nErrorMessage('error.address.address2.minLength', { min })
    )
    .max(50, ({ max }) =>
      getI18nErrorMessage('error.address.address2.maxLength', { max })
    ),
  type: Yup.mixed<AddressTypes>(),
});
