import React, { useCallback, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { currency, FormDataType, traceError } from '@appclose/core';

import EmailPreview from 'components/common/EmailPreview';
import { ModalSendEmailPage } from 'components/common/ModalSendEmailPage';
import { getContactName, getEntityPrimaryContact } from 'controllers/contact';
import SendEmailForm, {
  SendEmailFormActionsType,
  SendEmailFormValuesType,
} from 'components/form/SendEmailForm';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import {
  FetchPaymentQuery,
  FetchPaymentQueryVariables,
  SendPaymnetRefundMutation,
  SendPaymnetRefundMutationVariables,
} from './__generated__/SendRefundModal.gql';
import { FETCH_PAYMENT, SEND_PAYMENT_REFUND } from './SendRefundModal.gql';
import { SendRefundModalPropsType } from './SendRefundModal.types';

export default function SendRefundModal({
  id,
  onClose,
}: SendRefundModalPropsType) {
  const { t } = useIntl();
  const { filesLoading, files } = useListFiles({
    entityType: Entities.PAYMENT,
    input: { filter: { entityIds: [id] } },
  });
  const [
    sendEmailFormValues,
    setSendEmailFormValues,
  ] = useState<SendEmailFormValuesType>();
  const { loading, data } = useQuery<
    FetchPaymentQuery,
    FetchPaymentQueryVariables
  >(FETCH_PAYMENT, { variables: { id: id as string } });
  const [sendPaymentRefund] = useMutation<
    SendPaymnetRefundMutation,
    SendPaymnetRefundMutationVariables
  >(SEND_PAYMENT_REFUND);
  const payment = data?.payment;
  const firm = data?.firm;
  const contact = payment && getEntityPrimaryContact(payment);
  const initialValues = useMemo<SendEmailFormValuesType>(
    () => ({
      id,
      to: payment?.contact as any,
      subject: t('modal.sendRefund.subject', { firm: firm?.name }),
      text: t('modal.sendRefund.message', {
        contact: contact && getContactName(contact),
        amount: payment?.amount && currency().format(payment?.amount),
      }),
      files: files?.items,
      contactId: contact?.id || '',
      from: firm?.name || '',
      includeAccountStatementLink: true,
    }),
    [id, payment, firm, contact, t, files]
  );

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = async (
    { files, from, to, ...values }: SendEmailFormValuesType,
    { setSubmitting }: SendEmailFormActionsType
  ) => {
    try {
      await sendPaymentRefund({
        variables: {
          sendRefundPaymentInput: {
            ...values,
            files: files?.map(({ id }) => id) as string[],
          },
        },
      });

      onClose();

      notification().emailSent();
    } catch (e) {
      traceError(e as Error);
      setSubmitting(false);
    }
  };

  const handleFormChange = useCallback(
    (data: FormDataType<SendEmailFormValuesType>) => {
      onFormChange(data);
      setSendEmailFormValues(data.values);
    },
    [onFormChange]
  );

  return (
    <ModalSendEmailPage
      title={t('modal.sendRefund.title')}
      loading={loading || filesLoading}
      onClose={onConfirmClose}
    >
      <SendEmailForm
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        onCancel={onConfirmClose}
        onChange={handleFormChange}
      />
      <EmailPreview
        text={sendEmailFormValues?.text}
        firm={firm}
        files={sendEmailFormValues?.files}
        includeAccountStatementLink={
          sendEmailFormValues?.includeAccountStatementLink
        }
      />
    </ModalSendEmailPage>
  );
}
