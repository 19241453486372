import { useEffect, useState } from 'react';
import { history } from '@appclose/core';
import { isProductionMode } from '@appclose/lib';
import * as serviceWorkerRegistration from '../../serviceWorker';

const isProduction = isProductionMode();
const ONE_HOUR_DELAY = 1000 * 60 * 60;

export function useServiceWorkerUpdate() {
  const [hasUpdate, setHasUpdate] = useState(false);

  useEffect(() => {
    if (isProduction) {
      serviceWorkerRegistration.register({
        onUpdate: () => {
          setHasUpdate(true);
        },
      });

      setInterval(() => {
        void navigator.serviceWorker
          .getRegistration()
          .then((reg) => reg?.update());
      }, ONE_HOUR_DELAY);
    }
  }, []);

  useEffect(() => {
    if (isProduction) {
      history.listen(() => {
        if (hasUpdate) {
          window.location.reload();
        }
      });
    }
  }, [hasUpdate]);
}
