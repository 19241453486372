import { ReactElement } from 'react';

import { PaymentStatuses } from '__generated__/globalTypes';
import Label, { LabelPropsType } from 'components/common/Label';
import { I18n } from 'i18n';

import { PaymentStatusPropsType } from './PaymentStatus.types';

const PaymentStatusLabels: Record<
  PaymentStatuses,
  ReactElement<LabelPropsType>
> = {
  [PaymentStatuses.CLEARED]: (
    <Label theme="success">
      <I18n id="enum.paymentStatuses.cleared" />
    </Label>
  ),
  [PaymentStatuses.FAILED]: (
    <Label theme="warning">
      <I18n id="enum.paymentStatuses.failed" />
    </Label>
  ),
  [PaymentStatuses.PENDING]: (
    <Label theme="info">
      <I18n id="enum.paymentStatuses.pending" />
    </Label>
  ),
};

export default function PaymentStatus({ status }: PaymentStatusPropsType) {
  return PaymentStatusLabels[status] || null;
}
