import * as Yup from 'yup';

import { AttachedFileType } from 'components/common/AttachedFile';
import { getI18nErrorMessage } from 'i18n';

import { SendEmailFormValuesType } from './SendEmailForm.types';

export const SendEmailFormSchema = Yup.object().shape<SendEmailFormValuesType>({
  id: Yup.string().required(),
  from: Yup.string().required(),
  to: Yup.object<any>().required(),
  contactId: Yup.string().required(
    getI18nErrorMessage('form.sendEmail.error.files.contact')
  ),
  files: Yup.array<AttachedFileType>().test(
    'maxFileSize',
    getI18nErrorMessage('form.sendEmail.error.files.maxFileSize'),
    (files) => {
      const size =
        files?.reduce((total, { size }) => total + (size || 0), 0) || 0;

      return size <= 20971520;
    }
  ),
  includeAccountStatementLink: Yup.boolean().required(),
  subject: Yup.string()
    .required(getI18nErrorMessage('form.sendEmail.error.subject.required'))
    .max(500, ({ max }) =>
      getI18nErrorMessage('form.sendEmail.error.subject.maxLength', { max })
    ),
  text: Yup.string()
    .required(getI18nErrorMessage('form.sendEmail.error.text.required'))
    .max(1000, ({ max }) =>
      getI18nErrorMessage('form.sendEmail.error.text.maxLength', { max })
    ),
});
