import React, { useState, useCallback, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { currency, FormDataType, traceError } from '@appclose/core';

import EmailPreview from 'components/common/EmailPreview';
import { ModalSendEmailPage } from 'components/common/ModalSendEmailPage';
import { getContactName, getEntityPrimaryContact } from 'controllers/contact';
import SendEmailForm, {
  SendEmailFormActionsType,
  SendEmailFormValuesType,
} from 'components/form/SendEmailForm';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import {
  FetchPaymentQuery,
  FetchPaymentQueryVariables,
  SendPaymentReceiptMutation,
  SendPaymentReceiptMutationVariables,
} from './__generated__/SendPaymentReceiptModal.gql';
import {
  FETCH_PAYMENT,
  SEND_PAYMENT_RECEIPT,
} from './SendPaymentReceiptModal.gql';
import { SendPaymentReceiptModalPropsType } from './SendPaymentReceiptModal.types';

export default function SendPaymentReceiptModal({
  id,
  onClose,
}: SendPaymentReceiptModalPropsType) {
  const { t } = useIntl();
  const { filesLoading, files } = useListFiles({
    entityType: Entities.PAYMENT,
    input: { filter: { entityIds: [id] } },
  });
  const [
    sendEmailFormValues,
    setSendEmailFormValues,
  ] = useState<SendEmailFormValuesType>();
  const { loading, data } = useQuery<
    FetchPaymentQuery,
    FetchPaymentQueryVariables
  >(FETCH_PAYMENT, { variables: { id } });
  const [sendPaymentReceipt] = useMutation<
    SendPaymentReceiptMutation,
    SendPaymentReceiptMutationVariables
  >(SEND_PAYMENT_RECEIPT);
  const payment = data?.payment;
  const firm = data?.firm;
  const amount = payment?.amount || 0;
  const contact = payment && getEntityPrimaryContact(payment);
  const initialValues = useMemo<SendEmailFormValuesType>(
    () => ({
      id,
      to: payment?.contact as any,
      subject: t('modal.sendPaymentReceipt.subject', { firm: firm?.name }),
      text: t('modal.sendPaymentReceipt.message', {
        contact: contact && getContactName(contact),
        amount: currency().format(amount),
      }),
      from: firm?.name || '',
      contactId: contact?.id || '',
      files: files?.items,
      includeAccountStatementLink: true,
    }),
    [id, payment, t, firm, contact, amount, files]
  );

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async (
      { files, from, to, ...values }: SendEmailFormValuesType,
      { setSubmitting }: SendEmailFormActionsType
    ) => {
      try {
        await sendPaymentReceipt({
          variables: {
            sendPaymentInput: {
              ...values,
              files: files?.map(({ id }) => id) as string[],
            },
          },
        });

        onClose();

        notification().emailSent();
      } catch (e) {
        traceError(e as Error);
        setSubmitting(false);
      }
    },
    [onClose, sendPaymentReceipt]
  );

  const handleFormChange = useCallback(
    (data: FormDataType<SendEmailFormValuesType>) => {
      onFormChange(data);
      setSendEmailFormValues(data.values);
    },
    [onFormChange]
  );

  return (
    <ModalSendEmailPage
      title={t('modal.sendPaymentReceipt.title')}
      loading={loading || filesLoading}
      onClose={onConfirmClose}
    >
      <SendEmailForm
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        onCancel={onConfirmClose}
        onChange={handleFormChange}
      />
      <EmailPreview
        text={sendEmailFormValues?.text}
        firm={firm}
        files={sendEmailFormValues?.files}
        includeAccountStatementLink={
          sendEmailFormValues?.includeAccountStatementLink
        }
      />
    </ModalSendEmailPage>
  );
}
