export const PUBLIC_URL = process.env.PUBLIC_URL;
export const LANDING_URL = process.env.REACT_APP_REDIRECT_TO_LANDING;
export const OAUTH_REDIRECT = process.env.REACT_APP_OAUTH_REDIRECT;
export const APPCLOSE_OAUTH_URL = process.env.REACT_APP_APPCLOSE_OAUTH_URL;
export const APPCLOSE_OAUTH_APP_NAME =
  process.env.REACT_APP_APPCLOSE_OAUTH_APP_NAME;
export const USIO_URL = process.env.REACT_APP_USIO_URL;
export const USIO_ENROLLMENT_URL = process.env.REACT_APP_USIO_ENROLLMENT_URL;
export const HELLOSIGN_CLIENT_ID = process.env.REACT_APP_HELLOSIGN_CLIENT_ID;
export const NOTIFICATION_EMAIL = process.env.REACT_APP_NOTIFICATION_EMAIL;
export const QBO_PRINT_CHECKS_URL = process.env.REACT_APP_QBO_PRINT_CHECKS_URL;
export const GOOGLE_TAG_MANAGER_CONTAINER_ID =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID;
export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY || '';
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY || '';
export const APP_STORE_LINK =
  'http://itunes.apple.com/us/app/appclose/id1019290876?mt=8';
export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.appclose.androidapp';

export const ESIGNATURE_ENABLE =
  process.env.REACT_APP_ESIGNATURE_ENABLE === 'true';
export const MFA_ENABLE = process.env.REACT_APP_MFA_ENABLE === 'true';
