import { gql } from '@apollo/client';

export const INVOICE_REMINDERS_FRAGMENT = gql`
  fragment InvoiceReminders on InvoiceType {
    id
    status
    paymentReminder {
      overdueReminder {
        reminderSchedule {
          date
          sent
        }
      }
      prepaymentReminder {
        reminderSchedule {
          date
          sent
        }
      }
    }
  }
`;
