import React, { useEffect } from 'react';
import { Route as ReactRouterDom, useLocation } from 'react-router-dom';
import { getTokenPayload } from '@appclose/lib';
import { dateManager, history, Loader, traceError } from '@appclose/core';

import NotFoundPageAsync from 'components/common/NotFoundPage/NotFoundPageAsync';
import ModalProvider from 'components/modals/pages/ModalProvider';
import { publicSession } from 'controllers/publicSession';
import useUrlSearchParams from 'hooks/useUrlSearchParams';

import { PublicRoutePropsType } from './PublicRoute.types';

export default function PublicRoute({
  path,
  exact = false,
  session = publicSession,
  component: WrappedComponent,
}: PublicRoutePropsType): React.ReactElement {
  const { pathname } = useLocation();
  const {
    searchParams: { token: rawToken },
    getSearchParamsAsString,
  } = useUrlSearchParams<{
    token?: string;
  }>();

  const token = rawToken || session.getAccessToken();
  let tokenPayload: { [key: string]: any; exp?: number } | undefined;

  try {
    tokenPayload = token ? getTokenPayload(token) : undefined;
  } catch (e) {
    traceError(e as Error);
  }

  useEffect(() => {
    if (rawToken && session.getAccessToken()) {
      history.replace({ pathname, search: getSearchParamsAsString(['token']) });
    }
  }, [rawToken, pathname, session, getSearchParamsAsString]);

  if (
    !token ||
    !tokenPayload ||
    (tokenPayload?.exp &&
      dateManager().parse(tokenPayload.exp, 'X').isBefore(new Date()))
  ) {
    return <NotFoundPageAsync />;
  }

  session.setAccessToken(token);

  if (rawToken) {
    return <Loader />;
  }

  return (
    <ReactRouterDom
      exact={exact}
      path={path}
      render={(props: any) => (
        <>
          <WrappedComponent
            {...props}
            token={token}
            tokenPayload={tokenPayload}
          />
          <ModalProvider />
        </>
      )}
    />
  );
}
