import React from 'react';
import classnames from 'classnames';
import { Table, TablePropsType } from '@appclose/ui';

import styles from './MobileTable.module.scss';

export default function MobileTable({
  className,
  children,
  ...props
}: TablePropsType) {
  return (
    <Table className={classnames(styles.table, className)} {...props}>
      {children}
    </Table>
  );
}
