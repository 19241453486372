import { gql } from '@apollo/client';

import { CONTACT_SELECT_FRAGMENT } from 'components/fields/ContactSelectFormField/ContactSelectFormField.gql';

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(createContactInput: $input) {
      ...ContactSelect
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;
