import { TextArea, TextAreaPropsType } from '@appclose/ui';
import { Field } from 'formik';
import React, { Ref, useCallback } from 'react';

import { getFieldError } from '../../../../..';
import field, { FiledPropsType } from '../../../../hoc/field';

import {
  TextAreaFormFieldType,
  TextAreaFormFieldWrapperType,
} from './TextAreaFormField.types';

export const TextAreaField = React.forwardRef(
  (
    { value, ...props }: FiledPropsType<TextAreaPropsType>,
    ref: Ref<HTMLTextAreaElement>
  ) => field({ value, ...props, ref })(TextArea)
);

// prettier-ignore
const TextAreaFormFieldWrapper = ({
  field: {value, ...field},
  form,
  onChange,
  ...rest
}: TextAreaFormFieldWrapperType) => {
  const error = getFieldError(form, field.name);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event);

    form.setFieldValue(field.name, event.target.value, true);
  }, [onChange, form, field.name]);

  return <TextAreaField {...field} {...rest} value={value || ''} onChange={handleOnChange} error={error} />;
};

const TextAreaFormField = (props: TextAreaFormFieldType) => {
  return <Field {...props} component={TextAreaFormFieldWrapper} />;
};

export default TextAreaFormField;
