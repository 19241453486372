import { useCallback, useMemo } from 'react';
import { useIntl } from 'i18n';
import { QboSyncHistoryAction } from '../../__generated__/globalTypes';

export default function useQboSyncHistoryActions() {
  const { t } = useIntl();

  const qboSyncHistoryActionTypes: Record<
    QboSyncHistoryAction,
    string
  > = useMemo(
    () => ({
      [QboSyncHistoryAction.FIRM_AUTH_TOKEN_MISSED]: t(
        'enum.qboSyncHistoryAction.firmAuthTokenMissed'
      ),
      [QboSyncHistoryAction.FIRM_AUTH_REFRESH_FAILED]: t(
        'enum.qboSyncHistoryAction.firmAuthRefreshFailed'
      ),
      [QboSyncHistoryAction.FIRM_MONTHLY_FEES_CREATED]: t(
        'enum.qboSyncHistoryAction.firmMonthlyFeesCreated'
      ),
      [QboSyncHistoryAction.FIRM_MONTHLY_FEES_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.firmMonthlyFeesCreationFailed'
      ),
      [QboSyncHistoryAction.FIRM_MONTHLY_FEES_MAPPINGS_MISSED]: t(
        'enum.qboSyncHistoryAction.firmMonthlyFeesMappingMissed'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_CLEARED]: t(
        'enum.qboSyncHistoryAction.disbursementCleared'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_NO_MAPPINGS_FOUND]: t(
        'enum.qboSyncHistoryAction.disbursementMappingsNotFound'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_CLEAR_FAILED]: t(
        'enum.qboSyncHistoryAction.disbursementClearFailed'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_CLEAR_VALIDATION_ERROR]: t(
        'enum.qboSyncHistoryAction.disbursementClearValidationError'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_VALIDATION_ERROR]: t(
        'enum.qboSyncHistoryAction.disbursementValidationError'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.disbursementCreationFailed'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_CREATED]: t(
        'enum.qboSyncHistoryAction.disbursementCreation'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_NOT_EXPORTED]: t(
        'enum.qboSyncHistoryAction.disbursementNotExported'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_PRINT_STATUS_CHANGE_FAILED]: t(
        'enum.qboSyncHistoryAction.disbursementPrintStatusChangeFailed'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_PRINTED]: t(
        'enum.qboSyncHistoryAction.disbursementPrinted'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_NEED_TO_PRINT]: t(
        'enum.qboSyncHistoryAction.disbursementNeedToPrint'
      ),
      [QboSyncHistoryAction.DISBURSEMENT_VOIDED]: t(
        'enum.qboSyncHistoryAction.disbursementVoided'
      ),
      [QboSyncHistoryAction.OTO_NO_MAPPINGS_FOUND]: t(
        'enum.qboSyncHistoryAction.OTOMappingsNotFound'
      ),
      [QboSyncHistoryAction.OTO_CUSTOMER_NOT_EXPORTED]: t(
        'enum.qboSyncHistoryAction.OTOCustomerNotExported'
      ),
      [QboSyncHistoryAction.OTO_CREATED]: t(
        'enum.qboSyncHistoryAction.OTOCreated'
      ),
      [QboSyncHistoryAction.OTO_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.OTOCreationFailed'
      ),
      [QboSyncHistoryAction.OTO_VALIDATION_ERROR]: t(
        'enum.qboSyncHistoryAction.OTOValidationError'
      ),
      [QboSyncHistoryAction.DEPOSIT_NO_MAPPINGS_FOUND]: t(
        'enum.qboSyncHistoryAction.depositMappingsNotFound'
      ),
      [QboSyncHistoryAction.DEPOSIT_VALIDATION_ERROR]: t(
        'enum.qboSyncHistoryAction.depositValidationError'
      ),
      [QboSyncHistoryAction.DEPOSIT_CREATED]: t(
        'enum.qboSyncHistoryAction.depositCreated'
      ),
      [QboSyncHistoryAction.DEPOSIT_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.depositCreationFailed'
      ),
      [QboSyncHistoryAction.PAYMENT_CUSTOMER_NOT_EXPORTED]: t(
        'enum.qboSyncHistoryAction.paymentCustomerNotExported'
      ),
      [QboSyncHistoryAction.PAYMENT_NO_INVOICES_FOUND]: t(
        'enum.qboSyncHistoryAction.paymentInvoiceNotFound'
      ),
      [QboSyncHistoryAction.PAYMENT_CREATED]: t(
        'enum.qboSyncHistoryAction.paymentCreated'
      ),
      [QboSyncHistoryAction.PAYMENT_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.paymentCreationFailed'
      ),
      [QboSyncHistoryAction.PAYMENT_REFUND_NO_MAPPINGS_FOUND]: t(
        'enum.qboSyncHistoryAction.refundMappingsNotFound'
      ),
      [QboSyncHistoryAction.PAYMENT_REFUND_CUSTOMER_NOT_EXPORTED]: t(
        'enum.qboSyncHistoryAction.refundCustomerNotExported'
      ),
      [QboSyncHistoryAction.PAYMENT_REFUND_NO_INVOICES_FOUND]: t(
        'enum.qboSyncHistoryAction.refundInvoiceNotFound'
      ),
      [QboSyncHistoryAction.PAYMENT_REFUND_CREATED]: t(
        'enum.qboSyncHistoryAction.refundCreated'
      ),
      [QboSyncHistoryAction.PAYMENT_REFUND_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.refundCreationFailed'
      ),
      [QboSyncHistoryAction.CUSTOMER_CREATED]: t(
        'enum.qboSyncHistoryAction.customerCreated'
      ),
      [QboSyncHistoryAction.CUSTOMER_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.customerCreationFailed'
      ),
      [QboSyncHistoryAction.CUSTOMER_NAME_DUPLICATED]: t(
        'enum.qboSyncHistoryAction.customerNameDuplicated'
      ),
      [QboSyncHistoryAction.CUSTOMER_UPDATED]: t(
        'enum.qboSyncHistoryAction.customerUpdated'
      ),
      [QboSyncHistoryAction.CUSTOMER_UPDATE_FAILED]: t(
        'enum.qboSyncHistoryAction.customerUpdateFailed'
      ),
      [QboSyncHistoryAction.CUSTOMER_NOT_EXPORTED]: t(
        'enum.qboSyncHistoryAction.customerNotExported'
      ),
      [QboSyncHistoryAction.INVOICE_CREATED]: t(
        'enum.qboSyncHistoryAction.invoiceCreated'
      ),
      [QboSyncHistoryAction.INVOICE_UPDATED]: t(
        'enum.qboSyncHistoryAction.invoiceUpdated'
      ),
      [QboSyncHistoryAction.INVOICE_UPDATE_FAILED]: t(
        'enum.qboSyncHistoryAction.invoiceUpdateFailed'
      ),
      [QboSyncHistoryAction.INVOICE_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.invoiceCreationFailed'
      ),
      [QboSyncHistoryAction.INVOICE_NUMBER_DUPLICATED]: t(
        'enum.qboSyncHistoryAction.invoiceNumberDuplicated'
      ),
      [QboSyncHistoryAction.INVOICE_CUSTOMER_NOT_EXPORTED]: t(
        'enum.qboSyncHistoryAction.invoiceCustomerNotExported'
      ),
      [QboSyncHistoryAction.INVOICE_MAPPINGS_MISSED]: t(
        'enum.qboSyncHistoryAction.invoiceMappingsMissed'
      ),
      [QboSyncHistoryAction.INVOICE_NOT_EXPORTED]: t(
        'enum.qboSyncHistoryAction.invoiceNotExported'
      ),
      [QboSyncHistoryAction.INVOICE_VOIDED]: t(
        'enum.qboSyncHistoryAction.invoiceVoided'
      ),
      [QboSyncHistoryAction.INVOICE_VOID_FAILED]: t(
        'enum.qboSyncHistoryAction.invoiceVoidFailed'
      ),
      [QboSyncHistoryAction.CREDIT_MEMO_MAPPINGS_MISSED]: t(
        'enum.qboSyncHistoryAction.creditMemoMappingsMissed'
      ),
      [QboSyncHistoryAction.CREDIT_MEMO_INVOICE_NOT_EXPORTED]: t(
        'enum.qboSyncHistoryAction.creditMemoInvoiceNotExported'
      ),
      [QboSyncHistoryAction.CREDIT_MEMO_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.creditMemoCreationFailed'
      ),
      [QboSyncHistoryAction.CREDIT_MEMO_NUMBER_DUPLICATED]: t(
        'enum.qboSyncHistoryAction.creditMemoNumberDuplicated'
      ),
      [QboSyncHistoryAction.CREDIT_MEMO_PAYMENT_CREATE_FAILED]: t(
        'enum.qboSyncHistoryAction.creditMemoPaymentCreationFailed'
      ),
      [QboSyncHistoryAction.CREDIT_MEMO_CREATED]: t(
        'enum.qboSyncHistoryAction.creditMemoCreated'
      ),
    }),
    [t]
  );

  const getQboSyncHistoryAction = useCallback(
    (type?: QboSyncHistoryAction | null) => {
      if (!type) {
        return '';
      }

      return qboSyncHistoryActionTypes[type];
    },
    [qboSyncHistoryActionTypes]
  );

  return { getQboSyncHistoryAction };
}
