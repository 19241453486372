import { history, permissionProvider } from '@appclose/core';
import { getTokenPayload } from '@appclose/lib';

import { session } from '../session';
import { EsqPermissionType } from './permission.types';

export function initPermissionProvider() {
  permissionProvider(() => {
    const params = new URLSearchParams(history.location.search);
    const sessionToken = session.getAccessToken();
    const token = params.get('token') || sessionToken;

    if (!token) {
      return {};
    }

    try {
      return getTokenPayload(token).esq || {};
    } catch (e) {
      if (sessionToken) {
        return getTokenPayload(sessionToken).esq || {};
      }

      return {};
    }
  });
}

export function checkEsqPermission({
  resource,
  action,
  permissions,
}: EsqPermissionType): boolean {
  return (
    (resource && action
      ? permissionProvider().hasPermission(resource, action)
      : true) &&
    (permissions ? permissionProvider().hasPermissions(permissions) : true)
  );
}
