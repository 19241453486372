import React from 'react';
import noop from 'lodash/noop';
import classnames from 'classnames';
import { Checkbox } from '@appclose/ui';
import { FieldError, useFormContext } from '@appclose/core';

import AttachedFile, { AttachedFileType } from 'components/common/AttachedFile';
import { useIntl } from 'i18n';

import { AttachedFilesSelectFormFieldPropsType } from './AttachedFilesSelectFormField.types';
import styles from './AttachedFilesSelectFormField.module.scss';

export default function AttachedFilesSelectFormField({
  files,
  label,
  name,
  className,
  onChange,
}: AttachedFilesSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { setFieldValue, getFieldMeta } = useFormContext();

  const { value: selectedFiles } = getFieldMeta<AttachedFileType[]>(name);

  return (
    <div className={className}>
      <p className={styles.title}>
        {label || t('field.attachedFilesSelect.label')}
      </p>
      {files.map((file) => {
        const selected = selectedFiles.some(({ id }) => id === file.id);

        return (
          <AttachedFile
            key={file.id}
            file={file}
            className={classnames(styles.file, {
              [styles.unselectedFile]: !selected,
            })}
            onClick={() => {
              const newSelectedFiles = selected
                ? selectedFiles.filter(({ id }) => file.id !== id)
                : [...selectedFiles, file];

              setFieldValue(name, newSelectedFiles, true);
              onChange && onChange(newSelectedFiles);
            }}
          >
            <Checkbox
              checked={selected}
              onChange={noop}
              className={styles.checkBox}
            />
          </AttachedFile>
        );
      })}
      <FieldError name={name} />
    </div>
  );
}
