import React, { useEffect } from 'react';
import {
  ConfirmProvider,
  GqlErrorContext,
  NotificationProvider,
  PopupModalProvider,
} from '@appclose/core';

import { getGqlErrorResolvers } from './AppContent.constants';
import Router from 'components/common/Router';
import Zendesk from 'components/common/Zendesk';
import useEnvForwardingParams from 'hooks/useEnvForwardingParams';
import { useIntl } from 'i18n';
import { useLocation } from 'react-router-dom';
import { analytics } from 'controllers/analytics';

export default function AppContent() {
  const { t } = useIntl();
  const { savePrams } = useEnvForwardingParams();
  const location = useLocation();

  useEffect(() => {
    savePrams();
    // Save query params only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analytics().page();
  }, [location]);

  return (
    <GqlErrorContext.Provider
      value={{
        errorResolver: getGqlErrorResolvers(t),
      }}
    >
      <PopupModalProvider>
        <Router />
      </PopupModalProvider>
      <ConfirmProvider />
      <NotificationProvider />
      <Zendesk />
    </GqlErrorContext.Provider>
  );
}
