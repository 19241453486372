import { useIntl } from 'i18n';
import { FirmSizes } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useFirmSizes() {
  const { t } = useIntl();
  const firmSizes: Record<FirmSizes, string> = useMemo(
    () => ({
      [FirmSizes.S1]: t('enum.firmSizes.s1'),
      [FirmSizes.S2_4]: t('enum.firmSizes.s2_4'),
      [FirmSizes.S5_9]: t('enum.firmSizes.s5_9'),
      [FirmSizes.S10_19]: t('enum.firmSizes.s10_19'),
      [FirmSizes.S20_99]: t('enum.firmSizes.s20_99'),
      [FirmSizes.S100_500]: t('enum.firmSizes.s100_500'),
      [FirmSizes.S501_PLUS]: t('enum.firmSizes.s501_plus'),
      [FirmSizes.NOT_A_LAW_FIRM]: t('enum.firmSizes.notLawFirm'),
    }),
    [t]
  );

  const firmSizesOptions: {
    label: string;
    value: FirmSizes;
  }[] = useMemo(
    () =>
      Object.entries(firmSizes).map(([value, label]) => ({
        value: value as FirmSizes,
        label,
      })),
    [firmSizes]
  );

  const getFirmSizeLabel = useCallback(
    (type?: FirmSizes | null) => {
      if (!type) {
        return '';
      }

      return firmSizes[type];
    },
    [firmSizes]
  );

  return { firmSizesOptions, getFirmSizeLabel };
}
