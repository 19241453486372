import React from 'react';
import { v1 as uuid } from 'uuid';
import classnames from 'classnames';

import { CheckboxType } from './Checkbox.types';
import styles from './Checkbox.module.scss';

export default function Checkbox({
  id,
  defaultChecked,
  checked,
  readOnly,
  label,
  name,
  theme,
  align = 'center',
  className,
  labelClassName,
  disabled = false,
  onChange,
  onBlur,
  onFocus,
}: CheckboxType): React.ReactElement {
  const checkboxId = id || uuid();

  return (
    <div
      className={classnames(
        {
          [styles.light]: theme === 'light',
          [styles.readOnly]: readOnly,
          [styles.disabled]: disabled,
        },
        className
      )}
    >
      <input
        type="checkbox"
        id={checkboxId}
        name={name}
        defaultChecked={defaultChecked}
        checked={checked}
        readOnly={readOnly}
        className={styles.checkbox}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <label
        htmlFor={checkboxId}
        className={classnames(
          styles.label,
          { [styles.hasLabel]: !!label },
          labelClassName
        )}
        style={{
          alignItems:
            (align === 'top' && 'flex-start') ||
            (align === 'bottom' && 'flex-end') ||
            align,
        }}
      >
        {label}
      </label>
    </div>
  );
}
