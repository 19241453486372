import React from 'react';
import { EntityIcon, ContactsIcon } from '@appclose/icons';

import { ContactEntityIconPropsType } from './ContactEntityIcon.types';

export default function ContactEntityIcon({
  isEntity,
}: ContactEntityIconPropsType) {
  if (isEntity) {
    return <EntityIcon />;
  }

  return <ContactsIcon />;
}
