import React, { useMemo } from 'react';
import { NO_BREAK_SPACE } from '@appclose/lib';
import { dateManager } from '@appclose/core';
import useTimeZone from 'hooks/useTimeZone';

import { DatePropsType } from './Date.types';

export default function Date({
  value,
  className,
  withTime,
  withWeekday,
  withoutFirmTimezone,
  shortYear,
}: DatePropsType) {
  const { fullTimeZone } = useTimeZone({ skip: withoutFirmTimezone });

  const weekdaySpec = withWeekday ? 'ddd, ' : '';
  const yearSpec = shortYear ? 'YY' : 'YYYY';
  const timeSpec = withTime ? `, h:mm${NO_BREAK_SPACE}A` : '';
  const withTimezone =
    !withoutFirmTimezone &&
    fullTimeZone &&
    typeof value === 'string' &&
    value.includes('T');

  const date = useMemo(() => {
    if (!value) {
      return '';
    }

    let parsedDate = dateManager().parse(value);

    if (withTimezone) {
      parsedDate = parsedDate.tz(fullTimeZone);
    }

    return parsedDate.format(
      `${weekdaySpec}MMM${NO_BREAK_SPACE}DD,${NO_BREAK_SPACE}${yearSpec}${timeSpec}`
    );
  }, [value, fullTimeZone, withTimezone, timeSpec, weekdaySpec, yearSpec]);

  return (
    <time dateTime={date} className={className}>
      {date}
    </time>
  );
}
