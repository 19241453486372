import { Button, Flex, Title3 } from '@appclose/ui';
import { CloseIcon } from '@appclose/icons';
import classnames from 'classnames';

import { ModalOverlay } from './components/ModalOverlay';
import { ModalPropsType } from './Modal.types';
import styles from './Modal.module.scss';

export default function Modal({
  title,
  theme,
  shouldCloseOnOverlayClick = true,
  overlayClassName,
  closeIcon = <CloseIcon />,
  closeButtonClassName,
  className,
  onClose,
  onAfterOpen,
  children,
  headerContent,
  topBarContent,
  data,
}: ModalPropsType) {
  return (
    <ModalOverlay
      onOverlayClick={shouldCloseOnOverlayClick ? onClose : undefined}
      className={classnames(
        styles.modal,
        {
          [styles.page]: theme === 'page',
          [styles.media]: theme === 'media',
        },
        className
      )}
      overlayClassName={classnames(
        styles.overlay,
        { [styles.pageOverlay]: theme === 'page' },
        { [styles.mediaOverlay]: theme === 'media' },
        overlayClassName
      )}
      data={data}
      onAfterOpen={onAfterOpen}
    >
      <section className={styles.main}>
        {theme !== 'media' && (
          <Flex
            as="header"
            direction="column"
            alignItems="stretch"
            className={styles.header}
            gap={[0, 20]}
          >
            <Flex justify="flex-end" alignItems="center">
              {topBarContent}
              <Button
                skin="shell"
                onClick={onClose}
                className={closeButtonClassName}
              >
                {closeIcon}
              </Button>
            </Flex>
            {title && <Title3 className={styles.title}>{title}</Title3>}
            {headerContent}
          </Flex>
        )}
        {theme === 'media' && (
          <Button
            skin="shell"
            onClick={onClose}
            className={classnames(styles.close, closeButtonClassName)}
          >
            {closeIcon}
          </Button>
        )}
        <>{children}</>
      </section>
    </ModalOverlay>
  );
}
