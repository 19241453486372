import React, { useMemo } from 'react';
import {
  EXPERT_SUPPORT_ROUTE,
  ExternalLink,
  SUPPORT_PAGE_URL,
} from '@appclose/core';

import { ExternalSupportLinkPropsType } from './ExternalSupportLink.types';

export default function ExternalSupportLink({
  isEmail,
  customText,
}: ExternalSupportLinkPropsType) {
  const EXPERT_SUPPORT = useMemo(
    () => EXPERT_SUPPORT_ROUTE.replace('mailto:', ''),
    []
  );
  const SUPPORT_PAGE = useMemo(
    () => SUPPORT_PAGE_URL.replace(/https?:\/\//, ''),
    []
  );

  const text = customText?.length
    ? customText
    : isEmail ? EXPERT_SUPPORT : SUPPORT_PAGE;

  return (
    <ExternalLink link={isEmail ? EXPERT_SUPPORT_ROUTE : SUPPORT_PAGE_URL}>
      {text}
    </ExternalLink>
  );
}
