import React, { useCallback } from 'react';
import { Button, Caption2, Caption3 } from '@appclose/ui';

import styles from './MultiSelectHeader.module.scss';
import { MultiSelectHeaderType } from './MultiSelectHeader.types';

export default function MultiSelectHeader({
  multipleOptionsTitle,
  multipleOkButton,
  isSelected = false,
  multipleClearButton,
  onOkClick,
  onClearClick,
}: MultiSelectHeaderType) {
  const handleOkClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onOkClick();
    },
    [onOkClick]
  );

  const handleClearClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onClearClick();
    },
    [onClearClick]
  );

  return (
    <div className={styles.default}>
      <Caption3 weight="light" className={styles.title}>
        {multipleOptionsTitle}
      </Caption3>
      <div className={styles.actionsContainer}>
        {isSelected && (
          <Button
            skin="shell"
            className={styles.actionClear}
            onClick={handleClearClick}
          >
            <Caption3 weight="light">{multipleClearButton}</Caption3>
          </Button>
        )}
        <Button skin="shell" onClick={handleOkClick}>
          <Caption2 weight="bold" uppercase>
            {multipleOkButton}
          </Caption2>
        </Button>
      </div>
    </div>
  );
}
