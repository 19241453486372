import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { Hint } from '../Hint';
import { StaticFieldPropsType } from './StaticField.types';
import styles from './StaticField.module.scss';

const NO_VALUE = '-';

export default function StaticField({
  title,
  disabled,
  skin,
  compact,
  tooltip,
  children,
  className,
  underlined = false,
}: StaticFieldPropsType) {
  const [showNoValue, setShowNoValue] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ref = contentRef.current;
    const contentEmpty = !!ref?.innerText?.replace(NO_VALUE, '');

    setShowNoValue(contentEmpty);
  }, [contentRef]);

  useEffect(() => {
    const ref = contentRef.current;
    const observer = new MutationObserver(() => {
      const contentEmpty = !!ref?.textContent?.replace(NO_VALUE, '');

      setShowNoValue(contentEmpty);
    });

    if (ref) {
      observer.observe(ref, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [contentRef]);

  return (
    <div
      className={classnames(styles.staticField, className, {
        [styles.hasTitle]: !!title,
        [styles.disabled]: disabled,
        [styles.clear]: skin === 'clear',
        [styles.compact]: compact,
        [styles.underlined]: underlined,
      })}
    >
      {title && (
        <span className={styles.title}>
          {title}
          {tooltip && <Hint iconClassName={styles.hint} content={tooltip} />}
        </span>
      )}
      <div ref={contentRef} className={styles.content}>
        {children}
        {!showNoValue && NO_VALUE}
      </div>
    </div>
  );
}
