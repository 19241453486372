import { gql } from '@apollo/client';

export const CONTACT_SELECT_FRAGMENT = gql`
  fragment ContactSelect on ContactType {
    id
    name
    firstName
    lastName
    email
    isEntity
    billingContacts {
      id
      email
      type
    }
  }
`;

export const FETCH_CONTACT_OPTIONS = gql`
  query FetchContactOptions(
    $filter: FilterContactInput
    $order: OrderContactInput
    $skip: Int
    $take: Int
  ) {
    listContacts(
      listContactInput: {
        filter: $filter
        order: $order
        skip: $skip
        take: $take
      }
    ) {
      items {
        ...ContactSelect
      }
      total
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;
