import classnames from 'classnames';
import React, {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  useFloating,
  autoUpdate,
  flip,
  offset,
  shift,
  useRole,
  useDismiss,
  useInteractions,
  useListNavigation,
  useTypeahead,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
} from '@floating-ui/react';

import styles from './ContextMenu.module.scss';

type ContextMenuPropsType = {
  container?: HTMLElement | null;
  onClose?: () => void;
  className?: string;
  children: React.ReactNode;
};

export type ContextMenuRefType = {
  isOpen: boolean;
  handleOpen: (e: MouseEvent) => void;
  handleClose: () => void;
};

export const ContextMenu = forwardRef<ContextMenuRefType, ContextMenuPropsType>(
  ({ container, className, onClose, children }, ref) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const listItemsRef = useRef<Array<HTMLButtonElement | null>>([]);
    const listContentRef = useRef(
      Children.map(children, (child) =>
        isValidElement(child) ? child.props.label : null
      ) as Array<string | null>
    );

    const { refs, floatingStyles, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      middleware: [
        offset({ mainAxis: 5, alignmentAxis: 4 }),
        flip({
          fallbackPlacements: ['left-start'],
        }),
        shift({ padding: 10 }),
      ],
      placement: 'right-start',
      strategy: 'fixed',
      whileElementsMounted: autoUpdate,
    });

    const role = useRole(context, { role: 'menu' });
    const dismiss = useDismiss(context);
    const listNavigation = useListNavigation(context, {
      listRef: listItemsRef,
      onNavigate: setActiveIndex,
      activeIndex,
    });
    const typeahead = useTypeahead(context, {
      enabled: isOpen,
      listRef: listContentRef,
      onMatch: setActiveIndex,
      activeIndex,
    });

    const { getFloatingProps, getItemProps } = useInteractions([
      role,
      dismiss,
      listNavigation,
      typeahead,
    ]);

    const onContextMenu = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();

        refs.setPositionReference({
          getBoundingClientRect() {
            return {
              width: 0,
              height: 0,
              x: e.clientX,
              y: e.clientY,
              top: e.clientY,
              right: e.clientX,
              bottom: e.clientY,
              left: e.clientX,
            };
          },
        });

        setIsOpen(true);
      },
      [refs]
    );

    const handleClose = useCallback(() => {
      setIsOpen(false);
      onClose?.();
    }, [onClose]);

    useImperativeHandle(ref, () => ({
      isOpen,
      handleOpen: onContextMenu,
      handleClose,
    }));

    useEffect(() => {
      container?.addEventListener('contextmenu', onContextMenu);

      return () => {
        container?.removeEventListener('contextmenu', onContextMenu);
      };
    }, [refs, onContextMenu, container]);

    return (
      <FloatingPortal>
        {isOpen && (
          <FloatingOverlay
            lockScroll
            className={styles.overlay}
            onClick={handleClose}
          >
            <FloatingFocusManager
              context={context}
              initialFocus={refs.floating}
            >
              <div
                className={classnames(styles.contextMenu, className)}
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
              >
                {Children.map(
                  children,
                  (child, index) =>
                    isValidElement(child) &&
                    cloneElement(
                      child,
                      getItemProps({
                        tabIndex: activeIndex === index ? 0 : -1,
                        ref(node: HTMLButtonElement) {
                          listItemsRef.current[index] = node;
                        },
                        onClick(e: React.MouseEvent) {
                          child.props.onClick?.(e);

                          if (!child.props['data-prevented']) {
                            handleClose();
                          }
                        },
                        onMouseUp(e) {
                          child.props.onClick?.(e);

                          if (!child.props['data-prevented']) {
                            handleClose();
                          }
                        },
                      })
                    )
                )}
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    );
  }
);
