import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Text4 } from '@appclose/ui';

import Block from 'components/common/Block';

import {
  BankAccountNotLinkedPropsType,
  BankAccountNotLinkedTypes,
} from './BankAccountNotLinked.types';
import style from './BankAccountNotLinked.module.scss';

import { useConfig } from './hooks/useTypesConfig';

export default function BankAccountNotLinked({
  type,
}: BankAccountNotLinkedPropsType) {
  const dispatch = useDispatch();
  const config = useConfig();
  const { message: Message, button } = config[type];
  const {
    message: ButtonMessage,
    onClick: onClickCreator,
    ...buttonProps
  } = button || {
    message: () => null,
  };
  const onClick = onClickCreator?.(dispatch);

  return (
    <Block className={style.block}>
      <Text4>
        <Message />
      </Text4>
      {button && type !== BankAccountNotLinkedTypes.LIMITED_ACCESS_TEAM_MEMBER && (
        <Button onClick={onClick} {...buttonProps}>
          {<ButtonMessage />}
        </Button>
      )}
    </Block>
  );
}
