import React, { useState } from 'react';
import classnames from 'classnames';
import { sortByDateField } from '@appclose/lib';
import { Flex } from '@appclose/ui';
import { Amount } from '@appclose/core';

import Date from 'components/common/Date';
import { sanitizeAmount } from 'controllers/amount';
import { I18n } from 'i18n';

import ScheduledPaymentStatus from './components/ScheduledPaymentStatus';
import { SchedulePreviewPropsType } from './SchedulePreview.types';
import styles from './SchedulePreview.module.scss';

export default function SchedulePreview({
  plan,
  showTitle = true,
}: SchedulePreviewPropsType) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  if (!Array.isArray(plan) || !plan.length) {
    return null;
  }

  const itemsToShowCount =
    plan.length > 2 ? (isExpanded ? plan.length : 2) : plan.length;

  return (
    <div>
      {showTitle && (
        <p className={styles.title}>Payments schedule ({plan.length})</p>
      )}
      {sortByDateField(plan, { fieldName: 'paymentDate' })
        .slice(0, itemsToShowCount)
        .map(({ paymentDate, amount, status }, i) => (
          <Flex
            alignItems="center"
            justify="space-between"
            className={styles.row}
            key={i}
          >
            <Date
              className={classnames(styles.cell, styles.paymentDate)}
              value={paymentDate}
              withWeekday
            />
            <Amount
              className={classnames(styles.cell, styles.amount)}
              value={sanitizeAmount(amount)}
            />
            <ScheduledPaymentStatus
              className={classnames(styles.cell, styles.status)}
              status={status}
            />
          </Flex>
        ))}
      {plan.length > 2 && (
        <p
          className={classnames(styles.expand, {
            [styles.expanded]: isExpanded,
          })}
          onClick={toggleExpand}
        >
          <span className={styles.expandIcon} />
          {isExpanded ? (
            <I18n id="scheduledPreview.hide" />
          ) : (
            <I18n
              id="scheduledPreview.showMore"
              values={{
                count: plan.length - itemsToShowCount,
              }}
            />
          )}
        </p>
      )}
    </div>
  );
}
