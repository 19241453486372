import * as Yup from 'yup';
import { EMAIL_VALIDATION_SCHEMA } from '@appclose/core';

import {
  CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
  CONTACT_LAST_NAME_VALIDATION_SCHEMA,
} from 'schemas/validations/contact';

import { ContactPopupFieldsetValuesType } from '../../ContactPopupForm.types';

export const ContactFieldsetSchema = Yup.object().shape<ContactPopupFieldsetValuesType>(
  {
    firstName: CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
    lastName: CONTACT_LAST_NAME_VALIDATION_SCHEMA,
    email: EMAIL_VALIDATION_SCHEMA,
  }
);
