import React, { ReactElement } from 'react';

import { SignatureStatuses } from '__generated__/globalTypes';
import Label, { LabelPropsType } from 'components/common/Label';
import { I18n } from 'i18n';

import { SignatureDocumentStatusPropsType } from './SignatureDocumentStatus.types';

const SignatureDocumentStatusLabels: Record<
  SignatureStatuses,
  ReactElement<LabelPropsType>
> = {
  [SignatureStatuses.DRAFT]: (
    <Label theme="info">
      <I18n id="enum.document.signature.status.draft" />
    </Label>
  ),
  [SignatureStatuses.NOT_PREPARED]: (
    <Label theme="info">
      <I18n id="enum.document.signature.status.notPrepared" />
    </Label>
  ),
  [SignatureStatuses.PARTIALLY_SIGNED]: (
    <Label theme="info">
      <I18n id="enum.document.signature.status.partiallySigned" />
    </Label>
  ),
  [SignatureStatuses.SENT]: (
    <Label theme="info">
      <I18n id="enum.document.signature.status.sent" />
    </Label>
  ),
  [SignatureStatuses.SIGNED]: (
    <Label theme="success">
      <I18n id="enum.document.signature.status.signed" />
    </Label>
  ),
  [SignatureStatuses.DECLINED]: (
    <Label theme="warning">
      <I18n id="enum.document.signature.status.declined" />
    </Label>
  ),
  [SignatureStatuses.DECLINE_REQUEST_SENT]: (
    <Label theme="warning">
      <I18n id="enum.document.signature.status.declineRequestSent" />
    </Label>
  ),
  [SignatureStatuses.ERROR]: (
    <Label theme="warning">
      <I18n id="enum.document.signature.status.error" />
    </Label>
  ),
};

export default function SignatureDocumentStatus({
  status,
}: SignatureDocumentStatusPropsType) {
  return SignatureDocumentStatusLabels[status] || null;
}
