import USA_STATES from '../../../../../constants/usaStates.json';

export const USA_STATES_OPTIONS = Object.entries(USA_STATES).map(
  ([label, value]) => ({
    label,
    value,
  })
);

export const getStateLabelByCode = (code: string) => {
  const state = Object.entries(USA_STATES).find(([, value]) => value === code);

  return state?.[0] || '';
};
