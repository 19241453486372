import { gql } from '@apollo/client';

export const FETCH_PRIVACY = gql`
  query FetchPrivacy {
    profile: getProfile {
      id
      privacy @client
    }
  }
`;
