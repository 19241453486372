import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';

import styles from './ModalOverlay.module.scss';
import { ModalOverlayPropsType } from './ModalOverlay.types';

export default function ModalOverlay({
  isOpen = true,
  onOverlayClick,
  overlayClassName,
  children,
  className,
  data,
  onAfterOpen,
}: ModalOverlayPropsType): React.ReactElement {
  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={!!onOverlayClick}
      onRequestClose={onOverlayClick}
      overlayClassName={classnames(styles.modalOverlay, overlayClassName)}
      className={classnames(styles.modal, className)}
      bodyOpenClassName={styles.bodyModalOpen}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      data={data}
      onAfterOpen={onAfterOpen}
    >
      {children}
    </ReactModal>
  );
}
