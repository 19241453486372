import React, { useCallback } from 'react';
import { useMutation, useQuery, ApolloError } from '@apollo/client';
import { Loader, traceError } from '@appclose/core';

import { CreateRefundPaymentInput } from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { PAYMENT_REFUND_DETAILS_MODAL } from 'constants/modals';
import { sanitizeAmount } from 'controllers/amount';
import { hasRefundUnsettledTransactionError } from 'controllers/error';
import { openModal } from 'controllers/modal';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';

import {
  FetchPaymentQuery,
  FetchPaymentQueryVariables,
  RefundPaymentMutation,
  RefundPaymentMutationVariables,
} from './__generated__/PaymentRefundModal.gql';
import PaymentRefundForm from './components/PaymentRefundForm';
import { PaymentRefundFormValuesType } from './components/PaymentRefundForm/PaymentRefundForm.types';
import { FETCH_PAYMENT, REFUND_PAYMENT } from './PaymentRefundModal.gql';
import { PaymentRefundModalPropsType } from './PaymentRefundModal.types';

export default function PaymentRefundModal({
  id,
  onClose,
}: PaymentRefundModalPropsType) {
  const { t } = useIntl();
  const { onUpdateFiles } = useUpdateFiles(Entities.PAYMENT);
  const { loading, data } = useQuery<
    FetchPaymentQuery,
    FetchPaymentQueryVariables
  >(FETCH_PAYMENT, {
    variables: { id },
  });
  const [refundPayment] = useMutation<
    RefundPaymentMutation,
    RefundPaymentMutationVariables
  >(REFUND_PAYMENT);

  const payment = data?.payment;

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnRefund = useCallback(
    async ({ amount, internalMemo, files }: PaymentRefundFormValuesType) => {
      try {
        const createRefundPaymentInput: CreateRefundPaymentInput = {
          id,
          amount,
          internalMemo,
        };

        const { data } = await refundPayment({
          variables: { createRefundPaymentInput },
        });
        const refundId = data?.createRefundPayment.id;

        notification().entityCreated(
          t('modal.paymentRefund.notification.created')
        );

        if (refundId && files) {
          await onUpdateFiles({ entityId: refundId, ...files });
        }

        onClose();

        openModal(PAYMENT_REFUND_DETAILS_MODAL, {
          id: refundId,
        });
      } catch (e) {
        traceError(e as ApolloError);

        if (hasRefundUnsettledTransactionError(e as ApolloError)) {
          throw e;
        }
      }
    },
    [id, onClose, refundPayment, t, onUpdateFiles]
  );

  let initialValues = {} as PaymentRefundFormValuesType;

  if (payment) {
    const { amount, refundAmount } = payment;
    const maxAmount = sanitizeAmount(amount - refundAmount);

    initialValues = {
      payment,
      maxAmount,
      amount: maxAmount,
      internalMemo: '',
    };
  }

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.paymentRefund.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <PaymentRefundForm
            initialValues={initialValues}
            onSubmit={handleOnRefund}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
