import React from 'react';
import { createRoot } from 'react-dom/client';
import { APP_ROOT_ELEMENT_ID } from '@appclose/core';
import { setAppElement } from 'react-modal';

import App from 'components/common/App';

setAppElement(`#${APP_ROOT_ELEMENT_ID}`);

const root = createRoot(document.getElementById(APP_ROOT_ELEMENT_ID)!);

root.render(<App />);
