import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Modal } from '@appclose/core';

import EmbedFileExplorer, {
  NodesTableNodeClickHandlerType,
} from 'components/common/EmbedFileExplorer';
import { UseNodesItemsType } from 'hooks/useNodes';
import { I18n, useIntl } from 'i18n';
import Breadcrumbs, { BreadcrumbType } from 'components/common/Breadcrumbs';

import { SelectGoogleDriveSyncFolderPopupModalPropsType } from './SelectGoogleDriveSyncFolderPopupModal.types';
import { GET_GOOGLE_DRIVE_FOLDERS } from './SelectGoogleDriveSyncFolderPopupModal.gql';
import {
  GetGoogleDriveFoldersQuery,
  GetGoogleDriveFoldersQueryVariables,
  GoogleFolderFragment,
} from './__generated__/SelectGoogleDriveSyncFolderPopupModal.gql';
import styles from './SelectGoogleDriveSyncFolderPopupModal.module.scss';
import { Button, ButtonGroup, Offset } from '@appclose/ui';

export default function SelectGoogleDriveSyncFolderPopupModal({
  drive,
  folder,
  breadcrumbs: propsBreadcrumbs,
  onSelect,
  onComplete,
  onCancel,
}: SelectGoogleDriveSyncFolderPopupModalPropsType) {
  const { t } = useIntl();
  const [currentFolder, setCurrentFolder] = useState<
    GoogleFolderFragment | undefined
  >(folder);
  const { loading, data, fetchMore } = useQuery<
    GetGoogleDriveFoldersQuery,
    GetGoogleDriveFoldersQueryVariables
  >(GET_GOOGLE_DRIVE_FOLDERS, {
    fetchPolicy: 'network-only',
    variables: {
      driveId: drive?.id,
      parentId: currentFolder?.id,
    },
  });
  const { folders, nextPageToken } = data?.result || {};
  const nodes = useMemo<UseNodesItemsType>(
    () =>
      (folders || []).map(({ id, name }) => ({
        id,
        name,
        isFolder: true,
        updatedAt: '',
      })),
    [folders]
  );
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbType[]>(
    propsBreadcrumbs || []
  );

  const onOpenFolder = useMemo<NodesTableNodeClickHandlerType>(
    () => ({ id }) => () => {
      setCurrentFolder(folders?.find((folder) => folder.id === id));
    },
    [folders]
  );

  const onLoadMore = useCallback(async () => {
    await fetchMore({
      variables: { nextPageToken },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        result: {
          ...previousResult.result,
          drives: [
            ...previousResult.result.folders,
            ...(fetchMoreResult?.result.folders || []),
          ],
        },
      }),
    });
  }, [fetchMore, nextPageToken]);

  const onSelectFolder = useCallback(() => {
    onSelect(currentFolder, breadcrumbs);
    onComplete();
  }, [currentFolder, breadcrumbs, onSelect, onComplete]);

  const onOpenBreadcrumbFolder = useCallback(
    (nextFolderId: string) => {
      setCurrentFolder(
        breadcrumbs?.find((folder) => folder.id === nextFolderId)
      );
    },
    [breadcrumbs]
  );

  useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs) => {
      if (!currentFolder) {
        return [];
      } else {
        const breadcrumbItemIndex = prevBreadcrumbs.findIndex(
          (breadcrumb) => breadcrumb.id === currentFolder.id
        );

        if (breadcrumbItemIndex !== -1) {
          return prevBreadcrumbs.slice(0, breadcrumbItemIndex + 1);
        } else {
          return [...prevBreadcrumbs, currentFolder];
        }
      }
    });
  }, [currentFolder]);

  return (
    <Modal
      title={<I18n id="modal.popup.selectGoogleDriveSyncFolder.title" />}
      className={styles.modal}
      onClose={onCancel}
    >
      <EmbedFileExplorer
        loading={loading}
        breadcrumbs={
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            rootFolderName={
              drive?.name ||
              t('modal.popup.selectGoogleDriveSyncFolder.breadcrumbs.root')
            }
            onOpenFolder={onOpenBreadcrumbFolder}
          />
        }
        onLoadMore={onLoadMore}
        nodes={nodes}
        onNodeClick={onOpenFolder}
      />
      <Offset top="xxl">
        <ButtonGroup>
          <Button onClick={onCancel}>
            <I18n id="modal.popup.selectGoogleDriveSyncFolder.cancel" />
          </Button>
          <Button skin="brand" disabled={loading} onClick={onSelectFolder}>
            <I18n id="modal.popup.selectGoogleDriveSyncFolder.select" />
          </Button>
        </ButtonGroup>
      </Offset>
    </Modal>
  );
}
