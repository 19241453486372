import React, { useCallback } from 'react';
import { Button } from '@appclose/ui';
import { Fieldset } from '@appclose/core';
import { EditIcon } from '@appclose/icons';

import { INVOICE_REMINDERS_MODAL } from 'constants/modals';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { isScheduleRemindersAllowed } from 'controllers/invoice';
import { PermissionGuard } from 'components/common/PermissionGuard';
import { openModal } from 'controllers/modal';
import { I18n, useIntl } from 'i18n';

import { InvoiceType } from '../../InvoiceDetailsModal.types';
import RemindersSchedule from './components/RemindersSchedule';
import { InvoiceRemindersPropsType } from './InvoiceReminders.types';
import styles from './InvoiceReminders.module.scss';

export default function InvoiceReminders({
  invoice: { id, paymentReminder, status },
}: InvoiceRemindersPropsType) {
  const { t } = useIntl();
  const { overdueReminder, prepaymentReminder } = (paymentReminder ||
    {}) as NonNullable<InvoiceType['paymentReminder']>;

  const openInvoiceRemindersModal = useCallback(() => {
    openModal(INVOICE_REMINDERS_MODAL, {
      id,
      openInvoice: true,
    });
  }, [id]);

  const scheduleRemindersAllowed = isScheduleRemindersAllowed(status);

  return (
    <Fieldset
      title={t('modal.invoiceDetails.invoiceReminders.fieldset.reminders')}
    >
      {!overdueReminder?.reminderSchedule &&
      !prepaymentReminder?.reminderSchedule ? (
        <>
          <p className={styles.scheduleLabel}>
            <I18n id="modal.invoiceDetails.invoiceReminders.noReminders" />
          </p>
          {scheduleRemindersAllowed && (
            <PermissionGuard
              resource={PermissionResources.PAYMENT_REMINDER}
              action={PermissionActions.UPDATE}
            >
              <Button skin="brand" onClick={openInvoiceRemindersModal}>
                <I18n id="modal.invoiceDetails.invoiceReminders.reminders" />
              </Button>
            </PermissionGuard>
          )}
        </>
      ) : (
        <>
          <RemindersSchedule
            title={t('modal.invoiceDetails.invoiceReminders.beforeDueDate')}
            schedule={prepaymentReminder?.reminderSchedule}
          />
          <RemindersSchedule
            title={t('modal.invoiceDetails.invoiceReminders.overdue', {
              length: overdueReminder?.reminderSchedule?.length,
            })}
            schedule={overdueReminder?.reminderSchedule}
          />
          {scheduleRemindersAllowed && (
            <PermissionGuard
              resource={PermissionResources.PAYMENT_REMINDER}
              action={PermissionActions.UPDATE}
            >
              <Button
                skin="shell"
                onClick={openInvoiceRemindersModal}
                className={styles.editButton}
              >
                <EditIcon />
                <span>
                  <I18n id="modal.invoiceDetails.invoiceReminders.button.edit" />
                </span>
              </Button>
            </PermissionGuard>
          )}
        </>
      )}
    </Fieldset>
  );
}
