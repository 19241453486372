import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import InvoiceInfo from 'components/common/InvoiceInfo';
import { INVOICE_DETAILS_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import { I18n } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import {
  FetchPaymentQuery,
  FetchPaymentQueryVariables,
} from './__generated__/PaymentDetailsModal.gql';
import PaymentActions from './components/PaymentActions';
import PaymentDetailsFieldset from './components/PaymentDetailsFieldset';
import InvoiceWrapper from './components/InvoiceWrapper';
import AdditionalInfoDetailsFieldset from 'components/common/AdditionalInfoDetailsFieldset';
import { FETCH_PAYMENT } from './PaymentDetailsModal.gql';
import { PaymentDetailsModalPropsType } from './PaymentDetailsModal.types';
import styles from './PaymentDetailsModal.module.scss';

export default function PaymentDetailsModal({
  id,
  onClose,
}: PaymentDetailsModalPropsType) {
  const { loading, data: invoiceData } = useQuery<
    FetchPaymentQuery,
    FetchPaymentQueryVariables
  >(FETCH_PAYMENT, {
    variables: { id },
  });
  const payment = invoiceData?.getPayment;
  const invoices = payment?.invoices || [];

  const { filesLoading, files } = useListFiles({
    entityType: Entities.PAYMENT,
    input: { filter: { entityIds: [id] } },
  });

  const openInvoiceDetails = useCallback(
    (id: string) => () => openModal(INVOICE_DETAILS_MODAL, { id }),
    []
  );

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader className={styles.header}>
        <ModalPageTitle>
          <I18n id="modal.paymentDetails.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading || filesLoading ? (
          <Loader />
        ) : (
          payment && (
            <>
              <PaymentActions payment={payment} />
              <PaymentDetailsFieldset payment={payment} />
              <div className={styles.associatedInvoices}>
                <p className={styles.associatedInvoice}>
                  <I18n id="modal.paymentDetails.associatedInvoices" />
                </p>
                {invoices.map((invoice) => {
                  const transactions = invoice.transactions || [];
                  const invoicePayment = transactions.find(
                    ({ id, parent }) =>
                      parent?.id === payment.id || id === payment.id
                  ) || { amount: 0 };

                  return (
                    <InvoiceWrapper
                      key={invoice.id}
                      invoice={invoice}
                      payment={invoicePayment}
                    >
                      <InvoiceInfo
                        invoice={invoice}
                        className={styles.invoiceInfo}
                        onViewButtonClick={openInvoiceDetails(invoice.id)}
                      />
                    </InvoiceWrapper>
                  );
                })}
              </div>
              <AdditionalInfoDetailsFieldset
                notes={payment.internalMemo}
                files={files?.items}
              />
            </>
          )
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
