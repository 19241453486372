import { FetchDataQuery } from '../../__generated__/InvoiceRemindersModal.gql';
import { FormDataType } from '@appclose/core';

export enum InvoiceStopToRemind {
  DAYS_PASSED = 'DAYS_PASSED',
  REMINDERS_SENT = 'REMINDERS_SENT',
}

export type InvoiceRemindersFormValuesType = FetchDataQuery['invoice']['paymentReminder'] & {
  overdueReminderType?: InvoiceStopToRemind;
  overdueReminderActive: boolean;
  prepaymentReminderActive: boolean;
};

export type InvoiceRemindersFormPropsType = {
  initialValues: InvoiceRemindersFormValuesType;
  onSubmit(values: InvoiceRemindersFormValuesType): void;
  onCancel(): void;
  onChange(data: FormDataType<InvoiceRemindersFormValuesType>): void;
};
