import { gql } from '@apollo/client';

export const SIGNATURE_TEMPLATE_FRAGMENT = gql`
  fragment SignatureTemplate on SignatureTemplateType {
    id
    title
    createdAt
    signerRoles {
      id
      roleName
    }
    status
    completeInOrder
  }
`;

export const CREATE_SIGNATURE_TEMPLATE = gql`
  mutation CreateSignatureTemplate($input: CreateSignatureTemplateInput!) {
    template: createSignatureTemplate(createSignatureTemplateInput: $input) {
      template {
        ...SignatureTemplate
      }
      templateUrl {
        url
      }
    }
  }
  ${SIGNATURE_TEMPLATE_FRAGMENT}
`;
