import React from 'react';
import classnames from 'classnames';
import { Caption1 } from '@appclose/ui';
import { MediaQueryDesktop } from '@appclose/core';

import RoundButton from 'components/common/RoundButton';

import { ActionsPanelButtonPropsType } from './ActionsPanelButton.types';
import styles from './ActionsPanelButton.module.scss';

export default function ActionsPanelButton({
  icon,
  skin,
  title,
  loading,
  disabled,
  onClick,
}: ActionsPanelButtonPropsType) {
  return (
    <label
      className={classnames(styles.buttonLabel, {
        [styles.loading]: loading,
        [styles.disabled]: disabled,
      })}
    >
      <RoundButton
        disabled={disabled}
        loading={loading}
        skin={skin}
        onClick={onClick}
      >
        {icon}
      </RoundButton>
      <MediaQueryDesktop>
        <Caption1
          className={styles.caption}
          offset={{ top: 12 }}
          weight="bold"
          align="center"
        >
          {title}
        </Caption1>
      </MediaQueryDesktop>
    </label>
  );
}
