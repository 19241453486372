import { formatLabel } from 'controllers/utils';

export enum DUE_DAYS {
  '10_DAYS' = '10_DAYS',
  '15_DAYS' = '15_DAYS',
  '30_DAYS' = '30_DAYS',
  CUSTOM = 'CUSTOM',
}

export const DUE_DAYS_OPTIONS = Object.keys(DUE_DAYS).map((value) => ({
  value,
  label: formatLabel(value),
}));
