import {
  QboDefaultResourcesDetailsType,
  QboIntegrationMappingType,
} from 'components/modals/pages/QboIntegrationSetupModal';

import { QBO_MAPPINGS_KEY } from 'constants/localStorage';

export function storeQboMappings(mappings: Partial<QboIntegrationMappingType>) {
  localStorage.setItem(QBO_MAPPINGS_KEY, JSON.stringify(mappings));
}

export function clearQboMappings() {
  localStorage.removeItem(QBO_MAPPINGS_KEY);
}

export function restoreQboMappings(defaults: QboDefaultResourcesDetailsType) {
  const mappings: QboIntegrationMappingType = JSON.parse(
    localStorage.getItem(QBO_MAPPINGS_KEY) || '{}'
  );

  Object.entries(mappings).forEach(([key, value]) => {
    if (
      typeof value === 'string' &&
      !defaults.accounts.some(({ id }) => id === value) &&
      !defaults.serviceItems.some(({ id }) => id === value)
    ) {
      // @ts-ignore
      mappings[key] = undefined;
    }
  });

  return mappings;
}
