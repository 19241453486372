import { getOperationAST, DocumentNode } from 'graphql';

export function getQueryName(query: DocumentNode) {
  const queryName = getOperationAST(query, null)?.name?.value;

  if (!queryName) {
    throw new Error("Can't get query name");
  }

  return queryName;
}
