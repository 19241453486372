import React from 'react';
import { Amount, StaticField, Fieldset } from '@appclose/core';

import { TransactionTypes } from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import PaymentMethodInfo from 'components/common/PaymentMethodInfo';
import PaymentAmount from 'components/common/PaymentAmount';
import { useIntl } from 'i18n';

import { TransactionDetailsFieldsetPropsType } from './TransactionDetailsFieldset.types';
import PaymentStatus from 'components/common/PaymentStatus';

export default function TransactionDetailsFieldset({
  payment: {
    id,
    contact: { name },
    paymentMethod,
    truncatedPAN,
    amount,
    refundPayment,
    paymentDate,
    refundNumber,
    qboSyncState,
    status,
  },
}: TransactionDetailsFieldsetPropsType) {
  const { t } = useIntl();

  return (
    <Fieldset
      title={t('modal.paymentRefundDetails.form.fieldset.transactionDetails')}
    >
      <FormGrid>
        <StaticField title={t('modal.paymentRefundDetails.form.field.contact')}>
          {name}
        </StaticField>
        <StaticField
          title={t('modal.paymentRefundDetails.form.field.referenceId')}
        >
          {refundNumber}
        </StaticField>
        <StaticField
          title={t('modal.paymentRefundDetails.form.field.paymentMethod')}
        >
          <PaymentMethodInfo
            paymentMethod={paymentMethod}
            truncatedPAN={truncatedPAN}
          />
        </StaticField>
        <StaticField
          title={t('modal.paymentRefundDetails.form.field.refundAmount')}
        >
          <PaymentAmount value={amount} type={TransactionTypes.REFUND} />
        </StaticField>
        <StaticField
          title={t('modal.paymentRefundDetails.form.field.originalAmount')}
        >
          {refundPayment?.amount && <Amount value={refundPayment?.amount} />}
        </StaticField>
        <StaticField
          title={t('modal.paymentRefundDetails.form.field.transactionDate')}
        >
          <Date value={paymentDate} />
        </StaticField>
        {status && (
          <StaticField title={t('modal.paymentDetails.paymentStatus')}>
            <PaymentStatus status={status} />
          </StaticField>
        )}
        <StaticField title={t('modal.paymentDetails.qboSyncStatus')}>
          <EntityQboSyncStatus
            qboSyncState={qboSyncState || undefined}
            entityId={id}
            entityType="refund"
          />
        </StaticField>
      </FormGrid>
    </Fieldset>
  );
}
