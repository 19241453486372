import { BankAccountClasses } from '__generated__/globalTypes';

export enum BankAccountNotLinkedTypes {
  TRUST_ACCOUNT_NOT_COMPLETED = 'TRUST_ACCOUNT_NOT_COMPLETED',
  TRUST_ACCOUNTING = 'TRUST_ACCOUNTING',
  TRUST_ACCOUNT_NEED_RECONNECTION = 'TRUST_ACCOUNT_NEED_RECONNECT',
  TRUST_FEE_ACCOUNT_REQUIRED = 'TRUST_FEE_ACCOUNT_REQUIRED',
  TRUST_FEE_ACCOUNT_PROCESSING = 'TRUST_FEE_ACCOUNT_PROCESSING',
  LIMITED_ACCESS_TEAM_MEMBER = 'LIMITED_ACCESS_TEAM_MEMBER',
  LIMITED_ACCESS_OWNER = 'LIMITED_ACCESS_OWNER',
}

export type BankAccountNotLinkedType =
  | BankAccountClasses
  | BankAccountNotLinkedTypes;

export type BankAccountNotLinkedPropsType = {
  type: BankAccountNotLinkedType;
};
