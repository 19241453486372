import React from 'react';

import { EntityActionConfirmContentPropsType } from './EntityActionConfirmContent.types';
import styles from './EntityActionConfirmContent.module.scss';

export default function EntityActionConfirmContent({
  title,
  name,
  description,
}: EntityActionConfirmContentPropsType) {
  return (
    <>
      <p className={styles.title}>{title}</p>
      <p className={styles.name}>{name}</p>
      {description && <p className={styles.description}>{description}</p>}
    </>
  );
}
