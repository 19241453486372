import React, { memo } from 'react';
import {
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
  TextAreaFormField,
  ToggleFormField,
  Fieldset,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import PhoneFieldset from 'components/form/PhoneFieldset';
import ContactsFieldset from 'components/form/ContactsFieldset';
import AddressesFieldset from 'components/form/AddressesFieldset';
import TeamMemberSelectFormField from 'components/fields/TeamMemberSelectFormField';
import FilesFormField from 'components/fields/FilesFormField';
import { useIntl } from 'i18n';
import { Entities } from 'constants/entities';

import styles from './EntityFieldset.module.scss';
import { EntityFieldsetPropsType } from './EntityFieldset.types';

const EntityFieldset = memo(function EntityFieldset({
  id,
}: EntityFieldsetPropsType) {
  const { t } = useIntl();

  return (
    <>
      <Fieldset title={t('modal.contact.form.entity.fieldset.info.title')}>
        <FormGrid>
          <UpperFirstInputFormField
            name="entity.name"
            label={t('modal.contact.form.entity.fieldset.info.name')}
          />
          <InputFormField
            name="entity.website"
            label={t('modal.contact.form.entity.fieldset.info.website')}
          />
        </FormGrid>
      </Fieldset>
      <ContactsFieldset name="entity.billingContacts" />
      <PhoneFieldset name="entity.phoneNumbers" allowRemoveLast />
      <AddressesFieldset name="entity.addresses" allowRemoveLast />
      <Fieldset title={t('modal.contact.form.entity.fieldset.other.title')}>
        <FormGroup>
          <TextAreaFormField
            name="entity.notes"
            label={t('modal.contact.form.entity.fieldset.other.notes')}
          />
        </FormGroup>

        <FormGroup>
          <FormGrid>
            <TeamMemberSelectFormField
              name="entity.originatingAttorney"
              label={t(
                'modal.contact.form.entity.fieldset.other.originatingAttorney'
              )}
            />
          </FormGrid>
        </FormGroup>

        <FormGroup>
          <FilesFormField entityType={Entities.CONTACT} entityId={id} />
        </FormGroup>

        <FormGroup className={styles.conflictsCheck}>
          <ToggleFormField
            name="entity.conflictsCheckCompleted"
            label={t('modal.contact.form.entity.fieldset.other.conflicts')}
          />
        </FormGroup>
        <FormGroup>
          <ToggleFormField
            name="entity.engagementHasBeenSigned"
            label={t('modal.contact.form.entity.fieldset.other.engagement')}
          />
        </FormGroup>
        <FormGroup>
          <ToggleFormField
            name="entity.taxable"
            label={t('modal.contact.form.entity.fieldset.other.taxable')}
          />
        </FormGroup>
        <FormGroup>
          <ToggleFormField
            name="entity.w9FormSentToClient"
            label={t('modal.contact.form.entity.fieldset.other.w9FormSent')}
          />
        </FormGroup>
      </Fieldset>
    </>
  );
});

export default EntityFieldset;
