import { InvoiceStatusesTypes } from '__generated__/globalTypes';

export function isEditAllowed(status: InvoiceStatusesTypes) {
  return [
    InvoiceStatusesTypes.CREATED,
    InvoiceStatusesTypes.VIEWED,
    InvoiceStatusesTypes.SENT,
  ].includes(status);
}

export function isCreditMemoAllowed(status: InvoiceStatusesTypes) {
  return [
    InvoiceStatusesTypes.CREATED,
    InvoiceStatusesTypes.SENT,
    InvoiceStatusesTypes.VIEWED,
    InvoiceStatusesTypes.PARTIALLY_PAID,
  ].includes(status);
}

export function isVoidAllowed(status: InvoiceStatusesTypes) {
  return [
    InvoiceStatusesTypes.CREATED,
    InvoiceStatusesTypes.VIEWED,
    InvoiceStatusesTypes.SENT,
  ].includes(status);
}

export function isReceivePaymentAllowed(status: InvoiceStatusesTypes) {
  return [
    InvoiceStatusesTypes.CREATED,
    InvoiceStatusesTypes.VIEWED,
    InvoiceStatusesTypes.SENT,
    InvoiceStatusesTypes.PARTIALLY_PAID,
  ].includes(status);
}

export function isScheduleRemindersAllowed(status: InvoiceStatusesTypes) {
  return [
    InvoiceStatusesTypes.CREATED,
    InvoiceStatusesTypes.VIEWED,
    InvoiceStatusesTypes.SENT,
    InvoiceStatusesTypes.PARTIALLY_PAID,
  ].includes(status);
}
