export enum TimeZoneAbbreviation {
  AST = 'AST',
  EST = 'EST',
  EDT = 'EDT',
  CST = 'CST',
  CDT = 'CDT',
  MST = 'MST',
  MDT = 'MDT',
  PST = 'PST',
  PDT = 'PDT',
  AKST = 'AKST',
  AKDT = 'AKDT',
  HST = 'HST',
  HAST = 'HAST',
  HADT = 'HADT',
  SST = 'SST',
  SDT = 'SDT',
  CHST = 'CHST',
}

export const TIME_ZONE_NAMES = {
  [TimeZoneAbbreviation.AST]: 'Atlantic Standard Time',
  [TimeZoneAbbreviation.EST]: 'Eastern Standard Time',
  [TimeZoneAbbreviation.EDT]: 'Eastern Daylight Time',
  [TimeZoneAbbreviation.CST]: 'Central Standard Time',
  [TimeZoneAbbreviation.CDT]: 'Central Daylight Time',
  [TimeZoneAbbreviation.MST]: 'Mountain Standard Time',
  [TimeZoneAbbreviation.MDT]: 'Mountain Daylight Time',
  [TimeZoneAbbreviation.PST]: 'Pacific Standard Time',
  [TimeZoneAbbreviation.PDT]: 'Pacific Daylight Time',
  [TimeZoneAbbreviation.AKST]: 'Alaska Time',
  [TimeZoneAbbreviation.AKDT]: 'Alaska Daylight Time',
  [TimeZoneAbbreviation.HST]: 'Hawaii Standard Time',
  [TimeZoneAbbreviation.HAST]: 'Hawaii-Aleutian Standard Time',
  [TimeZoneAbbreviation.HADT]: 'Hawaii-Aleutian Daylight Time',
  [TimeZoneAbbreviation.SST]: 'Samoa Standard Time',
  [TimeZoneAbbreviation.SDT]: 'Samoa Daylight Time',
  [TimeZoneAbbreviation.CHST]: 'Chamorro Standard Time',
};
