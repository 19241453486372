import { useCallback, useEffect, useState } from 'react';
import { Avatar as UiAvatar } from '@appclose/ui';

import { AvatarPropsType } from './Avatar.types';

export default function Avatar({ icon, ...rest }: AvatarPropsType) {
  const [url, setUrl] = useState<string | null | undefined>(
    typeof icon === 'string' ? icon : icon?.url
  );

  useEffect(() => {
    setUrl(typeof icon === 'string' ? icon : icon?.url);
  }, [icon]);

  const onShowAvatarError = useCallback(() => {
    setUrl(null);
  }, []);

  return <UiAvatar {...rest} icon={url} onShowIconError={onShowAvatarError} />;
}
