import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Caption3, Flex, Number1, Number3, Title6 } from '@appclose/ui';
import { ArrowMediumIcon } from '@appclose/icons';
import { Hint, history, Loader } from '@appclose/core';

import Block from 'components/common/Block';

import { FinancialBlockPropsType } from './FinancialBlock.types';
import styles from './FinancialBlock.module.scss';

export default function FinancialBlock({
  icon,
  title,
  hint,
  value,
  loading = false,
  statistic,
  minimize = false,
  to,
  className,
  theme,
  children,
}: FinancialBlockPropsType) {
  const Number = minimize ? Number3 : Number1;

  const goTo = useCallback(() => !!to && history.push(to), [to]);

  return (
    <Block
      as="article"
      className={classnames(
        styles.financialBlock,
        {
          [styles.minimize]: minimize,
        },
        className
      )}
      onClick={to ? goTo : undefined}
    >
      <Flex
        alignItems="flex-end"
        justify="space-between"
        className={styles.header}
      >
        <Flex alignItems="center" fluid>
          <div
            className={styles.icon}
            style={{ backgroundColor: loading ? '#fff' : icon.bgColor }}
          >
            {loading ? <Loader /> : icon.content}
          </div>
          <div className={styles.content}>
            {(title || hint) && (
              <Flex alignItems="center" className={styles.titleContainer}>
                <Caption3 color="secondary">{title}</Caption3>
                {hint && (
                  <Hint
                    className={styles.hint}
                    iconClassName={classnames({
                      [styles.hintWithTitle]: title,
                    })}
                    content={hint}
                  />
                )}
              </Flex>
            )}
            <Number
              as="span"
              className={classnames(styles.value, theme?.value)}
            >
              {value}
            </Number>
          </div>
          {to && <ArrowMediumIcon className={styles.arrowIcon} />}
        </Flex>
        {statistic && (
          <Flex alignItems="baseline" className={styles.statistics}>
            <Caption3 color="secondary">{statistic.label}</Caption3>
            <Title6 offset={{ left: 8 }}>{statistic.value}</Title6>
          </Flex>
        )}
      </Flex>
      {children}
    </Block>
  );
}
