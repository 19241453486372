import React, { useEffect } from 'react';
import set from 'lodash/set';
import { Grid } from '@appclose/ui';
import {
  AmountFormField,
  CheckboxFormField,
  FieldError,
  FormGroup,
  PercentageFormField,
  useFormContext,
  Fieldset,
} from '@appclose/core';

import { BankAccountClasses } from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import { useIntl } from 'i18n';

import { InvoiceFormValuesType } from '../../../../InvoiceForm.types';
import { sanitizeAmount } from 'controllers/amount';

export default function InvoiceAmountFieldset() {
  const { t } = useIntl();
  const {
    setFieldValue,
    values: {
      destinationAccount,
      percentageDiscount,
      discount: rawDiscount,
      total,
    },
    setValues,
  } = useFormContext<InvoiceFormValuesType>();

  const isToOperating = destinationAccount === BankAccountClasses.OPERATING;
  const withDiscount = isToOperating;
  const discount = sanitizeAmount(rawDiscount);

  useEffect(() => {
    if (withDiscount) {
      const total = 0;
      const amount = sanitizeAmount(
        total -
          (percentageDiscount
            ? sanitizeAmount((total * discount) / 100)
            : discount)
      );

      setValues(
        (values) => set(set(values, 'total', total), 'amount', amount),
        true
      );
    }
  }, [withDiscount, percentageDiscount, discount, setFieldValue, setValues]);

  return (
    <Fieldset title={t('modal.invoice.form.fieldset.amount')}>
      {withDiscount && (
        <FormGroup>
          <FormGrid>
            <AmountFormField
              name="total"
              label={t('modal.invoice.form.field.total')}
              disabled
            />
            <div>
              <Grid align="baseline" gap={[40]} columns="130px auto">
                {percentageDiscount ? (
                  <PercentageFormField
                    disabled={!total}
                    name="discount"
                    label={t('modal.invoice.form.field.discount')}
                    hideErrorMsg={true}
                  />
                ) : (
                  <AmountFormField
                    disabled={!total}
                    name="discount"
                    label={t('modal.invoice.form.field.discount')}
                    hideErrorMsg={true}
                  />
                )}
                <CheckboxFormField
                  name="percentageDiscount"
                  label={t('modal.invoice.form.field.percentageDiscount')}
                  theme="light"
                />
              </Grid>
              <FieldError name="discount" />
            </div>
          </FormGrid>
        </FormGroup>
      )}
      <FormGroup>
        <FormGrid>
          <AmountFormField
            name="amount"
            label={t('modal.invoice.form.field.amount.paymentRequest')}
            disabled={withDiscount}
          />
        </FormGrid>
      </FormGroup>
    </Fieldset>
  );
}
