import React, { memo } from 'react';
import { FormGroup, TextAreaFormField, Fieldset } from '@appclose/core';

import { useIntl } from 'i18n';
import FilesFormField from 'components/fields/FilesFormField';

import { AdditionalInfoFieldsetPropsType } from './AddressesFieldset.types';

const AdditionalInfoFieldset = memo(function AdditionalInfoFieldset({
  entityType,
  entityId,
  notesFieldName = 'notes',
  className,
}: AdditionalInfoFieldsetPropsType) {
  const { t } = useIntl();

  return (
    <Fieldset
      title={t('form.additionalInfoFieldset.title')}
      className={className}
    >
      <FormGroup>
        <TextAreaFormField
          name={notesFieldName}
          label={t('form.additionalInfoFieldset.internalNotes')}
        />
      </FormGroup>
      <FormGroup>
        <FilesFormField entityType={entityType} entityId={entityId} />
      </FormGroup>
    </Fieldset>
  );
});

export default AdditionalInfoFieldset;
