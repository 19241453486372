import { useIsTabletDevice } from '../../../hooks/device';

import { MediaQueryPropsType } from './MediaQuery.types';

export default function MediaQueryMobile({
  breakpoint,
  children = null,
}: MediaQueryPropsType): any {
  const isMobile = useIsTabletDevice(breakpoint);

  return isMobile ? children : null;
}
