import { ReactNode } from 'react';
import { isApolloError } from '@apollo/client';
import { Grid } from '@appclose/ui';
import {
  ReceiveIcon,
  EditIcon,
  EmailIcon,
  TrashIcon,
  LinkIcon,
  TickIcon,
} from '@appclose/icons';
import { dateManager, FormGqlErrorList, Notification } from '@appclose/core';

import {
  NotificationMessageType,
  NotificationType,
} from './notification.types';
import { I18n } from 'i18n';
import styles from './notification.module.scss';

const successAndInfoNotifications = new Notification({
  dismiss: {
    duration: dateManager().getMilliseconds('second', 6),
    showIcon: true,
  },
});

const errorNotifications = new Notification({
  dismiss: {
    duration: dateManager().getMilliseconds('second', 20),
    showIcon: true,
  },
});

const NotificationMessage = ({
  icon,
  message,
}: {
  message: NotificationMessageType;
  icon?: ReactNode;
}) => (
  <Grid columns={icon ? 'auto 1fr' : '1fr'} gap={[10, 10]} align="center">
    {icon ? <div className={styles.icon}>{icon}</div> : null}
    <div>{message}</div>
  </Grid>
);

function successNotification(
  message: NotificationMessageType,
  icon?: ReactNode
) {
  successAndInfoNotifications.success({
    message: <NotificationMessage message={message} icon={icon} />,
  });
}

function infoNotification(message: NotificationMessageType, icon?: ReactNode) {
  successAndInfoNotifications.info({
    message: <NotificationMessage message={message} icon={icon} />,
  });
}

function emailSentNotification() {
  infoNotification('Email has been sent', <EmailIcon fill="#fff" />);
}

function errorNotification(error: Error) {
  if (isApolloError(error)) {
    if (
      !navigator.onLine ||
      (error.networkError && error.graphQLErrors.length === 0)
    ) {
      errorNotifications.danger({
        message: (
          <span className={styles.error}>
            <I18n id="error.network" />
          </span>
        ),
      });

      return;
    }

    errorNotifications.danger({
      message: <FormGqlErrorList error={error} className={styles.error} />,
    });
  } else {
    errorNotifications.danger({
      message: error.message,
    });
  }
}

const notification: () => NotificationType = () => {
  return {
    successAction(message) {
      successNotification(message, <TickIcon fill="#fff" />);
    },
    entityCreated(message) {
      successNotification(message, <ReceiveIcon fill="#fff" />);
    },
    entityUpdated(message) {
      infoNotification(message, <EditIcon fill="#fff" />);
    },
    entityDeleted(message) {
      infoNotification(message, <TrashIcon fill="#fff" />);
    },
    linkCopied(message) {
      infoNotification(message, <LinkIcon fill="#fff" />);
    },
    emailSent: emailSentNotification,
    info(message, icon) {
      infoNotification(message, icon);
    },
    error: errorNotification,
  };
};

export default notification;
