import React from 'react';
import { Button, ButtonGroup, Offset } from '@appclose/ui';
import {
  Form,
  FormGroup,
  InputFormField,
  ToggleFormField,
  Fieldset,
  EnhancedFormArrayField,
  FormSubmissionError,
} from '@appclose/core';

import {
  SIGNATURE_ATTACH_FILES_MIME_TYPES,
  SIGNATURE_MAX_ATTACH_SIZE_MB,
} from 'constants/file';
import { I18n, useIntl } from 'i18n';
import { SIGNER_ROLE_NAME_FIELD_LENGTH_LIMIT } from 'constants/fieldsLimitations';
import FilesFormField from 'components/fields/FilesFormField';
import { Entities } from 'constants/entities';

import { SignatureTemplateFormPropsType } from './SignatureTemplateForm.types';
import { CreateSignatureTemplateFormSchema } from './SignatureTemplateForm.schema';

export default function SignatureTemplateForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: SignatureTemplateFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={CreateSignatureTemplateFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ isSubmitting, values: { completeInOrder } }) => (
        <>
          <Fieldset title={t('modal.signatureTemplate.form.document')}>
            <FormGroup>
              <FilesFormField
                entityType={Entities.SIGNATURE_TEMPLATE}
                label={t('modal.signatureTemplate.form.document.uploadFiles')}
                accept={SIGNATURE_ATTACH_FILES_MIME_TYPES}
                maxSizeMb={SIGNATURE_MAX_ATTACH_SIZE_MB}
              />
            </FormGroup>
            <InputFormField
              name="title"
              label={t('modal.signatureTemplate.form.document.title')}
            />
          </Fieldset>
          <Fieldset title={t('modal.signatureTemplate.form.roles')}>
            <EnhancedFormArrayField
              name="signerRoles"
              addButtonLabel={t('modal.signatureTemplate.form.roles.addButton')}
              allowRemoveLast={false}
              sortable={completeInOrder}
            >
              {({ resolveName }) => (
                <FormGroup>
                  <InputFormField
                    name={resolveName('roleName')}
                    label={t('modal.signatureTemplate.form.roles.roleName')}
                    maxLength={SIGNER_ROLE_NAME_FIELD_LENGTH_LIMIT}
                  />
                </FormGroup>
              )}
            </EnhancedFormArrayField>
            <ToggleFormField
              name="completeInOrder"
              label={t('modal.signatureTemplate.form.roles.completeInOrder')}
              tooltip={t(
                'modal.signatureTemplate.form.roles.completeInOrder.tooltip'
              )}
            />
          </Fieldset>
          <Offset bottom="m">
            <FormSubmissionError />
          </Offset>
          <ButtonGroup>
            <Button onClick={onCancel}>
              <I18n id="modal.signatureTemplate.form.cancel" />
            </Button>
            <Button skin="brand" type="submit" loading={isSubmitting}>
              <I18n id="modal.signatureTemplate.form.submit" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
