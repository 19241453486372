import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';
import { FILES_SCHEMA } from 'schemas/validations/files';

import { PaymentRefundFormValuesType } from './PaymentRefundForm.types';

export const PaymentRefundFormSchema = Yup.object().shape<PaymentRefundFormValuesType>(
  {
    payment: Yup.object<any>(),
    internalMemo: Yup.string(),
    amount: Yup.number()
      .required(
        getI18nErrorMessage('modal.paymentRefund.form.error.amount.required')
      )
      .when(['maxAmount'], (maxAmount: number) =>
        Yup.number()
          .max(
            maxAmount || 0,
            getI18nErrorMessage('modal.paymentRefund.form.error.amount.max')
          )
          .moreThan(0, ({ more }) =>
            getI18nErrorMessage(
              'modal.paymentRefund.form.error.amount.moreThan',
              { more }
            )
          )
      ),
    maxAmount: Yup.number().required(),
    files: FILES_SCHEMA,
  }
);
