import React from 'react';
import {
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
} from '@appclose/core';

import { useIntl } from 'i18n';

export default function EntityFieldset() {
  const { t } = useIntl();

  return (
    <>
      <FormGroup>
        <UpperFirstInputFormField
          name="entity.name"
          label={t('modal.popup.contact.form.entity.name')}
        />
      </FormGroup>
      <FormGroup>
        <UpperFirstInputFormField
          name="entity.billingContacts.0.firstName"
          label={t('modal.popup.contact.form.entity.billingContact.firstName')}
        />
      </FormGroup>
      <FormGroup>
        <UpperFirstInputFormField
          name="entity.billingContacts.0.lastName"
          label={t('modal.popup.contact.form.entity.billingContact.lastName')}
        />
      </FormGroup>
      <FormGroup>
        <InputFormField
          name="entity.billingContacts.0.email"
          label={t('modal.popup.contact.form.entity.billingContact.email')}
        />
      </FormGroup>
    </>
  );
}
