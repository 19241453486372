import { handleActions } from 'redux-actions';
import omit from 'lodash/omit';
import { ConfirmStateType } from '../../../types/confirm.types';

import {
  closeConfirmAction,
  confirmConfirmAction,
  errorConfirmAction,
  openConfirmAction,
} from './actions';

const INITIAL_STATE: ConfirmStateType = {
  isOpen: false,
  isProcessing: false,
  error: undefined,
};

export default handleActions<ConfirmStateType>(
  {
    [`${openConfirmAction}`]: (state, { payload }) => ({
      ...state,
      ...omit(payload, ['onConfirm', 'onCancel', 'onClose', 'onError']),
      isOpen: true,
    }),
    [`${confirmConfirmAction}`]: (state) => ({
      ...state,
      isProcessing: true,
      error: undefined,
    }),
    [`${errorConfirmAction}`]: (state, { payload }) => ({
      ...state,
      ...omit(payload, ['onConfirm', 'onCancel', 'onClose', 'onError']),
      isProcessing: false,
    }),
    [`${closeConfirmAction}`]: () => ({ ...INITIAL_STATE }),
  },
  INITIAL_STATE
);
