import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { useDebounce, useWindowSize } from 'react-use';

import { EllipsisPropsType } from './Ellipsis.types';
import styles from './Ellipsis.module.scss';
import { Tooltip } from '../Tooltip';

export default function Ellipsis({
  as: Component = 'p',
  lines = 1,
  tooltipEnabled = true,
  className,
  children,
}: EllipsisPropsType) {
  const ref = useRef<HTMLElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { width, height } = useWindowSize();

  useDebounce(
    () =>
      setShowTooltip(
        !!ref.current &&
          tooltipEnabled &&
          (ref.current.offsetHeight < ref.current.scrollHeight ||
            ref.current.offsetWidth < ref.current.scrollWidth)
      ),
    200,
    [width, height, tooltipEnabled, children]
  );

  if (!children) {
    return null;
  }

  return (
    <Tooltip
      position="auto"
      theme="dark"
      content={showTooltip ? children : undefined}
    >
      <Component
        ref={ref}
        className={classnames(
          styles.ellipsis,
          {
            [styles.noBox]: lines <= 1,
          },
          className
        )}
        style={{ WebkitLineClamp: lines > 1 ? lines : undefined }}
      >
        {children}
      </Component>
    </Tooltip>
  );
}
