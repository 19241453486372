import React from 'react';
import { useMutation } from '@apollo/client';
import { Modal, traceError } from '@appclose/core';

import notification from 'controllers/notification';
import { useIntl } from 'i18n';

import ChangePasswordForm from './components/ChangePasswordForm';
import {
  ChangePasswordFormActionsType,
  ChangePasswordFormValuesType,
} from './components/ChangePasswordForm/ChangePasswordForm.types';
import { CHANGE_PASSWORD } from './ChangePasswordModal.gql';
import { ChangePasswordModalPropsType } from './ChangePasswordModal.types';
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
} from './__generated__/ChangePasswordModal.gql';
import styles from './ChangePasswordModal.module.scss';

export default function ChangePasswordModal({
  onClose,
}: ChangePasswordModalPropsType) {
  const { t } = useIntl();
  const [changePassword] = useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(CHANGE_PASSWORD);

  const handleOnChange = async (
    { oldPassword, newPassword }: ChangePasswordFormValuesType,
    { setSubmitting }: ChangePasswordFormActionsType
  ) => {
    try {
      await changePassword({
        variables: { oldPassword, newPassword },
      });

      onClose();

      notification().info(t('modal.changePassword.notification.changed'));
    } catch (e) {
      setSubmitting(false);
      traceError(e as Error);
    }
  };

  const initialValues: ChangePasswordFormValuesType = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  return (
    <Modal className={styles.modal} onClose={onClose}>
      <ChangePasswordForm
        initialValues={initialValues}
        onSubmit={handleOnChange}
        onClose={onClose}
      />
    </Modal>
  );
}
