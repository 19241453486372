import React from 'react';
import { SelectFormField } from '@appclose/core';

import { useIntl } from 'i18n';

import { BusinessActivityTypeSelectFormFieldPropsType } from './BusinessActivityTypeSelectFormField.types';
import { useOptions } from './BusinessActivityTypeSelectFormField.utils';

export default function BusinessActivityTypeSelectFormField({
  name = 'businessInfo.businessActivity',
  label,
  clearable = false,
  ...rest
}: BusinessActivityTypeSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { options } = useOptions();

  return (
    <SelectFormField
      name={name}
      label={label || t('field.businessActivityTypeSelect.label')}
      options={options}
      clearable={clearable}
      {...rest}
    />
  );
}
