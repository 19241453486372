import React, { ReactElement } from 'react';

import { SignatureTemplateStatuses } from '__generated__/globalTypes';
import Label, { LabelPropsType } from 'components/common/Label';
import { I18n } from 'i18n';

import { SignatureTemplateStatusPropsType } from './SignatureTemplateStatus.types';

const SignatureTemplateStatusLabels: Record<
  SignatureTemplateStatuses,
  ReactElement<LabelPropsType>
> = {
  [SignatureTemplateStatuses.DRAFT]: (
    <Label theme="info">
      <I18n id="enum.document.signature.template.status.draft" />
    </Label>
  ),
  [SignatureTemplateStatuses.READY]: (
    <Label theme="success">
      <I18n id="enum.document.signature.template.status.ready" />
    </Label>
  ),
  [SignatureTemplateStatuses.ERROR]: (
    <Label theme="warning">
      <I18n id="enum.document.signature.template.status.error" />
    </Label>
  ),
};

export default function SignatureTemplateStatus({
  status,
}: SignatureTemplateStatusPropsType) {
  return SignatureTemplateStatusLabels[status] || null;
}
