import React from 'react';
import { EnhancedFormArrayField } from '@appclose/core';

import { useIntl } from 'i18n';

import NonSignatoryRecipient from './components/NonSignatoryRecipient';

export default function NonSignatoryRecipientsFormField() {
  const { t } = useIntl();

  const name = 'nonSignatoryRecipients';

  return (
    <EnhancedFormArrayField
      name={name}
      deletable
      addButtonLabel={t('modal.signatureDocument.form.nonSignatoryRecipients.addButton')}
      allowAddNew
      allowRemoveLast
      initialData={{ email: '' }}
    >
      {({ resolveName }) => (
        <NonSignatoryRecipient resolveName={resolveName} />
      )}
    </EnhancedFormArrayField>
  );
}
