import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { Loader, openConfirmAction, traceError } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { SEND_INVOICE_VOID_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';

import {
  FetchInvoiceQuery,
  FetchInvoiceQueryVariables,
  VoidInvoiceMutation,
  VoidInvoiceMutationVariables,
} from './__generated__/InvoiceVoidModal.gql';
import InvoiceVoidForm from './components/InvoiceVoidForm';
import {
  InvoiceVoidFormActionsType,
  InvoiceVoidFormValuesType,
} from './components/InvoiceVoidForm/InvoiceVoidForm.types';
import { FETCH_INVOICE, VOID_INVOICE } from './InvoiceVoidModal.gql';
import { InvoiceVoidModalPropsType } from './InvoiceVoidModal.types';

export default function InvoiceVoidModal({
  id,
  onClose,
}: InvoiceVoidModalPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { onUpdateFiles } = useUpdateFiles(Entities.VOID_INVOICE);
  const { loading, data } = useQuery<
    FetchInvoiceQuery,
    FetchInvoiceQueryVariables
  >(FETCH_INVOICE, {
    variables: { id },
  });
  const [createVoidInvoice] = useMutation<
    VoidInvoiceMutation,
    VoidInvoiceMutationVariables
  >(VOID_INVOICE);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    (
      {
        invoice,
        sendEmail,
        files,
        ...createVoidInvoiceInput
      }: InvoiceVoidFormValuesType,
      { setSubmitting }: InvoiceVoidFormActionsType
    ) => {
      dispatch(
        openConfirmAction({
          title: t('modal.invoiceVoid.confirm.title'),
          content: t('modal.invoiceVoid.confirm.content'),
          okButtonTitle: t('modal.invoiceVoid.confirm.okButtonTitle'),
          cancelButtonTitle: t('modal.invoiceVoid.confirm.cancelButtonTitle'),
          okButtonSkin: 'brand',
          onConfirm: async () => {
            try {
              const result = await createVoidInvoice({
                variables: {
                  createVoidInvoiceInput,
                },
              });

              const voidInvoiceId =
                result.data?.createVoidInvoice.voidInvoice?.id;

              if (files && voidInvoiceId) {
                await onUpdateFiles({ entityId: voidInvoiceId, ...files });
              }

              setSubmitting(false);
              onClose();
              notification().info(t('modal.invoiceVoid.notification.info'));

              if (sendEmail) {
                openModal(SEND_INVOICE_VOID_MODAL, { id });
              }
            } catch (e) {
              traceError(e as Error);
            }
          },
        })
      );
    },
    [createVoidInvoice, dispatch, id, onClose, t, onUpdateFiles]
  );

  const invoice = data?.invoice as FetchInvoiceQuery['invoice'];

  const initialValues: InvoiceVoidFormValuesType = {
    id,
    invoice,
    voidInvoiceText: '',
    sendEmail: true,
  };

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.invoiceVoid.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <InvoiceVoidForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
