export const BANK_ACCOUNT_MODAL = 'BANK_ACCOUNT_MODAL';
export const CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL';
export const CONTACT_MODAL = 'CONTACT_MODAL';
export const CREDIT_MEMO_DETAILS_MODAL = 'CREDIT_MEMO_DETAILS_MODAL';
export const FIRM_MODAL = 'FIRM_MODAL';
export const GOOGLE_DRIVE_SYNC_OPTIONS_MODAL =
  'GOOGLE_DRIVE_SYNC_OPTIONS_MODAL';
export const INVOICE_CREDIT_MEMO_MODAL = 'INVOICE_CREDIT_MEMO_MODAL';
export const INVOICE_DETAILS_MODAL = 'INVOICE_DETAILS_MODAL';
export const INVOICE_LINK_MODAL = 'INVOICE_LINK_MODAL';
export const INVOICE_MODAL = 'INVOICE_MODAL';
export const INVOICE_REMINDERS_MODAL = 'INVOICE_REMINDERS_MODAL';
export const INVOICE_VOID_MODAL = 'INVOICE_VOID_MODAL';
export const PAYMENT_DETAILS_MODAL = 'PAYMENT_DETAILS_MODAL';
export const PAYMENT_REFUND_DETAILS_MODAL = 'PAYMENT_REFUND_DETAILS_MODAL';
export const PAYMENT_REFUND_MODAL = 'PAYMENT_REFUND_MODAL';
export const QBO_INTEGRATION_SETUP_MODAL = 'QBO_INTEGRATION_SETUP_MODAL';
export const RECEIVE_PAYMENT_MODAL = 'RECEIVE_PAYMENT_MODAL';
export const SEND_CREDIT_MEMO_MODAL = 'SEND_CREDIT_MEMO_MODAL';
export const SEND_INVOICE_MODAL = 'SEND_INVOICE_MODAL';
export const SEND_INVOICE_VOID_MODAL = 'SEND_INVOICE_VOID_MODAL';
export const SEND_PAYMENT_RECEIPT_MODAL = 'SEND_PAYMENT_RECEIPT_MODAL';
export const SEND_PAYMENT_REFUND_MODAL = 'SEND_PAYMENT_REFUND_MODAL';
export const SIGNATURE_DOCUMENT_DETAILS_MODAL =
  'SIGNATURE_DOCUMENT_DETAILS_MODAL';
export const SIGNATURE_DOCUMENT_MODAL = 'SIGNATURE_DOCUMENT_MODAL';
export const SIGNATURE_TEMPLATE_DETAILS_MODAL =
  'SIGNATURE_TEMPLATE_DETAILS_MODAL';
export const SIGNATURE_TEMPLATE_MODAL = 'SIGNATURE_TEMPLATE_MODAL';
export const TEAM_MEMBER_MODAL = 'TEAM_MEMBER_MODAL';
export const MODES_COMPARE_MODAL = 'MODES_COMPARE_MODAL';
export const MODE_UPGRADE_MODAL = 'MODE_UPGRADE_MODAL';
export const KYB_MODAL = 'KYB_MODAL';
