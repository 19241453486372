import { CommonAppErrors } from '__generated__/globalTypes';
import { TFuncType } from 'i18n';

type GqlErrorCodeType =
  | CommonAppErrors.NOT_ALLOWED_EMAIL_DOMAIN
  | CommonAppErrors.AUTH_SIGNIN_INVALID_CREDENTIALS
  | CommonAppErrors.FIRM_PAYMENT_LINK_CONTACT_NOT_FOUND;

export const getGqlErrorResolvers = (t: TFuncType) => (
  errorCode: GqlErrorCodeType
) =>
  ({
    [CommonAppErrors.NOT_ALLOWED_EMAIL_DOMAIN]: t(
      'server.error.notAllowedEmailDomain'
    ),
    [CommonAppErrors.AUTH_SIGNIN_INVALID_CREDENTIALS]: t(
      'server.error.auth.signIn.invalidCredential'
    ),
    [CommonAppErrors.AUTH_SIGNUP_EMAIL_ALREADY_USED]: t(
      'server.error.auth.signIn.emailAlreadyUsed'
    ),
    [CommonAppErrors.AUTH_SIGNUP_NOT_ALLOWED_COUNTRY]: t(
      'server.error.auth.signUp.notAllowedCountry'
    ),
    [CommonAppErrors.AUTH_SIGNUP_NOT_ALLOWED_STATE]: t(
      'server.error.auth.signUp.notAllowedState'
    ),
    [CommonAppErrors.FIRM_PAYMENT_LINK_CONTACT_NOT_FOUND]: t(
      'server.error.paymentLink.contactNotFound'
    ),
    [CommonAppErrors.PAYMENTS_REFUND_UNSETTLED_TRANSACTION]: t(
      'modal.paymentRefund.form.error.unsettledTransaction'
    ),
    [CommonAppErrors.PROMO_CODE_NOT_EXISTS]: t(
      'server.error.promoCode.notExists'
    ),
    [CommonAppErrors.PROMO_CODE_EXPIRED]: t('server.error.promoCode.expired'),
    [CommonAppErrors.NOT_ACTIVE_ACCOUNT]: t(
      'server.error.auth.signIn.accountDeactivated'
    ),
    [CommonAppErrors.SUB_EXPENSE_CATEGORY_ALREADY_EXISTS]: t(
      'server.error.expenseCategory.alreadyExists'
    ),
    [CommonAppErrors.SUB_EXPENSE_CATEGORY_HAS_RELATIONS]: t(
      'server.error.expenseCategory.hasRelations'
    ),
    [CommonAppErrors.SUB_EXPENSE_CATEGORY_CANNOT_USE_NOT_ACTIVE]: t(
      'server.error.expenseCategory.useDeactivated'
    ),
    [CommonAppErrors.TIMER_ACTIVITY_ALREADY_EXISTS]: t(
      'server.error.timeEntryActivity.alreadyExists'
    ),
    [CommonAppErrors.TIMER_ACTIVITY_HAS_RELATIONS]: t(
      'server.error.timeEntryActivity.hasRelations'
    ),
    [CommonAppErrors.TIMER_ACTIVITY_CANNOT_USE_NOT_ACTIVE]: t(
      'server.error.timeEntryActivity.useDeactivated'
    ),
    [CommonAppErrors.THIRD_PARTY_PAYEE_ALREADY_EXISTS]: t(
      'server.error.thirdPartyPayee.alreadyExists'
    ),
    [CommonAppErrors.THIRD_PARTY_PAYEE_IN_USE]: t(
      'server.error.thirdPartyPayee.hasRelations'
    ),
    [CommonAppErrors.SITE_BUILDER_CUSTOM_DOMAIN_ALREADY_CREATED]: t(
      'server.error.externalDomain.alreadyCreated'
    ),
    [CommonAppErrors.SITE_BUILDER_CUSTOM_DOMAIN_CNAME_RECORD_NOT_FOUND]: t(
      'server.error.externalDomain.invalidCname'
    ),
    [CommonAppErrors.SITE_BUILDER_CUSTOM_DOMAIN_CONNECTION_FAILED]: t(
      'server.error.externalDomain.connectionFailed'
    ),
    [CommonAppErrors.SUB_EXPENSE_CATEGORY_NOT_FOUND]: t(
      'server.error.subExpenseCategory.notFound'
    ),
    [CommonAppErrors.TIMER_ACTIVITY_NOT_FOUND]: t(
      'server.error.timeEntryActivity.notFound'
    ),
    [CommonAppErrors.SIGNATURE_EMPTY_OR_ENCRYPTED_FILE]: t(
      'server.error.signDocument.signatureEmptyOrEncryptedFile'
    ),
  }[errorCode] || t('server.error.somethingWentWrong'));
