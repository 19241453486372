import { gql } from '@apollo/client';

export const UPDATE_FIRM = gql`
  mutation UpdateFirm($input: UpdateFirmInput!, $logo: Upload) {
    updateFirm(updateFirmInput: $input, logo: $logo) {
      id
      name
      legalName
      entityType
      logo {
        original
        thumbnail
      }
      phoneNumber {
        number
      }
      businessStartedSince
      size
      website
      description
      address {
        address1
        address2
        state
        zipCode
        city
      }
    }
  }
`;
