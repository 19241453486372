import { SelectFormField } from '@appclose/core';

import useAccountType from 'hooks/useAccountType';
import { useIntl } from 'i18n';

import { RelationshipTypeSelectFormFieldPropsType } from './RelationshipTypeSelectFormField.types';

export default function RelationshipTypeSelectFormField({
  name = 'relationship',
  excludedValues = [],
  label,
  ...props
}: RelationshipTypeSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { personRelationshipTypesOptions } = useAccountType();

  const options = personRelationshipTypesOptions.filter((option) => {
    return !excludedValues.includes(option.value)
  });

  return (
    <SelectFormField
      {...props}
      name={name}
      label={label || t('field.relationshipTypeSelectFormField.label')}
      options={options}
    />
  );
}
