import React from 'react';
import { DownloadIcon, EmailIcon } from '@appclose/icons';

import { useIntl } from 'i18n';
import { SEND_PAYMENT_REFUND_MODAL } from 'constants/modals';
import ActionsPanel, {
  ActionsPanelButton,
} from 'components/common/ActionsPanel';
import { PermissionsGuard } from 'components/common/PermissionsGuard';
import { PermissionGuard } from 'components/common/PermissionGuard';
import { openModal } from 'controllers/modal';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import usePaymentActions from 'hooks/usePaymentActions';
import { TransactionTypes } from '__generated__/globalTypes';

import { REFUND_ACTIONS_PERMISSIONS } from './RefundActions.constants';
import { RefundActionsPropsType } from './RefundActions.types';

export default function RefundActions({
  payment: { id },
}: RefundActionsPropsType) {
  const { t } = useIntl();
  const { downloadRefundPdf, downloadRefundPdfLoading } = usePaymentActions({
    id,
    type: TransactionTypes.REFUND,
  });
  const openSendEmail = () => {
    openModal(SEND_PAYMENT_REFUND_MODAL, { id });
  };

  return (
    <PermissionsGuard allow={REFUND_ACTIONS_PERMISSIONS}>
      <ActionsPanel>
        <PermissionGuard
          resource={PermissionResources.REFUND_PAYMENT}
          action={PermissionActions.SEND}
        >
          <ActionsPanelButton
            skin="yellow"
            icon={<EmailIcon />}
            title={t('modal.paymentRefundDetails.actions.sendReceipt')}
            onClick={openSendEmail}
          />
        </PermissionGuard>
        <PermissionGuard
          resource={PermissionResources.REFUND_PAYMENT}
          action={PermissionActions.READ}
        >
          <ActionsPanelButton
            skin="secondary"
            icon={<DownloadIcon />}
            title={t('modal.paymentRefundDetails.actions.downloadPdf')}
            onClick={downloadRefundPdf}
            loading={downloadRefundPdfLoading}
          />
        </PermissionGuard>
      </ActionsPanel>
    </PermissionsGuard>
  );
}
