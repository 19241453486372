import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import { MinusIcon, PlusMediumIcon } from '@appclose/icons';

import { FieldsetPropsType } from './Fieldset.types';
import styles from './Fieldset.module.scss';

const Fieldset = React.forwardRef<HTMLFieldSetElement, FieldsetPropsType>(
  (
    {
      title,
      foldable = false,
      initiallyFolded = false,
      children,
      className,
      titleClassName,
    },
    ref
  ) => {
    const [isFolded, setIsFolded] = useState(initiallyFolded);
    const toggleIsFolded = useCallback(() => setIsFolded(!isFolded), [
      isFolded,
      setIsFolded,
    ]);

    const FoldIcon = isFolded ? PlusMediumIcon : MinusIcon;

    return (
      <fieldset className={classnames(styles.fieldset, className)} ref={ref}>
        <legend className={styles.legend}>
          <div className={styles.legendContent}>
            <h4 className={classnames(styles.title, titleClassName)}>
              {title}
            </h4>
            {foldable && <FoldIcon onClick={toggleIsFolded} />}
          </div>
        </legend>
        {(!foldable || !isFolded) && (
          <div className={styles.content}>{children}</div>
        )}
      </fieldset>
    );
  }
);

export default Fieldset;
