import { useIntl } from 'i18n';
import { KybCompanyType } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useKybCompanyTypes() {
  const { t } = useIntl();
  const kybCompanyType: Record<KybCompanyType, string> = useMemo(
    () => ({
      [KybCompanyType.LLC]: t('enum.kybCompanyType.LLC'),
      [KybCompanyType.PRIVATE_PARTNERSHIP]: t('enum.kybCompanyType.partnership'),
      [KybCompanyType.PRIVATE_CORPORATION]: t('enum.kybCompanyType.corporation'),
      [KybCompanyType.SOLE_PROPRIETORSHIP]: t('enum.kybCompanyType.soleProp'),
      [KybCompanyType.SINGLE_MEMBER_LLC]: t('enum.kybCompanyType.singleMember'),
    }),
    [t]
  );

  const kybCompanyTypeOptions: {
    label: string;
    value: KybCompanyType;
  }[] = useMemo(
    () =>
      Object.entries(kybCompanyType).map(([value, label]) => ({
        value: value as KybCompanyType,
        label,
      })),
    [kybCompanyType]
  );

  const getKybCompanyTypeLabel = useCallback(
    (type?: KybCompanyType | null) => {
      if (!type) {
        return '';
      }

      return kybCompanyType[type];
    },
    [kybCompanyType]
  );

  return { kybCompanyTypeOptions, getKybCompanyTypeLabel };
}
