import { gql } from '@apollo/client';

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(
      changePasswordInput: {
        oldPassword: $oldPassword
        newPassword: $newPassword
      }
    ) {
      id
    }
  }
`;
