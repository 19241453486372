import React from 'react';
import { Grid } from '@appclose/ui';
import { useIsDesktopDevice } from '@appclose/core';

import { FormGridPropsType } from './FormGrid.types';

export default function FormGrid({
  gap = 60,
  align = 'start',
  adaptive = true,
  className,
  children,
}: FormGridPropsType) {
  const isDesktopDevice = useIsDesktopDevice() || !adaptive;

  return (
    <Grid
      gap={isDesktopDevice ? [gap, 20] : [0, 20]}
      columns={isDesktopDevice ? 'repeat(2, minmax(0, 1fr))' : '100%'}
      align={align}
      className={className}
    >
      {children}
    </Grid>
  );
}
