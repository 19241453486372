import React from 'react';
import classnames from 'classnames';
import { ChevronDownBoldIcon } from '@appclose/icons';

import { SortPropsType } from './Sort.types';
import styles from './Sort.module.scss';

export default function Sort({
  name,
  order,
  active = false,
  children,
  classNames,
  onClick,
}: SortPropsType) {
  const handleOnClick = () => onClick(name, order === 'DESC' ? 'ASC' : 'DESC');

  return (
    <div
      className={classnames(
        styles.sort,
        {
          [styles.active]: active,
        },
        classNames
      )}
      onClick={handleOnClick}
    >
      {children}
      <ChevronDownBoldIcon
        width={16}
        height={16}
        className={classnames(styles.icon, {
          [styles.iconAsc]: order === 'ASC',
        })}
      />
    </div>
  );
}
