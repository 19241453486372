import { gql } from '@apollo/client';

export const CONNECT_TRUST_ACCOUNT = gql`
  mutation ConnectTrustAccount($input: BankAccountInput!) {
    connectTrustAccount(connectTrustAccountInput: $input) {
      success
    }
  }
`

export const REPLACE_BANK_ACCOUNT = gql`
  mutation ReplaceBankAccount($input: ReplaceBankAccountInput!) {
    replaceBankAccount(replaceBankAccountInput: $input) {
      success
    }
  }
`;