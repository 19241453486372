import { useCallback } from 'react';
import { useLocalStorage } from '@appclose/core';

import {
  USER_ID_PARAM_KEY,
  PROMO_CODE_PARAM_KEY,
  UTM_SOURCE_PARAM_KEY,
  UTM_MEDIUM_PARAM_KEY,
  UTM_CAMPAIGN_PARAM_KEY,
  UTM_CONTENT_PARAM_KEY,
} from 'constants/queryParams';
import { PROMO_CODE_KEY } from 'constants/localStorage';
import useUrlSearchParams from 'hooks/useUrlSearchParams';

import { deserializer, serializer } from './useEnvForwardingParams.utils';
import { PROMO_CODE_TTL } from './useEnvForwardingParams.constants';

export default function useEnvForwardingParams() {
  const { searchParams } = useUrlSearchParams<{
    [USER_ID_PARAM_KEY]?: string;
    [PROMO_CODE_PARAM_KEY]?: string;
    [UTM_SOURCE_PARAM_KEY]?: string;
    [UTM_MEDIUM_PARAM_KEY]?: string;
    [UTM_CAMPAIGN_PARAM_KEY]?: string;
    [UTM_CONTENT_PARAM_KEY]?: string;
  }>({ parseNumbers: false });
  const searchPromoCodeParam = searchParams[PROMO_CODE_PARAM_KEY] || undefined;

  const [storedPromoCode, setStoredPromoCode] = useLocalStorage<
    string | undefined
  >(PROMO_CODE_KEY, undefined, {
    raw: false,
    serializer,
    deserializer: deserializer(PROMO_CODE_TTL),
  });

  const promoCode = searchPromoCodeParam || storedPromoCode;

  const savePrams = useCallback(() => {
    if (searchPromoCodeParam) {
      setStoredPromoCode(searchPromoCodeParam);
    }
  }, [searchPromoCodeParam, setStoredPromoCode]);

  return {
    params: {
      promoCode,
    },
    savePrams,
  };
}
