import { gql } from '@apollo/client';

export const TRACK_ANALYTICS_EVENT = gql`
  mutation TrackAnalyticsEvent($event: String!, $data: JSON!, $timestamp: Int) {
    trackAnalyticEvent(
      trackAnalyticEventInput: {
        event: $event
        data: $data
        timestamp: $timestamp
      }
    ) {
      success
    }
  }
`;
