import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { closeModal, getModalData } from 'controllers/modal';
import {
  CONTACT_MODAL,
  INVOICE_DETAILS_MODAL,
  INVOICE_MODAL,
  SEND_INVOICE_MODAL,
  INVOICE_VOID_MODAL,
  SEND_INVOICE_VOID_MODAL,
  RECEIVE_PAYMENT_MODAL,
  INVOICE_CREDIT_MEMO_MODAL,
  SEND_CREDIT_MEMO_MODAL,
  PAYMENT_REFUND_MODAL,
  PAYMENT_DETAILS_MODAL,
  SEND_PAYMENT_REFUND_MODAL,
  TEAM_MEMBER_MODAL,
  INVOICE_LINK_MODAL,
  CHANGE_PASSWORD_MODAL,
  PAYMENT_REFUND_DETAILS_MODAL,
  SEND_PAYMENT_RECEIPT_MODAL,
  INVOICE_REMINDERS_MODAL,
  FIRM_MODAL,
  BANK_ACCOUNT_MODAL,
  CREDIT_MEMO_DETAILS_MODAL,
  QBO_INTEGRATION_SETUP_MODAL,
  SIGNATURE_TEMPLATE_MODAL,
  SIGNATURE_DOCUMENT_MODAL,
  SIGNATURE_DOCUMENT_DETAILS_MODAL,
  SIGNATURE_TEMPLATE_DETAILS_MODAL,
  GOOGLE_DRIVE_SYNC_OPTIONS_MODAL,
  KYB_MODAL,
} from 'constants/modals';
import InvoiceDetailsModal from 'components/modals/pages/InvoiceDetailsModal';
import ContactModal from 'components/modals/pages/ContactModal';
import SendInvoiceModal from 'components/modals/pages/SendInvoiceModal';
import PaymentRefundModal from 'components/modals/pages/PaymentRefundModal';
import SendVoidInvoiceModal from 'components/modals/pages/SendVoidInvoiceModal';
import InvoiceVoidModal from 'components/modals/pages/InvoiceVoidModal';
import SendRefundModal from 'components/modals/pages/SendRefundModal';
import InvoiceCreditMemoModal from 'components/modals/pages/InvoiceCreditMemoModal';
import SendCreditMemoModal from 'components/modals/pages/SendCreditMemoModal';
import PaymentDetailsModal from 'components/modals/pages/PaymentDetailsModal';
import TeamMemberModal from 'components/modals/pages/TeamMemberModal';
import InvoiceLinkModal from 'components/modals/pages/InvoiceLinkModal';
import ChangePasswordModal from 'components/modals/pages/ChangePasswordModal';
import PaymentRefundDetailsModal from 'components/modals/pages/PaymentRefundDetailsModal';
import SendPaymentReceiptModal from 'components/modals/pages/SendPaymentReceiptModal';
import InvoiceRemindersModal from 'components/modals/pages/InvoiceRemindersModal';
import FirmModal from 'components/modals/pages/FirmModal';
import BankAccountModal from 'components/modals/pages/BankAccountModal';
import CreditMemoDetailsModal from 'components/modals/pages/CreditMemoDetailsModal';
import QboIntegrationSetupModal from 'components/modals/pages/QboIntegrationSetupModal';
import SignatureTemplateModal from 'components/modals/pages/SignatureTemplateModal';
import SignatureDocumentModal from 'components/modals/pages/SignatureDocumentModal';
import SignatureDocumentDetailsModal from 'components/modals/pages/SignatureDocumentDetailsModal';
import SignatureTemplateDetailsModal from 'components/modals/pages/SignatureTemplateDetailsModal';
import GoogleDriveSyncOptionsModal from 'components/modals/pages/GoogleDriveSyncOptionsModal';
import KybModal from 'components/modals/pages/KybModal';
import InvoiceModal from '../InvoiceModal';
import ReceivePaymentModal from '../ReceivePaymentModal';

export default function ModalProvider() {
  const { search } = useLocation();
  const [modalData, setModalData] = useState<{
    type: string;
    data?: any;
  } | null>(null);

  const handleOnClose = useCallback(() => {
    closeModal();
  }, []);

  const { type: currentModal, data = {} } = modalData || {};
  const modalProps = { ...data, onClose: handleOnClose };

  useEffect(() => {
    const modalData = getModalData();

    if (modalData?.type !== currentModal) {
      setModalData(modalData);
    }
  }, [currentModal, search]);

  switch (currentModal) {
    case CONTACT_MODAL:
      return <ContactModal {...modalProps} />;
    case INVOICE_MODAL:
      return <InvoiceModal {...modalProps} />;
    case INVOICE_DETAILS_MODAL:
      return <InvoiceDetailsModal {...modalProps} />;
    case INVOICE_VOID_MODAL:
      return <InvoiceVoidModal {...modalProps} />;
    case SEND_INVOICE_MODAL:
      return <SendInvoiceModal {...modalProps} />;
    case SEND_INVOICE_VOID_MODAL:
      return <SendVoidInvoiceModal {...modalProps} />;
    case RECEIVE_PAYMENT_MODAL:
      return <ReceivePaymentModal {...modalProps} />;
    case INVOICE_CREDIT_MEMO_MODAL:
      return <InvoiceCreditMemoModal {...modalProps} />;
    case SEND_CREDIT_MEMO_MODAL:
      return <SendCreditMemoModal {...modalProps} />;
    case PAYMENT_REFUND_MODAL:
      return <PaymentRefundModal {...modalProps} />;
    case PAYMENT_REFUND_DETAILS_MODAL:
      return <PaymentRefundDetailsModal {...modalProps} />;
    case PAYMENT_DETAILS_MODAL:
      return <PaymentDetailsModal {...modalProps} />;
    case SEND_PAYMENT_REFUND_MODAL:
      return <SendRefundModal {...modalProps} />;
    case TEAM_MEMBER_MODAL:
      return <TeamMemberModal {...modalProps} />;
    case INVOICE_LINK_MODAL:
      return <InvoiceLinkModal {...modalProps} />;
    case CHANGE_PASSWORD_MODAL:
      return <ChangePasswordModal {...modalProps} />;
    case SEND_PAYMENT_RECEIPT_MODAL:
      return <SendPaymentReceiptModal {...modalProps} />;
    case INVOICE_REMINDERS_MODAL:
      return <InvoiceRemindersModal {...modalProps} />;
    case FIRM_MODAL:
      return <FirmModal {...modalProps} />;
    case BANK_ACCOUNT_MODAL:
      return <BankAccountModal {...modalProps} />;
    case CREDIT_MEMO_DETAILS_MODAL:
      return <CreditMemoDetailsModal {...modalProps} />;
    case QBO_INTEGRATION_SETUP_MODAL:
      return <QboIntegrationSetupModal {...modalProps} />;
    case SIGNATURE_TEMPLATE_MODAL:
      return <SignatureTemplateModal {...modalProps} />;
    case SIGNATURE_DOCUMENT_MODAL:
      return <SignatureDocumentModal {...modalProps} />;
    case SIGNATURE_TEMPLATE_DETAILS_MODAL:
      return <SignatureTemplateDetailsModal {...modalProps} />;
    case SIGNATURE_DOCUMENT_DETAILS_MODAL:
      return <SignatureDocumentDetailsModal {...modalProps} />;
    case GOOGLE_DRIVE_SYNC_OPTIONS_MODAL:
      return <GoogleDriveSyncOptionsModal {...modalProps} />;
    case KYB_MODAL:
      return <KybModal {...modalProps} />;
    default:
      return null;
  }
}
