import { gql } from '@apollo/client';

export const ADDRESS_FRAGMENT = gql`
  fragment Address on AddressType {
    type
    city
    address1
    address2
    state
    zipCode
  }
`;
