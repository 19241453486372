import React, { useCallback } from 'react';
import { EmailIcon, EditIcon, DownloadIcon, CloseIcon } from '@appclose/icons';

import ActionsPanel, {
  ActionsPanelButton,
} from 'components/common/ActionsPanel';
import useSignatureDocumentActions from 'hooks/useSignatureDocumentActions';
import { useIntl } from 'i18n';

import { ActionsPropsType } from './Actions.types';

export default function Actions({
  id,
  status,
  hasWithoutServiceInfoVersion,
  onClose,
}: ActionsPropsType) {
  const { t } = useIntl();
  const onCancelSignatureDocumentSuccess = useCallback(() => {
    onClose();
  }, [onClose]);
  const {
    onSendSignatureDocumentReminder,
    onEditSignatureDocument,
    onDownloadSignatureDocumentWithAuditTrail,
    onDownloadSignatureDocumentWithoutAuditTrail,
    onDownloadSignatureDocumentAuditTrailOnly,
    onCancelSignatureDocument,
    signatureDocumentAllowedActions: {
      canBeDownloadedWithoutTrail,
      canBeDownloadedTrailOnly,
      canBeDownloaded,
      canBeCanceled,
      canReminderBeSent,
      canBeEdited,
    },
  } = useSignatureDocumentActions({
    id,
    status,
    hasWithoutServiceInfoVersion,
    onCancelSuccess: onCancelSignatureDocumentSuccess,
  });

  if (
    ![canBeDownloaded, canBeCanceled, canReminderBeSent, canBeEdited].includes(
      true
    )
  ) {
    return null;
  }

  return (
    <ActionsPanel>
      {canReminderBeSent && (
        <ActionsPanelButton
          skin="yellow"
          icon={<EmailIcon />}
          title={t('modal.signatureDocumentDetails.action.reminder')}
          onClick={onSendSignatureDocumentReminder}
        />
      )}
      {canBeEdited && (
        <ActionsPanelButton
          skin="secondary"
          icon={<EditIcon />}
          title={t('modal.signatureDocumentDetails.action.edit')}
          onClick={onEditSignatureDocument}
        />
      )}
      {canBeDownloadedWithoutTrail && (
        <ActionsPanelButton
          skin="success"
          icon={<DownloadIcon />}
          title={t(
            'modal.signatureDocumentDetails.action.downloadWithoutTrail'
          )}
          onClick={onDownloadSignatureDocumentWithoutAuditTrail}
        />
      )}
      {canBeDownloaded && (
        <ActionsPanelButton
          skin="success"
          icon={<DownloadIcon />}
          title={t('modal.signatureDocumentDetails.action.download')}
          onClick={onDownloadSignatureDocumentWithAuditTrail}
        />
      )}
      {canBeDownloadedTrailOnly && (
        <ActionsPanelButton
          skin="success"
          icon={<DownloadIcon />}
          title={t('modal.signatureDocumentDetails.action.downloadAuditOnly')}
          onClick={onDownloadSignatureDocumentAuditTrailOnly}
        />
      )}
      {canBeCanceled && (
        <ActionsPanelButton
          skin="warning"
          icon={<CloseIcon />}
          title={t('modal.signatureDocumentDetails.action.cancel')}
          onClick={onCancelSignatureDocument}
        />
      )}
    </ActionsPanel>
  );
}
