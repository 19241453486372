import { sanitizeAmount } from 'controllers/amount';

import { InvoicesType } from '../InvoicesField';

export function splitPaymentByInvoices(
  totalAmount?: number,
  invoices?: InvoicesType
) {
  if (!invoices) {
    return [];
  }

  let total = sanitizeAmount(totalAmount);
  const payments: number[] = [];

  invoices.forEach(({ balanceDue }) => {
    const payment = total - balanceDue >= 0 ? balanceDue : total;
    total -= payment;
    payments.push(payment);
  });

  return payments;
}
