import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { Button, ButtonGroup, Note, Text3, Text4 } from '@appclose/ui';
import set from 'lodash/set';
import {
  ExternalLink,
  Form,
  FormDataType,
  FormErrors,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  openConfirmAction,
} from '@appclose/core';
import notification from 'controllers/notification';

import { useIntl } from 'i18n';
import MerchantFieldsAnalytics from 'components/common/MerchantFieldsAnalytics';
import { MafSteps, MafTypes } from 'constants/analytics';
import { sanitizeAmount } from 'controllers/amount';
import { I18n } from 'i18n';

import BusinessInfoFieldset from './components/BusinessInfoFieldset';
import BusinessAddressFieldset from './components/BusinessAddressFieldset';
import OperatingAccountFieldset from './components/OperatingAccountFieldset';
import BusinessOwnersFieldset from './components/BusinessOwnersFieldset';
import {
  MerchantApplicationFormSchemaPropsType,
  MerchantApplicationsFormActionsType,
} from './MerchantApplicationForm.types';
import { MerchantApplicationFormSchema } from './MerchantApplicationForm.schema';
import styles from './MerchantApplicationForm.module.scss';
import {
  KybCompanyType,
  KybInput,
  KybPersonInput,
  PersonRelationship,
} from '__generated__/globalTypes';

export default function MerchantApplicationForm({
  isFailed,
  initialValues,
  errors,
  submitTitle,
  onSubmit,
  onClose,
  onChange,
}: MerchantApplicationFormSchemaPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();

  submitTitle = submitTitle || t('form.merchantApplication.submit');

  const checkTotalOwnersPercentage = useCallback(
    (
      owners: KybInput['owners'],
      controlPersonOwnershipPercentage: KybPersonInput['ownershipPercentage']
    ) => {
      const ownersPercentage =
        owners?.reduce((acc, owner) => {
          const percents = Number(owner?.ownershipPercentage) || 0;

          return acc + percents;
        }, 0) || 0;

      const totalOwnersPercentage =
        ownersPercentage + Number(controlPersonOwnershipPercentage);

      return totalOwnersPercentage <= 100;
    },
    []
  );

  const validatePrincipalsOwnershipPercent = useCallback(
    (
      owners: KybInput['owners'],
      setFieldError: MerchantApplicationsFormActionsType['setFieldError'],
      errors: FormErrors<KybInput>
    ) => {
      let hasError = false;

      owners?.forEach((owner, i) => {
        const { ownershipPercentage } = owner || {};

        if (
          ownershipPercentage !== undefined &&
          !get(errors, `owners[${i}].ownershipPercentage`)
        ) {
          if (Number(ownershipPercentage) < 25) {
            setFieldError(
              `owners[${i}].ownershipPercentage`,
              t('form.merchantApplication.error.ownershipPercent')
            );

            hasError = true;
          }
        }
      });

      return hasError;
    },
    [t]
  );

  const isLowOwnershipPercent = useCallback(
    (owners: KybInput['owners'], controlPerson: KybInput['controlPerson']) => {
      const ownershipPercent =
        owners?.reduce(
          (acc, owner) =>
            sanitizeAmount(acc + Number(owner?.ownershipPercentage) || 0),
          0
        ) || 0;

      return ownershipPercent + Number(controlPerson?.ownershipPercentage) < 76;
    },
    []
  );

  const availableStatuses = useMemo(
    () => [
      KybCompanyType.LLC,
      KybCompanyType.PRIVATE_PARTNERSHIP,
      KybCompanyType.PRIVATE_CORPORATION,
    ],
    []
  );

  const isDisplayField = useCallback(
    (businessInfo: KybInput['businessInfo']) => {
      const isAvailableStatus = availableStatuses.includes(
        businessInfo?.structure
      );

      return isAvailableStatus;
    },
    [availableStatuses]
  );

  const isExecutive = useCallback(
    (controlPerson: KybInput['controlPerson']) => {
      return controlPerson?.relationship === PersonRelationship.EXECUTIVE;
    },
    []
  );

  const handleOnChange = useCallback(
    (data: FormDataType<KybInput>) => {
      const {
        values: { owners, controlPerson, businessInfo },
        setFieldError,
        setValues,
        errors,
      } = data;

      const isAvailableStatus = availableStatuses.includes(
        businessInfo?.structure
      );
      const isExecutive =
        controlPerson?.relationship === PersonRelationship.EXECUTIVE;

      const isOwnerProvide = isAvailableStatus && isExecutive;

      if (isOwnerProvide) {
        if (owners?.length === 0) {
          setValues((values) => {
            return set(values, 'owners', [
              {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                ssn: '',
                phoneNumber: '',
                email: '',
                title: '',
                address1: '',
                city: '',
                state: '',
                zip: '',
              },
            ]);
          });
        }
      }

      validatePrincipalsOwnershipPercent(owners, setFieldError, errors);

      onChange?.(data);
    },
    [validatePrincipalsOwnershipPercent, onChange, availableStatuses]
  );

  const handleOnSubmit = useCallback(
    async (values: KybInput, actions: MerchantApplicationsFormActionsType) => {
      const preparedValues = {
        ...values,
        businessInfo: {
          ...values.businessInfo,
          noWebsite: undefined,
        },
      };
      const { owners, controlPerson } = preparedValues;
      const { setFieldError, setSubmitting } = actions;
      const isAvailableStatus = availableStatuses.includes(
        values.businessInfo?.structure
      );

      if (validatePrincipalsOwnershipPercent(owners, setFieldError, {})) {
        return;
      } else if (
        !checkTotalOwnersPercentage(owners, controlPerson?.ownershipPercentage)
      ) {
        return notification().error(
          new Error(
            t('form.merchantApplication.error.combinedOwnershipPercent')
          )
        );
      } else if (isLowOwnershipPercent(owners, controlPerson)) {
        dispatch(
          openConfirmAction({
            name: t('form.merchantApplication.confirmLessThan76.name'),
            content: t('form.merchantApplication.confirmLessThan76.content'),
            okButtonTitle: t(
              'form.merchantApplication.confirmLessThan76.okButton'
            ),
            cancelButtonTitle: t(
              'form.merchantApplication.confirmLessThan76.cancelButton'
            ),
            okButtonSkin: 'brand',
            onConfirm: () => {
              setSubmitting(true);
              onSubmit(preparedValues, actions);
            },
          })
        );
      } else if (!isAvailableStatus) {
        const { businessAddress, ...values } = preparedValues;
        await onSubmit(values, actions);
      } else {
        await onSubmit(preparedValues, actions);
      }
    },
    [
      dispatch,
      isLowOwnershipPercent,
      onSubmit,
      t,
      validatePrincipalsOwnershipPercent,
      checkTotalOwnersPercentage,
      availableStatuses,
    ]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onChange={handleOnChange}
      validationSchema={MerchantApplicationFormSchema}
    >
      {({ isSubmitting, values: { owners, businessInfo, controlPerson } }) => (
        <>
          <MerchantFieldsAnalytics
            step={MafSteps.MERCHANT_FORM}
            type={MafTypes.OPERATING}
            isFormSubmitting={isSubmitting}
          >
            {isFailed && (
              <Note theme="error" offset={{ bottom: 20 }}>
                <I18n id="form.KYB.onboarding.errors.note" />

                {errors?.map((error) => (
                  <Text4 offset={{ top: 16 }}>{error}</Text4>
                ))}
              </Note>
            )}
            <Note theme="important-small" offset={{ bottom: 20 }}>
              <Text4>
                <I18n id="form.KYB.onboarding.note" />
              </Text4>
            </Note>
            <BusinessInfoFieldset
              isDisplayField={isDisplayField(businessInfo)}
            />
            {isDisplayField(businessInfo) && <BusinessAddressFieldset />}
            <OperatingAccountFieldset />
            <BusinessOwnersFieldset
              owners={owners}
              isDisplayField={isDisplayField(businessInfo)}
              isExecutive={isExecutive(controlPerson)}
            />
          </MerchantFieldsAnalytics>
          <Text3 offset={{ bottom: 20, top: 20 }}>
            <I18n
              id="form.KYB.onboarding.termsNote"
              values={{
                terms: (terms: string) => (
                  <ExternalLink link={TERMS_OF_SERVICE_ROUTE}>
                    {terms}
                  </ExternalLink>
                ),
                policy: (policy: string) => (
                  <ExternalLink link={PRIVACY_POLICY_ROUTE}>
                    {policy}
                  </ExternalLink>
                ),
              }}
            />
          </Text3>
          <ButtonGroup className={styles.submit}>
            {onClose && (
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
            )}

            <Button loading={isSubmitting} type="submit" skin="brand">
              {submitTitle}
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
