import React from 'react';
import { Modal } from '@appclose/core';

import { ModalPagePropsType } from './ModalPage.types';

export default function ModalPage({
  children,
  onClose,
  className,
  closeIcon,
}: ModalPagePropsType) {
  return (
    <Modal
      className={className}
      theme="page"
      shouldCloseOnOverlayClick={false}
      closeIcon={closeIcon}
      onClose={onClose}
    >
      {children}
    </Modal>
  );
}
