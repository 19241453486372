import { gql } from '@apollo/client';

export const UPDATE_CONTACT_FILES = gql`
  mutation UpdateContactFiles($input: UpdateFilesInput!) {
    updateContactFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_INVOICE_FILES = gql`
  mutation UpdateInvoiceFiles($input: UpdateFilesInput!) {
    updateInvoiceFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_PAYMENT_FILES = gql`
  mutation UpdatePaymentFiles($input: UpdateFilesInput!) {
    updatePaymentFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_VOID_INVOICE_FILES = gql`
  mutation UpdateVoidInvoiceFiles($input: UpdateFilesInput!) {
    updateVoidInvoiceFiles(updateFilesInput: $input) {
      success
    }
  }
`;
