import React from 'react';
import { Fieldset, StaticField, FormGroup } from '@appclose/core';

import StaticFilesField from 'components/common/StaticFilesField/StaticFilesField';
import { useIntl } from 'i18n';

import { AdditionalInfoDetailsFieldsetPropsType } from './AdditionalInfoDetailsFieldset.types';

export default function AdditionalInfoDetailsFieldset({
  notes,
  files,
}: AdditionalInfoDetailsFieldsetPropsType) {
  const { t } = useIntl();

  return notes || !!files?.length ? (
    <Fieldset title={t('form.additionalInfoDetailsFieldset.title')}>
      {notes && (
        <FormGroup>
          <StaticField
            title={t('form.additionalInfoDetailsFieldset.internalNotes')}
          >
            {notes}
          </StaticField>
        </FormGroup>
      )}
      {!!files?.length && (
        <FormGroup>
          <StaticFilesField files={files} />
        </FormGroup>
      )}
    </Fieldset>
  ) : null;
}
