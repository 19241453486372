import { gql } from '@apollo/client';
import { Resolvers } from '@apollo/client/core/types';
import { OS_MAP, parse } from 'bowser';
import { history, gqlClient, traceError } from '@appclose/core';

import { SIGN_IN_ROUTE, NOT_FOUND_ROUTE, MOBILE_ROUTE } from 'constants/routes';
import { PRIVACY_KEY } from 'constants/localStorage';
import { shouldSkipErrorNotification } from 'controllers/error';
import { session } from 'controllers/session';
import { http } from 'controllers/http';
import notification from 'controllers/notification';

const clientResolvers: Resolvers = {
  Mutation: {
    setPrivacy: (_, { privacy }, { cache }) => {
      const queryResult = cache.readQuery({
        query: gql`
          query FetchPrivicy {
            getProfile {
              id
            }
          }
        `,
      });

      cache.writeQuery({
        query: gql`
          query FetchPrivicy {
            getProfile {
              id
              privacy
            }
          }
        `,
        data: {
          getProfile: { ...queryResult?.getProfile, privacy },
        },
      });

      localStorage.setItem(PRIVACY_KEY, privacy);
    },
  },
  UserType: {
    privacy: () => {
      const storedPrivacy = localStorage.getItem(PRIVACY_KEY);

      return storedPrivacy ? JSON.parse(storedPrivacy) : false;
    },
  },
};

class TokenRefresher {
  private static refreshingPromise: Promise<any> | null = null;
  static async refresh() {
    if (TokenRefresher.refreshingPromise) {
      return TokenRefresher.refreshingPromise;
    }

    TokenRefresher.refreshingPromise = http().post('/graphql', {
      query: `mutation SignInWithToken { signInWithToken(refreshToken: "${session.getRefreshToken()}") { accessToken refreshToken } }`,
    });

    try {
      const { data } = await TokenRefresher.refreshingPromise;

      if (data.errors) {
        throw data.errors;
      }

      const accessToken = data?.data?.signInWithToken?.accessToken;
      const refreshToken = data?.data?.signInWithToken?.refreshToken;

      session.setAccessToken(accessToken);
      session.setRefreshToken(refreshToken);

      return accessToken;
    } finally {
      TokenRefresher.refreshingPromise = null;
    }
  }
}

const { client, clientServices } = gqlClient({
  possibleTypes: {},
  session,
  onAuthError: (hasToken) => {
    if (hasToken) {
      history.push(NOT_FOUND_ROUTE);
    } else if (history.location.pathname !== SIGN_IN_ROUTE) {
      history.push(SIGN_IN_ROUTE, { from: history.location });
    }
  },
  onRequestError: (e) => {
    if (shouldSkipErrorNotification(e)) {
      return;
    }

    traceError(e);

    notification().error(e);
  },
  onRefreshToken: TokenRefresher.refresh,
  resolvers: clientResolvers,
  headersProvider() {
    const {
      os: { name },
    } = parse(window.navigator.userAgent);
    const isMobileRoutes = history.location.pathname.includes(MOBILE_ROUTE);

    return {
      APPCLOSEPRO_PLATFORM:
        isMobileRoutes &&
        name &&
        [OS_MAP['Android'], OS_MAP['iOS']].includes(name)
          ? name.toLowerCase()
          : 'web',
    };
  },
});

export default client;
export const refreshToken = TokenRefresher.refresh;
export const gqlClientServices = clientServices;
