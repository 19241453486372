import classnames from 'classnames';

import { Tooltip } from '../Tooltip';
import { ReactComponent as TooltipIcon } from '../../../assets/hint.svg';

import { HintPropsType } from './Hint.types';
import styles from './Hint.module.scss';

export default function Hint({
  content,
  className,
  iconClassName,
}: HintPropsType) {
  return (
    <Tooltip content={content} className={classnames(styles.hint, className)}>
      <TooltipIcon
        width={13}
        className={classnames(styles.icon, iconClassName)}
      />
    </Tooltip>
  );
}
