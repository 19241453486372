import React from 'react';
import { useQuery } from '@apollo/client';
import { Note } from '@appclose/ui';
import { Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import AdditionalInfoDetailsFieldset from 'components/common/AdditionalInfoDetailsFieldset';
import { isOnlinePayment } from 'controllers/payment';
import { I18n } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import {
  FetchPaymentQuery,
  FetchPaymentQueryVariables,
} from './__generated__/PaymentRefundDetailsModal.gql';
import TransactionDetailsFieldset from './components/TransactionDetailsFieldset';
import RefundActions from './components/RefundActions';
import { FETCH_PAYMENT } from './PaymentRefundDetailsModal.gql';
import { PaymentDetailsModalPropsType } from './PaymentRefundDetailsModal.types';
import styles from './PaymentRefundDetailsModal.module.scss';

export default function PaymentRefundDetailsModal({
  id,
  onClose,
}: PaymentDetailsModalPropsType) {
  const { loading, data: invoiceData } = useQuery<
    FetchPaymentQuery,
    FetchPaymentQueryVariables
  >(FETCH_PAYMENT, {
    variables: { id },
  });
  const payment = invoiceData?.payment;
  const { filesLoading, files } = useListFiles({
    entityType: Entities.PAYMENT,
    input: { filter: { entityIds: [id] } },
  });

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader className={styles.header}>
        <ModalPageTitle>
          <I18n id="modal.paymentRefundDetails.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading || filesLoading ? (
          <Loader />
        ) : (
          payment && (
            <>
              <RefundActions payment={payment} />
              {!isOnlinePayment(payment) && (
                <Note theme="important" offset={{ top: 40, bottom: 40 }}>
                  <I18n id="modal.paymentRefundDetails.note" />
                </Note>
              )}
              <TransactionDetailsFieldset payment={payment} />
              <AdditionalInfoDetailsFieldset
                notes={payment.internalMemo}
                files={files?.items}
              />
            </>
          )
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
