import React, { useContext } from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import { Form, InputField, Fieldset, traceError } from '@appclose/core';

import { WizardContext } from 'components/common/Wizard';
import { QboAccountTypes } from '__generated__/globalTypes';
import { I18n, useIntl } from 'i18n';

import IntegrationMappingGrid from '../IntegrationMappingGrid';
import IntegrationMappingInfo from '../IntegrationMappingInfo';
import QboAccountSelectFormField from '../QboAccountSelectFormField';
import QboServiceItemSelectFormField from '../QboServiceItemSelectFormField';
import QboMappingTargetField from '../QboMappingTargetField';
import { DiscountsAndRefundsStepSchema } from './DiscountsAndRefundsStep.schema';
import {
  DiscountsAndRefundsStepPropsType,
  DiscountsAndRefundsStepValuesType,
} from './DiscountsAndRefundsStep.types';

export default function DiscountsAndRefundsStep({
  initialValues,
  onStepComplete,
  onPreviousStep,
  onChange,
}: DiscountsAndRefundsStepPropsType) {
  const { t } = useIntl();
  const { goToPrevStep, goToNextStep, isLastStep } = useContext(WizardContext);

  const handleOnSubmit = async (values: DiscountsAndRefundsStepValuesType) => {
    try {
      await onStepComplete(values, isLastStep);
      goToNextStep();
    } catch (e) {
      traceError(e as Error);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={DiscountsAndRefundsStepSchema}
    >
      {({ values, isSubmitting }) => {
        onChange(values);
        const { isEsqash, discountEnabled } = values;

        return (
          <>
            {!isEsqash && (
              <Fieldset
                title={t(
                  'modal.qboIntegrationSetup.step.discountsAndRefunds.mapDiscounts.title'
                )}
              >
                {discountEnabled ? (
                  <>
                    <IntegrationMappingInfo>
                      <I18n id="modal.qboIntegrationSetup.step.discountsAndRefunds.mapDiscounts.enabled.info" />
                    </IntegrationMappingInfo>
                  </>
                ) : (
                  <>
                    <IntegrationMappingInfo>
                      <I18n id="modal.qboIntegrationSetup.step.discountsAndRefunds.mapDiscounts.disabled.info" />
                    </IntegrationMappingInfo>
                    <IntegrationMappingGrid
                      esqTitle={t(
                        'modal.qboIntegrationSetup.step.discountsAndRefunds.mapDiscounts.disabled.esqTitle'
                      )}
                      qboTitle={t(
                        'modal.qboIntegrationSetup.step.discountsAndRefunds.mapDiscounts.disabled.qboTitle'
                      )}
                    >
                      <QboMappingTargetField
                        title={t(
                          'modal.qboIntegrationSetup.step.discountsAndRefunds.mapDiscounts.disabled.discounts'
                        )}
                      />
                      <InputField
                        readOnly
                        value={t(
                          'modal.qboIntegrationSetup.step.discountsAndRefunds.mapDiscounts.disabled.discounts.value'
                        )}
                      />
                    </IntegrationMappingGrid>
                  </>
                )}
              </Fieldset>
            )}
            <Fieldset
              title={t(
                'modal.qboIntegrationSetup.step.discountsAndRefunds.mapRefunds.title'
              )}
            >
              <IntegrationMappingInfo>
                <I18n id="modal.qboIntegrationSetup.step.discountsAndRefunds.mapRefunds.info" />
              </IntegrationMappingInfo>
              <IntegrationMappingGrid
                esqTitle={t(
                  'modal.qboIntegrationSetup.step.discountsAndRefunds.mapRefunds.esqTitle'
                )}
                qboTitle={t(
                  'modal.qboIntegrationSetup.step.discountsAndRefunds.mapRefunds.qboTitle'
                )}
              >
                <QboMappingTargetField
                  title={t(
                    'modal.qboIntegrationSetup.step.discountsAndRefunds.mapRefunds.refundItem'
                  )}
                />
                <QboServiceItemSelectFormField name="refundItem" />
              </IntegrationMappingGrid>
            </Fieldset>
            <Fieldset
              title={t(
                'modal.qboIntegrationSetup.step.discountsAndRefunds.mapMerchantFees.title'
              )}
            >
              <IntegrationMappingInfo>
                <I18n id="modal.qboIntegrationSetup.step.discountsAndRefunds.mapMerchantFees.info" />
              </IntegrationMappingInfo>
              <IntegrationMappingGrid
                esqTitle="Appclose Pro item"
                qboTitle="Target QBO account"
                qboTooltip={t(
                  'modal.qboIntegrationSetup.step.discountsAndRefunds.mapMerchantFees.qboTooltip'
                )}
              >
                <QboMappingTargetField
                  title={t(
                    'modal.qboIntegrationSetup.step.discountsAndRefunds.mapMerchantFees.creditCardFeesAccount'
                  )}
                />
                <QboAccountSelectFormField
                  name="creditCardFeesAccount"
                  accountType={QboAccountTypes.EXPENSE}
                />
              </IntegrationMappingGrid>
            </Fieldset>
            <ButtonGroup>
              <Button
                onClick={() => {
                  onPreviousStep(values);
                  goToPrevStep();
                }}
                disabled={isSubmitting}
              >
                <I18n id="modal.qboIntegrationSetup.step.discountsAndRefunds.back" />
              </Button>
              <Button type="submit" skin="brand" loading={isSubmitting}>
                <I18n id="modal.qboIntegrationSetup.step.discountsAndRefunds.continue" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
