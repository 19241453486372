import { createContext } from 'react';
import noop from 'lodash/noop';

import { WizardContextType } from './Wizard.types';

export const WizardContext = createContext<WizardContextType>({
  step: 0,
  isFirstStep: false,
  isLastStep: false,
  completed: false,
  loading: false,
  complete: noop,
  goToNextStep: noop,
  goToPrevStep: noop,
});
