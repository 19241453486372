import { gql } from '@apollo/client';

import { CONTACT_SELECT_FRAGMENT } from 'components/fields/ContactSelectFormField';
import { INVOICE_HISTORY_FRAGMENT } from 'components/modals/pages/InvoiceDetailsModal';

export const FETCH_CONTACT = gql`
  query FetchContact($id: ID!) {
    contact: getContact(id: $id) {
      ...ContactSelect
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;

const INVOICE_EDIT_FRAGMENT = gql`
  fragment InvoiceEdit on InvoiceType {
    id
    contact {
      ...ContactSelect
    }
    invoiceNumber
    dueDate
    percentageDiscount
    discount
    balanceDue
    destinationAccount
    message
    internalMemo
    scheduledPayments {
      plan {
        amount
        paymentDate
        status
      }
      settings {
        installments
        invoiceEvery
        invoicePeriod
        startDate
      }
    }
    createdAt
  }
  ${CONTACT_SELECT_FRAGMENT}
`;

export const FETCH_INVOICE = gql`
  query FetchInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      ...InvoiceEdit
    }
  }
  ${INVOICE_EDIT_FRAGMENT}
`;

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($invoice: CreateInvoiceInput!) {
    createInvoice(createInvoiceInput: $invoice) {
      id
      invoiceNumber
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($invoice: UpdateInvoiceInput!) {
    updateInvoice(updateInvoiceInput: $invoice) {
      ...InvoiceEdit
      ...InvoiceHistory
    }
  }
  ${INVOICE_EDIT_FRAGMENT}
  ${INVOICE_HISTORY_FRAGMENT}
`;
