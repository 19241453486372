import HelloSign from 'classes/HelloSign';

let _hellosign: HelloSign;

export default function hellosign(): HelloSign {
  if (!_hellosign) {
    _hellosign = new HelloSign();
  }

  return _hellosign;
}
