import { gql } from '@apollo/client';

export const CREATE_NEW_DOCUMENT_TO_SIGN_FROM_FILE = gql`
  mutation CreateNewDocumentToSignFromFile(
    $input: CreateSignatureDraftWithoutTemplatesInput!
  ) {
    document: createSignatureDraftWithoutTemplates(
      createSignatureDraftWithoutTemplatesInput: $input
    ) {
      signature {
        id
      }
      signatureUrl {
        url
      }
    }
  }
`;
