import * as Yup from 'yup';

import { FILES_SCHEMA } from 'schemas/validations/files';

import { InvoiceVoidFormValuesType } from './InvoiceVoidForm.types';

export const InvoiceVoidFormSchema = Yup.object().shape<
  Pick<InvoiceVoidFormValuesType, 'files'>
>({
  files: FILES_SCHEMA,
});
