import React, { ReactElement } from 'react';
import { Grid } from '@appclose/ui';

import { ReactComponent as DoubleArrowIcon } from 'assets/dobule-arrow.svg';

import SystemLabel from './components/SystemLabel';
import { IntegrationMappingGridPropsType } from './IntegrationMappingGrid.types';
import styles from './IntegrationMappingGrid.module.scss';

export default function IntegrationMappingGrid({
  children,
  esqTitle,
  qboTitle,
  qboTooltip,
}: IntegrationMappingGridPropsType) {
  return (
    <>
      <Grid gap={[149, 20]} columns="repeat(2, minmax(0, 1fr))">
        <SystemLabel type="esq" text={esqTitle} />
        <SystemLabel type="qbo" text={qboTitle} tooltip={qboTooltip} />
      </Grid>
      <Grid gap={[60, 20]} columns="minmax(0px, 1fr) auto minmax(0px, 1fr)">
        {React.Children.toArray(children)
          .reduce<ReactElement[]>((acc, elem) => {
            if (React.isValidElement(elem)) {
              if (elem.type === React.Fragment) {
                acc.push(...elem.props.children);
              } else {
                acc.push(elem);
              }
            }

            return acc;
          }, [])
          .reduce<ReactElement[]>((acc, elem, index) => {
            if (React.isValidElement(elem)) {
              acc.push(
                React.cloneElement(elem, {
                  ...(elem as ReactElement)?.props,
                  key: index,
                })
              );
            }

            if (index % 2 === 0) {
              acc.push(
                <DoubleArrowIcon
                  className={styles.doubleArrowIcon}
                  key={`arrow-${index}`}
                />
              );
            }

            return acc;
          }, [])}
      </Grid>
    </>
  );
}
