import React from 'react';

import { ScheduledPaymentStatuses } from '__generated__/globalTypes';
import { I18n } from 'i18n';

import { StatusPropsType } from './Status.types';

export default function Status({ status }: StatusPropsType) {
  switch (status) {
    case ScheduledPaymentStatuses.FAILED:
      return <I18n id="enum.payment.scheduled.status.failed" />;
    case ScheduledPaymentStatuses.PAID:
      return <I18n id="enum.payment.scheduled.status.paid" />;
    case ScheduledPaymentStatuses.SCHEDULED:
      return <I18n id="enum.payment.scheduled.status.scheduled" />;
    default:
      return null;
  }
}
