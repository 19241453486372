import React, { FocusEvent } from 'react';
import get from 'lodash/get';
import { useFormContext } from '@appclose/core';

import { track } from 'controllers/analytics';
import { EventNames } from 'constants/analytics';

import { MerchantFieldsAnalyticsPropsType } from './MerchantFieldsAnalytics.types';

export default function MerchantFieldsAnalytics({
  children,
  step,
  type,
  isFormSubmitting = false,
}: MerchantFieldsAnalyticsPropsType) {
  const { values } = useFormContext();

  const onBlur = (e: FocusEvent<HTMLDivElement>) => {
    if (!isFormSubmitting) {
      // @ts-ignore
      const field = e.target.name || e.target.id;
      const section = e.target
        .closest('fieldset')
        ?.querySelector('legend')
        ?.textContent?.toLocaleLowerCase();

      if (field) {
        const valueLength = get(values, field)?.toString()?.length || 0;

        track(EventNames.MAF_FIELD_EDITED, {
          maf_type: type,
          maf_step: step,
          field,
          section,
          n_symbols: valueLength,
        });
      }
    }
  };

  return <div onBlur={onBlur}>{children}</div>;
}
