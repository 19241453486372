import { useCallback, useMemo } from 'react';
import { useIntl } from 'i18n';
import { Maybe, PhoneTypes } from '__generated__/globalTypes';

export default function usePhoneTypes() {
  const { t } = useIntl();
  const phoneTypes: Record<PhoneTypes, string> = useMemo(
    () => ({
      [PhoneTypes.FAX]: t('enum.phoneTypes.fax'),
      [PhoneTypes.HOME]: t('enum.phoneTypes.home'),
      [PhoneTypes.MOBILE]: t('enum.phoneTypes.mobile'),
      [PhoneTypes.OFFICE]: t('enum.phoneTypes.office'),
    }),
    [t]
  );

  const phoneTypesOptions: {
    label: string;
    value: PhoneTypes;
  }[] = useMemo(
    () =>
      Object.entries(phoneTypes).map(([value, label]) => ({
        value: value as PhoneTypes,
        label,
      })),
    [phoneTypes]
  );

  const getPhoneTypeLabel = useCallback(
    (type?: PhoneTypes | null, isPrimary?: Maybe<boolean> | undefined) => {
      if (!type) {
        return '';
      }

      if (isPrimary) {
        return t('enum.phoneTypes.primary');
      }

      return phoneTypes[type as PhoneTypes];
    },
    [phoneTypes, t]
  );

  return { phoneTypesOptions, getPhoneTypeLabel };
}
