import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const FETCH_PROFILE = gql`
  query FetchProfile {
    profile: getProfile {
      id
      accessLevel
    }
  }
`;

export const CREATE_TEAM_MEMBER = gql`
  mutation CreateTeamMember($input: CreateTeamMemberInput!) {
    createTeamMember(createTeamMemberInput: $input) {
      id
    }
  }
`;

export const FETCH_TEAM_MEMBER = gql`
  query FetchTeamMember($id: ID!) {
    teamMember: getTeamMember(id: $id) {
      id
      firstName
      lastName
      middleName
      picture {
        ...Avatar
      }
      email
      additionalEmails
      phoneNumbers {
        type
        number
      }
      addresses {
        type
        city
        address1
        address2
        state
        zipCode
      }
      title
      website
      licenseNumber
      licensedInStates
      licensedSince
      practiceAreas
      gender
      role
      accessLevel
      hourlyRate
      isOwner
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const UPDATE_TEAM_MEMBER = gql`
  mutation UpdateTeamMember($input: UpdateTeamMemberInput!, $picture: Upload) {
    updateTeamMember(updateTeamMemberInput: $input, picture: $picture) {
      id
      firstName
      lastName
      middleName
      picture {
        ...Avatar
      }
      email
      additionalEmails
      phoneNumbers {
        type
        number
      }
      addresses {
        type
        city
        address1
        address2
        state
        zipCode
      }
      title
      website
      licenseNumber
      licensedInStates
      licensedSince
      practiceAreas
      gender
      role
      accessLevel
      hourlyRate
    }
  }
  ${AVATAR_FRAGMENT}
`;
