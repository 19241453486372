import {
  FetchContactQuery,
  FetchInvoiceQuery,
} from './__generated__/ReceivePaymentModal.gql';

export const getInitialContact = (
  contactData?: FetchContactQuery,
  invoiceData?: FetchInvoiceQuery
) => {
  return contactData?.contact || invoiceData?.invoice?.contact;
};

export const getInitialInvoices = (invoiceData?: FetchInvoiceQuery) => {
  return invoiceData?.invoice ? [invoiceData.invoice] : [];
};
