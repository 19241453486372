export enum FileMimeTypes {
  DOCX = '.docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = '.doc, application/msword',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PPT = 'application/vnd.ms-powerpoint',
  PPSX = 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  PDF = 'application/pdf',
  HTML = 'text/html',
  TXT = 'text/plain',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
  VCF = 'text/vcard',
  XVCF = 'text/x-vcard',
  DIRECTORY = 'text/directory',
}

export const BANNED_FILES_EXTENSIONS = [
  'action',
  'apk',
  'app',
  'bat',
  'bin',
  'cmd',
  'com',
  'command',
  'cpl',
  'csh',
  'exe',
  'gadget',
  'inf',
  'ins',
  'inx',
  'ipa',
  'isu',
  'job',
  'jse',
  'ksh',
  'lnk',
  'msc',
  'msi',
  'msp',
  'mst',
  'osx',
  'out',
  'paf',
  'pif',
  'prg',
  'ps1',
  'reg',
  'rgs',
  'run',
  'scr',
  'sct',
  'shb',
  'shs',
  'u3p',
  'vb',
  'vbe',
  'vbs',
  'vbscript',
  'workflow',
  'ws',
  'wsf',
  'wsh',
  '0xe',
  '73k',
  '89k',
  'a6p',
  'ac',
  'acc',
  'acr',
  'actm',
  'ahk',
  'air',
  'app',
  'arscript',
  'as',
  'asb',
  'awk',
  'azw2',
  'beam',
  'btm',
  'cel',
  'celx',
  'chm',
  'cof',
  'crt',
  'dek',
  'dld',
  'dmc',
  'docm',
  'dotm',
  'dxl',
  'ear',
  'ebm',
  'ebs',
  'ebs2',
  'ecf',
  'eham',
  'elf',
  'es',
  'ex4',
  'exopc',
  'ezs',
  'fas',
  'fky',
  'fpi',
  'frs',
  'fxp',
];

const joinTypes = (types: string[]) => types.join(', ');

export const CONTACTS_IMPORT_FILE_MIME_TYPES = joinTypes([
  FileMimeTypes.VCF,
  FileMimeTypes.XVCF,
  FileMimeTypes.DIRECTORY,
]);

export const ALLOWED_ATTACH_IMAGES_MIME_TYPES = joinTypes([
  FileMimeTypes.JPEG,
  FileMimeTypes.PNG,
]);

export const SIGNATURE_ATTACH_FILES_MIME_TYPES = joinTypes([
  FileMimeTypes.DOCX,
  FileMimeTypes.DOC,
  FileMimeTypes.XLSX,
  FileMimeTypes.XLS,
  FileMimeTypes.PPTX,
  FileMimeTypes.PPT,
  FileMimeTypes.PPSX,
  FileMimeTypes.PDF,
  FileMimeTypes.HTML,
  FileMimeTypes.TXT,
  FileMimeTypes.JPEG,
  FileMimeTypes.PNG,
  FileMimeTypes.GIF,
]);

export const ALLOWED_CHAT_IMAGES_MIME_TYPES = joinTypes([
  FileMimeTypes.JPEG,
  FileMimeTypes.PNG,
  FileMimeTypes.GIF,
]);

export const ALLOWED_VIDEO_MIME_TYPES = joinTypes([
  FileMimeTypes.MP4,
  FileMimeTypes.WEBM,
]);

export const ALLOWED_CHAT_MEDIA_MIME_TYPES = joinTypes([
  ALLOWED_CHAT_IMAGES_MIME_TYPES,
  ALLOWED_VIDEO_MIME_TYPES,
]);

export const SIGNATURE_MAX_ATTACH_SIZE_MB = 40;
export const DEFAULT_MAX_ATTACH_LENGTH = 10;
export const CHAT_MAX_FILE_SIZE_MB = 100;

export const MEGABYTE_MULTIPLIER = 1000000;
