import React from 'react';
import { Button, ButtonGroup, Offset } from '@appclose/ui';
import {
  Amount,
  AmountFormField,
  Form,
  FormGroup,
  FormSubmissionError,
  StaticField,
  Fieldset,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import AdditionalInfoFieldset from 'components/form/AdditionalInfoFieldset';
import PaymentMethodInfo from 'components/common/PaymentMethodInfo';
import { I18n, useIntl } from 'i18n';
import { Entities } from 'constants/entities';

import { PaymentRefundFormSchema } from './PaymentRefundForm.schema';
import { PaymentRefundFormPropsType } from './PaymentRefundForm.types';

export default function PaymentRefundForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: PaymentRefundFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={PaymentRefundFormSchema}
    >
      {({
        isSubmitting,
        values: {
          payment: {
            amount,
            refundAmount,
            paymentMethod,
            truncatedPAN,
            contact,
            createdAt,
          },
          maxAmount,
        },
      }) => {
        return (
          <>
            <Fieldset
              title={t('modal.paymentRefund.form.fieldset.transactionDetails')}
            >
              <FormGroup>
                <FormGrid>
                  <StaticField
                    title={t('modal.paymentRefund.form.field.contact')}
                  >
                    {contact.name}
                  </StaticField>
                  <StaticField
                    title={t('modal.paymentRefund.form.field.transactionDate')}
                  >
                    <Date value={createdAt} />
                  </StaticField>
                  <StaticField
                    title={t(
                      'modal.paymentRefund.form.field.transactionAmount'
                    )}
                  >
                    <Amount value={amount} />
                  </StaticField>
                  <StaticField
                    title={t('modal.paymentRefund.form.field.paymentMethod')}
                  >
                    <PaymentMethodInfo
                      paymentMethod={paymentMethod}
                      truncatedPAN={truncatedPAN}
                    />
                  </StaticField>
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <Fieldset
              title={t('modal.paymentRefund.form.fieldset.refundDetails')}
            >
              <FormGroup>
                <FormGrid>
                  <AmountFormField
                    label={t(
                      'modal.paymentRefund.form.field.refundAmount.label'
                    )}
                    name="amount"
                    tooltip={t(
                      'modal.paymentRefund.form.field.refundAmount.tooltip',
                      {
                        refundAmount: <Amount strong value={refundAmount} />,
                        maxAmount: <Amount strong value={maxAmount} />,
                      }
                    )}
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <AdditionalInfoFieldset
              entityType={Entities.PAYMENT}
              notesFieldName="internalMemo"
            />
            <Offset bottom="m">
              <FormSubmissionError />
            </Offset>
            <ButtonGroup>
              <Button onClick={onCancel}>
                <I18n id="modal.paymentRefund.form.button.cancel" />
              </Button>
              <Button loading={isSubmitting} type="submit" skin="brand">
                <I18n id="modal.paymentRefund.form.button.submit" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
