import { FetchInvoiceQuery } from './__generated__/InvoiceDetailsModal.gql';

export type InvoiceType = FetchInvoiceQuery['invoice'];
export type InvoiceHistoryType = InvoiceType['history'][0];

export enum InvoiceDetailsModalTabs {
  INFO,
  TRANSACTIONS,
  HISTORY,
  REMINDERS,
}

export interface InvoiceDetailsModalPropsType {
  id: string;
  onClose(): void;
  defaultTab?: InvoiceDetailsModalTabs;
}
