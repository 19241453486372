import React from 'react';
import { Flex } from '@appclose/ui';
import { StatsOverdueIcon, AlarmIcon } from '@appclose/icons';

import { I18n } from 'i18n';

import { ModifiersPropsType } from './Modifiers.types';
import styles from './Modifiers.module.scss';

export default function Modifiers({
  invoice: { scheduledPayments, paymentReminder },
}: ModifiersPropsType) {
  return (
    <>
      {scheduledPayments && (
        <Flex className={styles.info} alignItems="center">
          <StatsOverdueIcon />
          <p className={styles.note}>
            <I18n
              id={
                scheduledPayments?.planApproved
                  ? 'invoiceInfo.modifiers.planApprovedNote'
                  : 'invoiceInfo.modifiers.planNotApprovedNote'
              }
            />
          </p>
        </Flex>
      )}
      {(paymentReminder?.prepaymentReminder ||
        paymentReminder?.overdueReminder) && (
        <Flex className={styles.info} alignItems="center">
          <AlarmIcon />
          <p className={styles.note}>
            <I18n id="invoiceInfo.modifiers.remindersApplied" />
          </p>
        </Flex>
      )}
    </>
  );
}
