import React from 'react';
import { Caption2, Grid } from '@appclose/ui';

import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';

import { PaymentMethodInfoPropsType } from './PaymentMethodInfo.types';

export default function PaymentMethodInfo({
  paymentMethod,
  truncatedPAN,
}: PaymentMethodInfoPropsType) {
  const { getPaymentMethodsTypeLabel } = usePaymentMethodsTypes();

  return (
    <Grid gap={[8]} autoFlow="column" justifyContent="start" align="baseline">
      <span>{getPaymentMethodsTypeLabel(paymentMethod)}</span>
      {truncatedPAN && <Caption2 weight="light">****{truncatedPAN}</Caption2>}
    </Grid>
  );
}
