import { SelectFormField } from '@appclose/core';

import { OwnershipTypeSelectFormFieldPropsType } from './OwnershipTypeSelectFormField.types';
import useKybCompanyTypes from 'hooks/useKybCompanyTypes';

export default function OwnershipTypeSelectFormField({
  name = 'businessInfo.structure',
  label = 'Ownership type',
  placeholder,
  showLabel,
  showPlaceholder,
  ...props
}: OwnershipTypeSelectFormFieldPropsType) {
  const { kybCompanyTypeOptions } = useKybCompanyTypes();

  return (
    <SelectFormField
      name={name}
      label={label}
      placeholder={placeholder}
      showLabel={showLabel}
      showPlaceholder={showPlaceholder}
      {...props}
      options={kybCompanyTypeOptions}
    />
  );
}
