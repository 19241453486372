import React, { useCallback } from 'react';
import set from 'lodash/set';
import {
  dateManager,
  DatePickerFormField,
  FormGroup,
  InputFormField,
  StaticField,
  // ToggleFormField,
  useFormContext,
  Fieldset,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import DueDaysSelectFormField, {
  DUE_DAYS,
} from 'components/fields/DueDaysSelectFormField';
import { SchedulePreviewItem } from 'components/common/SchedulePreview';
import Date from 'components/common/Date';
import { SERVER_DATE_TIME_FORMAT } from 'constants/date';
import { useIntl } from 'i18n';

import { InvoiceFormValuesType } from '../../../../InvoiceForm.types';
import SchedulePaymentsFormField from './components/SchedulePaymentsFormField';
import { PaymentDetailsFieldsetPropsType } from './PaymentDetailsFieldset.types';
// import styles from './PaymentDetailsFieldset.module.scss';

export default function PaymentDetailsFieldset({
  isEdit,
}: PaymentDetailsFieldsetPropsType) {
  const { t } = useIntl();
  const {
    setFieldValue,
    values: {
      dueDays,
      dueDate,
      scheduleSettings,
      schedulePlan,
      scheduleRecurringPayments,
      scheduleLoading,
      amount,
      createdAt,
    },
    setValues,
  } = useFormContext<InvoiceFormValuesType>();

  const withScheduleRecurringPayments =
    scheduleRecurringPayments &&
    !!scheduleSettings?.startDate &&
    !!scheduleSettings?.invoicePeriod &&
    !!scheduleSettings?.invoiceEvery &&
    !!scheduleSettings?.installments;

  const handleOnDueDaysChange = useCallback(
    (value: DUE_DAYS) => {
      setFieldValue(
        'dueDate',
        dateManager()
          .parse()
          .add(parseInt(value) || 0, 'days')
          .format(SERVER_DATE_TIME_FORMAT),
        true
      );
    },
    [setFieldValue]
  );

  // const handleOnScheduleRecurringPaymentsChange = useCallback(
  //   (e: ChangeEvent<HTMLInputElement>) => {
  //     if (e.target.checked && !dueDate && schedulePlan) {
  //       const date = schedulePlan[schedulePlan.length - 1].paymentDate;

  //       setFieldValue(
  //         'dueDate',
  //         dateManager().format(date, SERVER_DATE_TIME_FORMAT),
  //         true
  //       );
  //     }
  //   },
  //   [dueDate, schedulePlan, setFieldValue]
  // );

  const handleOnScheduleChange = useCallback(
    (schedule: SchedulePreviewItem[]) => {
      if (schedule.length) {
        const date = schedule[schedule.length - 1].paymentDate;

        if (date !== dueDate) {
          setValues(
            (values) =>
              set(
                set(
                  values,
                  'dueDate',
                  dateManager().format(date, SERVER_DATE_TIME_FORMAT)
                ),
                'dueDays',
                DUE_DAYS.CUSTOM
              ),
            true
          );
        }
      }
    },
    [dueDate, setValues]
  );

  return (
    <Fieldset title={t('modal.invoice.form.fieldset.paymentDetails')}>
      <FormGroup>
        <FormGrid>
          <InputFormField
            name="invoiceNumber"
            disabled={isEdit}
            label={
              isEdit
                ? t('modal.invoice.form.field.invoiceNumber.label')
                : t('modal.invoice.form.field.invoiceNumber.label.optional')
            }
            placeholder={t('system.placeholder.invoiceNumber')}
            tooltip={t('modal.invoice.form.field.invoiceNumber.tooltip')}
          />
        </FormGrid>
      </FormGroup>
      <FormGroup>
        <FormGrid>
          <DueDaysSelectFormField
            tooltip={t('modal.invoice.form.field.dueDays.tooltip')}
            disabled={withScheduleRecurringPayments}
            onChange={handleOnDueDaysChange}
          />
          {dueDays !== DUE_DAYS.CUSTOM ? (
            <StaticField title={t('modal.invoice.form.field.dueDate')}>
              <Date value={dueDate} />
            </StaticField>
          ) : (
            <DatePickerFormField
              name="dueDate"
              label={t('modal.invoice.form.field.dueDate')}
              outputDateFormat={SERVER_DATE_TIME_FORMAT}
              minDate={
                isEdit
                  ? dateManager().parse(createdAt).startOf('day').valueOf()
                  : dateManager().parse().startOf('day').valueOf()
              }
              endOfDate
              disabled={withScheduleRecurringPayments}
            />
          )}
        </FormGrid>
      </FormGroup>
      {/* <div className={styles.toggles}>
        <FormGroup>
          <ToggleFormField
            name="scheduleRecurringPayments"
            label={t('modal.invoice.form.field.scheduleRecurringPayments')}
            onChange={handleOnScheduleRecurringPaymentsChange}
          />
        </FormGroup>
      </div> */}
      {scheduleRecurringPayments && (
        <SchedulePaymentsFormField
          setFieldValue={setFieldValue}
          scheduleSettings={scheduleSettings}
          schedulePlan={schedulePlan}
          scheduleLoading={scheduleLoading}
          onScheduleChange={handleOnScheduleChange}
          amount={amount}
        />
      )}
    </Fieldset>
  );
}
