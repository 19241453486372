import { gql } from '@apollo/client';

const INVOICE_FRAGMENT = gql`
  fragment InvoiceFieldFragment on InvoiceType {
    id
    invoiceNumber
    createdAt
    dueDate
    overdueDayQty
    missedPaymentQty
    contact {
      id
      name
      firstName
      lastName
      email
    }
    balanceDue
    status
    destinationAccount
    qboSyncState
  }
`;

export const FETCH_INVOICES = gql`
  query FetchInvoices($input: ListInvoiceInput!) {
    invoices: listInvoices(listInvoiceInput: $input) {
      total
      items {
        ...InvoiceFieldFragment
      }
    }
  }
  ${INVOICE_FRAGMENT}
`;

export const ON_INVOICE_CREATE = gql`
  subscription InvoiceCreate(
    $invoiceSubscriptionInput: InvoiceSubscriptionInput!
  ) {
    invoiceCreated(invoiceSubscriptionInput: $invoiceSubscriptionInput) {
      id
    }
  }
`;

export const ON_INVOICE_UPDATE = gql`
  subscription InvoiceUpdate(
    $invoiceSubscriptionInput: InvoiceSubscriptionInput!
  ) {
    invoiceUpdated(invoiceSubscriptionInput: $invoiceSubscriptionInput) {
      ...InvoiceFieldFragment
    }
  }
  ${INVOICE_FRAGMENT}
`;
