import React from 'react';

import { ModalPageHeaderPropsType } from './ModalPageHeader.types';
import { Offset } from '@appclose/ui';

export default function ModalPageHeader({
  className,
  children,
}: ModalPageHeaderPropsType) {
  return (
    <Offset bottom="l" className={className}>
      {children}
    </Offset>
  );
}
