import React from 'react';
import { InputFormField, FormGroup } from '@appclose/core';

import { useIntl } from 'i18n';

import { NonSignatoryRecipientPropsType } from './NonSignatoryRecipient.types';

export default function NonSignatoryRecipient({
  resolveName,
}: NonSignatoryRecipientPropsType) {
  const { t } = useIntl();

  return (
    <FormGroup>
      <InputFormField
        name={resolveName('email')}
        label={t(
          'modal.signatureDocument.form.nonSignatoryRecipients.recipient.email.label'
        )}
        placeholder={t(
          'modal.signatureDocument.form.nonSignatoryRecipients.recipient.email.placeholder'
        )}
        tooltip={t(
          'modal.signatureDocument.form.nonSignatoryRecipients.recipient.email.tooltip'
        )}
      />
    </FormGroup>
  );
}
