import React from 'react';
import classnames from 'classnames';

import { IntegrationMappingInfoPropsType } from './IntegrationMappingInfo.types';
import style from './IntegrationMappingInfo.module.scss';

export default function IntegrationMappingInfo({
  children,
  className,
}: IntegrationMappingInfoPropsType) {
  return <p className={classnames(style.info, className)}>{children}</p>;
}
