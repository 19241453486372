import React from 'react';
import classnames from 'classnames';

import FirmLogoPlaceholder from 'assets/firm-logo-paceholder.svg';
import { FirmLogoPropsType } from './FirmLogo.types';
import styles from './FirmLogo.module.scss';

export default function FirmLogo({ logo, huge, className }: FirmLogoPropsType) {
  return (
    <div
      className={classnames(styles.logo, className, {
        [styles.empty]: !logo,
        [styles.huge]: huge,
      })}
    >
      <img src={logo || FirmLogoPlaceholder} alt="" className={styles.img} />
    </div>
  );
}
