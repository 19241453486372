import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
  useFormContext,
} from '@appclose/core';
import set from 'lodash/set';
import get from 'lodash/get';

import FormGrid from 'components/common/FormGrid/FormGrid';
import FieldsetFormArray from 'components/form/FieldsetFormArray';
import {
  BillingContactTypes,
  EntityBillingContactsInput,
} from '__generated__/globalTypes';
import { INITIAL_BILLING_CONTACT } from 'constants/contacts';
import { useIntl } from 'i18n';
import { ContactAndEntityFormValuesType } from 'components/modals/pages/ContactModal/components/ContactForm';

import { ContactsFieldsetPropsType } from './ContactsFieldset.types';
import { PrimaryCheckbox } from '../PrimaryCheckbox';

export default function ContactsFieldset({
  name = 'billingContacts',
  title,
  allowRemoveLast = false,
}: ContactsFieldsetPropsType) {
  const { t } = useIntl();
  const fieldsetTitle = title || t('form.contactsFieldset.defaultTitle');
  const {
    getFieldMeta,
    setValues,
    values,
  } = useFormContext<ContactAndEntityFormValuesType>();
  const { value } = getFieldMeta<typeof INITIAL_BILLING_CONTACT[]>(name);
  const primary = value?.find(
    ({ type }) => type === BillingContactTypes.PRIMARY
  );
  const [hasPrimary, setHasPrimary] = useState(!!primary);

  const addresses: EntityBillingContactsInput[] = useMemo(
    () => get(values, name),
    [values, name]
  );

  const setPrimaryByIndex = useCallback(
    (primaryIndex: number) => {
      setValues((values) => {
        return set(
          values,
          name,
          addresses?.map((address, index) => ({
            ...address,
            type:
              index === primaryIndex
                ? BillingContactTypes.PRIMARY
                : BillingContactTypes.SECONDARY,
          }))
        );
      });
    },
    [addresses, name, setValues]
  );

  useEffect(() => {
    setHasPrimary(!!primary);

    if (!primary) {
      setPrimaryByIndex(0);
    }
  }, [primary, setPrimaryByIndex]);

  const initialData = useMemo(
    () =>
      !hasPrimary
        ? INITIAL_BILLING_CONTACT
        : { ...INITIAL_BILLING_CONTACT, type: BillingContactTypes.SECONDARY },
    [hasPrimary]
  );

  return (
    <FieldsetFormArray
      title={fieldsetTitle}
      name={name}
      addButtonLabel={t('form.contactsFieldset.addButtonLabel')}
      limit={5}
      allowRemoveLast={allowRemoveLast}
      initialData={initialData}
    >
      {({ value, resolveName, index }) => (
        <FormGroup>
          <FormGrid>
            <InputFormField
              name={resolveName('email')}
              label={t('form.contactsFieldset.email')}
            />
            <UpperFirstInputFormField
              name={resolveName('firstName')}
              label={t('form.contactsFieldset.firstName')}
            />
            <UpperFirstInputFormField
              name={resolveName('middleName')}
              label={t('form.contactsFieldset.middleName')}
            />
            <UpperFirstInputFormField
              name={resolveName('lastName')}
              label={t('form.contactsFieldset.lastName')}
            />
          </FormGrid>
          <PrimaryCheckbox
            name={resolveName('type')}
            checked={value.type === BillingContactTypes.PRIMARY}
            onChange={() => setPrimaryByIndex(index)}
            label={t('form.contactsFieldset.primaryLabel')}
          />
        </FormGroup>
      )}
    </FieldsetFormArray>
  );
}
