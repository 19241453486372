import { AllowPermissionType } from '@appclose/lib';

import { PermissionActions, PermissionResources } from 'constants/permissions';

export const REFUND_ACTIONS_PERMISSIONS: AllowPermissionType = {
  [PermissionResources.REFUND_PAYMENT]: {
    '*': {
      actions: [PermissionActions.READ, PermissionActions.SEND],
    },
  },
};
