import React from 'react';
import { SelectFormField } from '@appclose/core';

import { useIntl } from 'i18n';

import { PeriodSelectFormFieldPropsType } from './PeriodSelectFormField.types';
import { useOptions } from './PeriodSelectFormField.utils';

export default function PeriodSelectFormField({
  name = 'period',
  label,
  placeholder,
  showLabel = 'onFocus',
  showPlaceholder = 'always',
  ...props
}: PeriodSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { options } = useOptions();

  return (
    <SelectFormField
      name={name}
      label={label || t('field.periodSelectFormField.label')}
      placeholder={placeholder || t('field.periodSelectFormField.placeholder')}
      showLabel={showLabel}
      showPlaceholder={showPlaceholder}
      {...props}
      options={options}
    />
  );
}
