import { gql } from '@apollo/client';

import { INVOICE_INFO_FRAGMENT } from 'components/common/InvoiceInfo';
import { FIRM_INFO_FRAGMENT } from 'components/common/FirmInfo';

export const FETCH_DATA = gql`
  query FetchData($id: ID!) {
    invoice: getInvoice(id: $id) {
      ...InvoiceInfo
      contact {
        email
        isEntity
        billingContacts {
          id
          firstName
          lastName
          email
          type
        }
      }
      message
    }
    firm: getFirm {
      ...FirmInfo
    }
  }
  ${INVOICE_INFO_FRAGMENT}
  ${FIRM_INFO_FRAGMENT}
`;

export const SEND_INVOICE = gql`
  mutation SendInvoice($input: SendInvoiceInput!) {
    sendInvoice(sendInvoiceInput: $input) {
      id
      status
      history {
        status
        date
        client {
          email
        }
      }
    }
  }
`;
