import { gql } from '@apollo/client';

export const DOWNLOAD_SIGNATURE_DOCUMENT_WITH_AUDIT_TRAIL = gql`
  query DownloadSignatureDocumentWithAuditTrail($id: ID!) {
    file: downloadSignatureDocumentWithAuditTrail(
      signatureIdInput: { id: $id }
    ) {
      url
    }
  }
`;

export const DOWNLOAD_SIGNATURE_DOCUMENT_WITHOUT_AUDIT_TRAIL = gql`
  query DownloadSignatureDocumentWithoutAuditTrail($id: ID!) {
    file: downloadSignatureDocumentOnly(signatureIdInput: { id: $id }) {
      url
    }
  }
`;

export const DOWNLOAD_SIGNATURE_DOCUMENT_AUDIT_TRAIL_ONLY = gql`
  query DownloadSignatureDocumentAuditTrailOnly($id: ID!) {
    file: downloadSignatureAuditTrail(signatureIdInput: { id: $id }) {
      url
    }
  }
`;
