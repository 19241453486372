import { gql } from '@apollo/client';

export const GOOGLE_FOLDER_FRAGMENT = gql`
  fragment GoogleFolder on FoldersInDriveType {
    id
    name
  }
`;

export const GET_GOOGLE_DRIVE_FOLDERS = gql`
  query GetGoogleDriveFolders(
    $driveId: ID
    $parentId: ID
    $nextPageToken: String
  ) {
    result: getFoldersFromGoogle(
      getDriveFolderInput: {
        driveId: $driveId
        parentId: $parentId
        nextPageToken: $nextPageToken
      }
    ) {
      folders {
        ...GoogleFolder
      }
      parentId
      nextPageToken
    }
  }
  ${GOOGLE_FOLDER_FRAGMENT}
`;
