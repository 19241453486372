import * as Types from '__generated__/globalTypes';
import { GoogleDriveSyncOptionsFragment } from './__generated__/GoogleDriveSyncOptionsModal.gql';
import { GoogleDriveSelectType } from './components/GoogleDriveSelectFormField';

export interface GoogleDriveSyncOptionsModalPropsType {
  onClose(): void;
}

export enum SyncFolderType {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export interface GoogleDriveSyncOptionsFormValuesType
  extends Omit<GoogleDriveSyncOptionsFragment, 'driveId' | 'driveName'> {
  syncFolderType: SyncFolderType;
  newFolderName?: Types.Maybe<string>;
  drive?: GoogleDriveSelectType;
}

export type GoogleDriveSyncOptionsFormSubmitHandler = (
  values: Omit<GoogleDriveSyncOptionsFormValuesType, 'drive'> &
    Required<Pick<GoogleDriveSyncOptionsFormValuesType, 'drive'>> & { newFolderName?: string }
) => Promise<void>;
