import { gql } from '@apollo/client';

export const QBO_SERVICE_ITEM_SELECT_FRAGMENT = gql`
  fragment QboServiceItemSelect on QboServiceItemType {
    id
    name
  }
`;

export const FETCH_QBO_SERVICE_ITEMS = gql`
  query FetchServiceItems {
    getQboResourcesDetails {
      serviceItems {
        ...QboServiceItemSelect
      }
    }
  }
  ${QBO_SERVICE_ITEM_SELECT_FRAGMENT}
`;
