import { gql } from '@apollo/client';

export const FETCH_SIGNATURE_TEMPLATE_ROLES = gql`
  query FetchSignatureTemplateRoles($templateId: ID!) {
    roles: getSignatureTemplate(
      getSignatureTemplateInput: { id: $templateId }
    ) {
      id
      signerRoles {
        id
        roleName
      }
    }
  }
`;
