import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@appclose/ui';

import Date from 'components/common/Date/Date';
import { I18n } from 'i18n';

import { RemindersSchedulePropsType } from './RemindersSchedule.types';
import styles from './RemindersSchedule.module.scss';

export default function RemindersSchedule({
  title,
  schedule,
}: RemindersSchedulePropsType) {
  if (!schedule) {
    return null;
  }

  return (
    <>
      <p className={styles.title}>{title}</p>
      <Table className={styles.remindersSchedule}>
        <TableBody>
          {schedule.map(({ date, sent }, i) => (
            <TableRow key={i} withHover={false}>
              <TableCell>
                <Date value={date} withWeekday />
              </TableCell>
              <TableCell align="right">
                {sent ? (
                  <span className={styles.sent}>
                    <I18n id="modal.invoiceDetails.invoiceReminders.sent" />
                  </span>
                ) : (
                  <span className={styles.scheduled}>
                    <I18n id="modal.invoiceDetails.invoiceReminders.scheduled" />
                  </span>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
