import { Http } from '@appclose/lib';

import { session } from 'controllers/session';

let _http: Http;

export function http(): Http {
  if (!_http) {
    _http = new Http({
      baseURL: '/',
      authorizationHeaderProvider: () => ({
        Authorization: `Bearer ${session.getAccessToken()}`,
      }),
    });
  }

  return _http;
}
