import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import {
  Amount,
  Form,
  FormGroup,
  StaticField,
  ToggleFormField,
  Fieldset,
} from '@appclose/core';

import { InvoiceHistoryTypes } from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import { getContactName } from 'controllers/contact';
import AdditionalInfoFieldset from 'components/form/AdditionalInfoFieldset';
import { I18n, useIntl } from 'i18n';
import { Entities } from 'constants/entities';

import { InvoiceVoidFormPropsType } from './InvoiceVoidForm.types';
import styles from './InvoiceVoidForm.module.scss';
import { InvoiceVoidFormSchema } from './InvoiceVoidForm.schema';

export default function InvoiceVoidForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: InvoiceVoidFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={InvoiceVoidFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({
        values: {
          invoice: { history, contact, invoiceNumber, requestedAmount },
        },
      }) => (
        <>
          <Fieldset title={t('modal.invoiceVoid.form.invoiceDetails')}>
            <FormGrid>
              <StaticField title={t('modal.invoiceVoid.form.amount')}>
                <Amount value={requestedAmount} />
              </StaticField>
              <StaticField title={t('modal.invoiceVoid.form.invoiceSent')}>
                <Date
                  value={
                    history.find(
                      ({ status }) => status === InvoiceHistoryTypes.SENT
                    )?.date
                  }
                />
              </StaticField>
              <StaticField title={t('modal.invoiceVoid.form.contact')}>
                {getContactName(contact)}
              </StaticField>
              <StaticField title={t('modal.invoiceVoid.form.referenceId')}>
                {invoiceNumber}
              </StaticField>
            </FormGrid>
          </Fieldset>
          <AdditionalInfoFieldset
            entityType={Entities.VOID_INVOICE}
            className={styles.additionalInfo}
            notesFieldName="voidInvoiceText"
          />
          <FormGroup className={styles.sendEmailToggle}>
            <ToggleFormField
              name="sendEmail"
              label={t('modal.invoiceVoid.form.sendEmail')}
            />
          </FormGroup>
          <ButtonGroup>
            <Button onClick={onCancel}>
              <I18n id="modal.invoiceVoid.form.cancel" />
            </Button>
            <Button type="submit" skin="brand">
              <I18n id="modal.invoiceVoid.form.submit" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
