import { useIntl } from 'i18n';
import { BankAccountClasses } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useBankAccountClasses() {
  const { t } = useIntl();
  const bankAccountClasses: Record<BankAccountClasses, string> = useMemo(
    () => ({
      [BankAccountClasses.OPERATING]: t('enum.bankAccountClasses.operating'),
      [BankAccountClasses.TRUST]: t('enum.bankAccountClasses.trust'),
    }),
    [t]
  );

  const bankAccountClassesOptions: {
    label: string;
    value: BankAccountClasses;
  }[] = useMemo(
    () =>
      Object.entries(bankAccountClasses).map(([value, label]) => ({
        value: value as BankAccountClasses,
        label,
      })),
    [bankAccountClasses]
  );

  const getBankAccountClassLabel = useCallback(
    (bankAccountClass?: BankAccountClasses | null) => {
      if (!bankAccountClass) {
        return '';
      }

      return bankAccountClasses[bankAccountClass];
    },
    [bankAccountClasses]
  );

  const getBankAccountClassLabelWithAccount = useCallback(
    (bankAccountClass?: BankAccountClasses | null) => {
      if (!bankAccountClass) {
        return '';
      }

      return t('hook.useBankAccountClasses.accountLabel', {
        bankAccountClass: getBankAccountClassLabel(bankAccountClass),
      });
    },
    [getBankAccountClassLabel, t]
  );

  return {
    bankAccountClassesOptions,
    getBankAccountClassLabel,
    getBankAccountClassLabelWithAccount,
  };
}
