import { gql } from '@apollo/client';

export const FETCH_PAYMENT = gql`
  query FetchPayment($id: ID!) {
    payment: getPayment(id: $id) {
      amount
      id
      paymentDate
      paymentMethod
      status
      internalMemo
      contact {
        id
        name
      }
      refundPayment {
        id
        amount
      }
      truncatedPAN
      refundNumber
      qboSyncState
    }
  }
`;
