import React from 'react';

import ExternalSupportLink from 'components/common/ExternalSupportLink';

export const VALUE_RESOLVERS = {
  supportPage: () => <ExternalSupportLink />,
  supportEmail: (customText?: React.ReactNode[]) => (
    <ExternalSupportLink isEmail customText={customText as string[]} />
  ),
};
