import { gql } from '@apollo/client';

export const FETCH_PAYMENT_PDF = gql`
  query fetchPaymentPdf($id: ID!) {
    pdf: getReceiptPDF(id: $id) {
      url
    }
  }
`;

export const FETCH_REFUND_PDF = gql`
  query fetchRefundPdf($id: ID!) {
    pdf: getRefundReceiptPDF(id: $id) {
      data
      mimetype
      name
    }
  }
`;

export const FETCH_CREDIT_MEMO_PDF = gql`
  query fetchCreditMemoPdf($id: ID!) {
    pdf: getCreditMemoPDF(id: $id) {
      data
      mimetype
      name
    }
  }
`;
