import { gql } from '@apollo/client';

export const FETCH_INVOICE = gql`
  query FetchInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      id
      contact {
        id
        firstName
        lastName
        name
        email
      }
      history {
        date
        status
      }
      internalMemo
      invoiceNumber
      requestedAmount
    }
  }
`;

export const VOID_INVOICE = gql`
  mutation VoidInvoice($createVoidInvoiceInput: CreateVoidInvoiceInput!) {
    createVoidInvoice(createVoidInvoiceInput: $createVoidInvoiceInput) {
      id
      status
      voidInvoice {
        id
      }
      history {
        date
        status
      }
    }
  }
`;
