import { Checkbox, CheckboxType } from '@appclose/ui';

import styles from './PrimaryCheckbox.module.scss';

export function PrimaryCheckbox(props: CheckboxType) {
  return (
    <Checkbox
      {...props}
      className={styles.checkbox}
      labelClassName={styles.label}
    />
  );
}
