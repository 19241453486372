import React from 'react';
import classnames from 'classnames';
import { Flex, Button } from '@appclose/ui';
import { ViewIcon } from '@appclose/icons';

import { InvoiceStatusesTypes } from '__generated__/globalTypes';
import { isReceivePaymentAllowed } from 'controllers/invoice';
import { I18n } from 'i18n';
import PoweredBy from 'components/common/PoweredBy';

import { FooterPropsType } from './Footer.types';
import styles from './Footer.module.scss';

export default function Footer({
  mode = 'embedded',
  isMinified = false,
  onViewButtonClick,
  invoice: { status },
  children,
}: FooterPropsType) {
  const receivePaymentAllowed = isReceivePaymentAllowed(status);
  const publicMode = ['payment', 'email'].includes(mode);

  return !isMinified ? (
    <div className={styles.footer}>
      {children}
      {!children && publicMode && receivePaymentAllowed && (
        <span className={styles.noteText}>
          <I18n id="invoiceInfo.footer.paymentLimit" />
        </span>
      )}
      {!children && (
        <Flex
          alignItems="flex-start"
          justify="space-between"
          className={styles.invoiceFooter}
        >
          <div>
            {mode === 'email' && receivePaymentAllowed && (
              <Button className={styles.notClickable} skin="brand">
                <I18n id="invoiceInfo.footer.button.reviewAndPay" />
              </Button>
            )}
            {(mode === 'email' || mode === 'payment') &&
              status === InvoiceStatusesTypes.VOIDED && (
                <p
                  className={classnames(styles.voidedMessage, {
                    [styles.voidedMessageEmail]: mode === 'email',
                  })}
                >
                  <I18n id="invoiceInfo.footer.invoiceCanceled" />
                </p>
              )}
            {status === InvoiceStatusesTypes.PAID && (
              <p className={styles.noteText}>
                <I18n id="invoiceInfo.footer.invoicePaid" />
              </p>
            )}
            {mode === 'embedded' &&
              onViewButtonClick &&
              status !== InvoiceStatusesTypes.VOIDED && (
                <Button
                  className={classnames(styles.invoiceDetailsButton, {
                    [styles.notClickable]: !onViewButtonClick,
                  })}
                  skin="shell"
                  onClick={onViewButtonClick}
                >
                  <Flex alignItems="center">
                    <ViewIcon />{' '}
                    <I18n id="invoiceInfo.footer.button.invoiceDetails" />
                  </Flex>
                </Button>
              )}
          </div>
          {publicMode && <PoweredBy />}
        </Flex>
      )}
    </div>
  ) : null;
}
