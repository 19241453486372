import { gql } from '@apollo/client';


export const FETCH_TRUST_FEES_ACCOUNT_INTENT = gql`
  query FetchTrustFeesAccountIntent {
    getTrustFeesAccountIntent {
      clientSecret
    }
  }
`;
