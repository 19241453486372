import * as Yup from 'yup';
import { EMAIL_VALIDATION_SCHEMA } from '@appclose/core';

import { GenderTypes } from '__generated__/globalTypes';
import { ADDRESSES_FIELDSET_SCHEMA } from 'components/form/AddressesFieldset';
import { PHONE_FIELDSET_SCHEMA } from 'components/form/PhoneFieldset';
import { NOTES_VALIDATION_SCHEMA } from 'schemas/validations/notes';
import {
  CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
  CONTACT_LAST_NAME_VALIDATION_SCHEMA,
} from 'schemas/validations/contact';
import { getI18nErrorMessage } from 'i18n';

import { ContactFieldsetValuesType } from '../../ContactForm.types';
import { FetchProfileQuery } from '../../../../__generated__/ContactModal.gql';

export const ContactFieldsetSchema = Yup.lazy(() =>
  Yup.object().shape<ContactFieldsetValuesType>({
    firstName: CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
    lastName: CONTACT_LAST_NAME_VALIDATION_SCHEMA,
    middleName: Yup.string().nullable(),
    email: EMAIL_VALIDATION_SCHEMA,
    gender: Yup.mixed<GenderTypes>(),
    conflictsCheckCompleted: Yup.boolean().required(),
    engagementHasBeenSigned: Yup.boolean().required(),
    w9FormSentToClient: Yup.boolean().required(),
    taxable: Yup.boolean().required(),
    addresses: ADDRESSES_FIELDSET_SCHEMA.nullable(),
    phoneNumbers: PHONE_FIELDSET_SCHEMA.nullable(),
    notes: NOTES_VALIDATION_SCHEMA,
    originatingAttorney: Yup.object<FetchProfileQuery['getProfile']>().required(
      getI18nErrorMessage(
        'modal.contact.form.contact.error.originatingAttorney'
      )
    ),
  })
);
