import React from 'react';

import { I18n } from 'i18n';
import Label from 'components/common/Label';
import Date from 'components/common/Date';

import { InvoiceDateAndPaymentStatusPropsType } from './InvoiceDateAndPaymentStatus.types';

export default function InvoiceDateAndPaymentStatus({
  dueDate,
  missedPaymentQty,
  overdueDayQty,
}: InvoiceDateAndPaymentStatusPropsType) {
  if (overdueDayQty) {
    return (
      <Label theme="warning">
        <I18n
          id="invoiceDateAndPaymentStatus.overdue"
          values={{ days: overdueDayQty }}
        />
      </Label>
    );
  }

  if (missedPaymentQty) {
    return (
      <Label theme="info">
        <I18n
          id={
            missedPaymentQty > 1
              ? 'invoiceDateAndPaymentStatus.missedPayments'
              : 'invoiceDateAndPaymentStatus.missedPayment'
          }
          values={{ payments: missedPaymentQty }}
        />
      </Label>
    );
  }

  return <Date value={dueDate} />;
}
