import { gql } from '@apollo/client';

import { CONTACT_SELECT_FRAGMENT } from 'components/fields/ContactSelectFormField';

export const FETCH_CONTACT = gql`
  query FetchContact($id: ID!) {
    contact: getContact(id: $id) {
      ...ContactSelect
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;

export const FETCH_INVOICE = gql`
  query FetchInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      id
      invoiceNumber
      createdAt
      dueDate
      contact {
        ...ContactSelect
      }
      balanceDue
      status
      destinationAccount
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;

export const CREATE_PAYMENT_INTENT = gql`
  mutation CreatePaymentIntent($paymentIntent: CreatePaymentIntentInput!) {
    createPaymentIntent(createPaymentIntentInput: $paymentIntent) {
      clientSecret
      stripeAccountId
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($payment: CreatePaymentInput!) {
    createPayment(createPaymentInput: $payment) {
      id
      invoices {
        id
        status
        balanceDue
        paidAmount
        invoiceNumber
        history {
          date
          status
          partialPayment {
            paymentAmount
            dueAmount
          }
        }
      }
    }
  }
`;
