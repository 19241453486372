import React from 'react';
import { SelectFormField } from '@appclose/core';

import { AttorneysQtySelectFormFieldPropsType } from './AttorneysQtySelectFormField.types';
import useFirmSizes from 'hooks/useFirmSizes';

export default function AttorneysQtySelectFormField({
  name = 'attorneysQty',
  label = 'Number of attorneys',
  placeholder = 'Select number of attorneys',
}: AttorneysQtySelectFormFieldPropsType) {
  const { firmSizesOptions } = useFirmSizes();

  return (
    <SelectFormField
      name={name}
      label={label}
      placeholder={placeholder}
      options={firmSizesOptions}
    />
  );
}
