import React, { ReactNode } from 'react';
import { useIsDesktopDevice } from '@appclose/core';
import {
  EditIcon,
  CreditMemoIcon,
  VoidIcon,
  ViewIcon,
  InvoiceIcon,
  EmailIcon,
  ReceiveIcon,
} from '@appclose/icons';

import { InvoiceHistoryTypes } from '__generated__/globalTypes';
import Date from 'components/common/Date';

import InvoiceHistoryItemDescription from './components/InvoiceHistoryItemDescription';
import { InvoiceHistoryItemPropsType } from './InvoiceHistoryItem.types';
import styles from './InvoiceHistoryItem.module.scss';

const InvoiceHistoryIcons: Record<InvoiceHistoryTypes, ReactNode> = {
  [InvoiceHistoryTypes.CREATED]: <InvoiceIcon />,
  [InvoiceHistoryTypes.SENT]: <EmailIcon />,
  [InvoiceHistoryTypes.VIEWED]: <ViewIcon />,
  [InvoiceHistoryTypes.PAID]: <ReceiveIcon />,
  [InvoiceHistoryTypes.VOIDED]: <VoidIcon />,
  [InvoiceHistoryTypes.PARTIALLY_PAID]: <ReceiveIcon />,
  [InvoiceHistoryTypes.CREDIT_MEMO]: <CreditMemoIcon />,
  [InvoiceHistoryTypes.EDIT]: <EditIcon />,
  [InvoiceHistoryTypes.REFUNDED]: <></>,
};

export default function InvoiceHistoryItem({
  historyItem,
  isFirstSent,
}: InvoiceHistoryItemPropsType) {
  const isDesktop = useIsDesktopDevice();
  const { status, date } = historyItem;
  const historyContent = (
    <>
      <Date className={styles.date} value={date} />
      <div className={styles.description}>
        <InvoiceHistoryItemDescription
          historyItem={historyItem}
          isFirstSent={isFirstSent}
        />
      </div>
    </>
  );

  return (
    <li className={styles.invoiceHistoryItem}>
      <div className={styles.statusIcon}>{InvoiceHistoryIcons[status]}</div>
      {isDesktop ? historyContent : <div>{historyContent}</div>}
    </li>
  );
}
