// @ts-ignore
import HelloSignEmbedded from 'hellosign-embedded';
import { isDevelopmentMode } from '@appclose/lib';

import { HELLOSIGN_CLIENT_ID } from 'constants/env';

import { HelloSignErrorData, HelloSignEvents } from './HelloSign.types';

export default class HelloSign {
  private readonly client: HelloSignEmbedded;

  constructor() {
    if (!HELLOSIGN_CLIENT_ID) {
      throw new Error(
        'The REACT_APP_HELLOSIGN_CLIENT_ID process env variable is required'
      );
    }

    this.client = new HelloSignEmbedded();
  }

  public open(url: string, options?: { container: HTMLElement }) {
    this.client.open(url, {
      clientId: HELLOSIGN_CLIENT_ID,
      debug: isDevelopmentMode(),
      skipDomainVerification: isDevelopmentMode(),
      container: options?.container,
      whiteLabeling: {
        page_background_color: '#f8f9fa',
        header_background_color: '#1b1d1e',
        text_color1: '#989fa3',
        text_color2: '#ffffff',
        link_color: '#02bed0',
        primary_button_color: '#02bed0',
        primary_button_text_color: '#ffffff',
        primary_button_color_hover: '#02bed0',
        primary_button_text_color_hover: '#ffffff',
        secondary_button_color: '#ffffff',
        secondary_button_text_color: '#1b1d1e',
        secondary_button_color_hover: '#1b1d1e',
        secondary_button_text_color_hover: '#20c2d2',
      },
    });
  }

  public on(
    event: HelloSignEvents,
    callback: (data?: HelloSignErrorData) => void
  ) {
    this.client.on(event, callback);
  }

  public off(event: HelloSignEvents, callback?: Function) {
    this.client.off(event, callback);
  }

  public onceOn(event: HelloSignEvents, callback: Function) {
    this.client.on(event, callback);

    this.client.on(HelloSignEvents.CLOSE, () => {
      this.client.off(event, callback);
    });
  }
}
