import { dateManager } from '@appclose/core';
import {
  BankAccountClasses,
  PaymentMethodsTypes,
  PaymentStatuses,
  PaymentType,
  TransactionTypes,
} from '__generated__/globalTypes';
import { openModal } from 'controllers/modal';
import {
  CREDIT_MEMO_DETAILS_MODAL,
  PAYMENT_DETAILS_MODAL,
  PAYMENT_REFUND_DETAILS_MODAL,
} from 'constants/modals';

export function isOnlinePayment({
  paymentMethod,
}: Pick<PaymentType, 'paymentMethod'>) {
  return (
    paymentMethod === PaymentMethodsTypes.ACH ||
    paymentMethod === PaymentMethodsTypes.CC
  );
}

export function isPaymentRefundable(payment: Pick<
  PaymentType,
  'status' | 'type' | 'createdAt' | 'amount' | 'refundAmount' | 'paymentMethod'
> & {
  bankAccount: Pick<PaymentType['bankAccount'], 'class'>;
}) {
  return (
    payment.status === PaymentStatuses.CLEARED && 
    payment.amount > payment.refundAmount &&
    payment.type === TransactionTypes.PAYMENT &&
    payment.paymentMethod !== PaymentMethodsTypes.TRANSFER_FROM_TRUST &&
    payment.bankAccount.class === BankAccountClasses.OPERATING &&
    dateManager()
      .parse(payment.createdAt)
      .isAfter(dateManager().parse().subtract(1, 'year'))
  );
}

export function openPaymentDetails(id: string, type: TransactionTypes) {
  switch (type) {
    case TransactionTypes.PAYMENT:
      openModal(PAYMENT_DETAILS_MODAL, { id });
      break;
    case TransactionTypes.CREDIT_MEMO:
      openModal(CREDIT_MEMO_DETAILS_MODAL, { id });
      break;
    case TransactionTypes.REFUND:
      openModal(PAYMENT_REFUND_DETAILS_MODAL, { id });
      break;
  }
}

export function truncateCardNumber(cardNumber: string) {
  return cardNumber.substring(cardNumber.length - 4) || undefined;
}
