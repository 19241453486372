export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const YEAR_FORMAT = 'YYYY';
export const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const USER_DATE_FORMAT = 'MMM DD, YYYY';
export const DISPLAY_DATE_FORMAT = 'MM.DD.YYYY';

export enum MONTHS {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

export enum WEEK_DAYS {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const MONTH_NAMES = {
  [MONTHS.JANUARY]: 'january',
  [MONTHS.FEBRUARY]: 'february',
  [MONTHS.MARCH]: 'march',
  [MONTHS.APRIL]: 'april',
  [MONTHS.MAY]: 'may',
  [MONTHS.JUNE]: 'june',
  [MONTHS.JULY]: 'july',
  [MONTHS.AUGUST]: 'august',
  [MONTHS.SEPTEMBER]: 'september',
  [MONTHS.OCTOBER]: 'october',
  [MONTHS.NOVEMBER]: 'november',
  [MONTHS.DECEMBER]: 'december',
};

export const WEEK_DAYS_NAMES = {
  [WEEK_DAYS.MONDAY]: 'monday',
  [WEEK_DAYS.TUESDAY]: 'tuesday',
  [WEEK_DAYS.WEDNESDAY]: 'wednesday',
  [WEEK_DAYS.THURSDAY]: 'thursday',
  [WEEK_DAYS.FRIDAY]: 'friday',
  [WEEK_DAYS.SATURDAY]: 'saturday',
  [WEEK_DAYS.SUNDAY]: 'sunday',
};
