import * as Yup from 'yup';

import { getI18nErrorMessage } from '../../i18n';

export const getNameValidationSchema = (
  nameType: 'First' | 'Last' | 'Entity' | 'Name',
  max = 30,
  min = 2
) => {
  let schema = Yup.string()
    .nullable()
    .max(max, ({ max }) => {
      switch (nameType) {
        case 'First':
          return getI18nErrorMessage('core.error.person.firstName.maxLength', {
            max,
          });
        case 'Last':
          return getI18nErrorMessage('core.error.person.lastName.maxLength', {
            max,
          });
        case 'Entity':
          return getI18nErrorMessage('core.error.entity.name.maxLength', {
            max,
          });
        case 'Name':
          return getI18nErrorMessage('core.error.name.maxLength', {
            max,
          });
      }
    })
    .min(min, ({ min }) => {
      switch (nameType) {
        case 'First':
          return getI18nErrorMessage('core.error.person.firstName.minLength', {
            min,
          });
        case 'Last':
          return getI18nErrorMessage('core.error.person.lastName.minLength', {
            min,
          });
        case 'Entity':
          return getI18nErrorMessage('core.error.entity.name.minLength', {
            min,
          });
        case 'Name':
          return getI18nErrorMessage('core.error.name.minLength', {
            min,
          });
      }
    });

  switch (nameType) {
    case 'First':
      return schema.matches(
        /^[A-Za-z .]+$/,
        getI18nErrorMessage('core.error.name.letters')
      );
    case 'Last':
      return schema.matches(
        /^[A-Za-z .]+$/,
        getI18nErrorMessage('core.error.name.letters')
      );
    case 'Name':
      return schema.matches(
        /^[A-Za-z .]+$/,
        getI18nErrorMessage('core.error.name.letters')
      );

    default:
      return schema;
  }
};

export const PROFILE_FIRST_NAME_VALIDATION_SCHEMA = getNameValidationSchema(
  'First'
).required(getI18nErrorMessage('core.error.person.firstName.required'));

export const PROFILE_LAST_NAME_VALIDATION_SCHEMA = getNameValidationSchema(
  'Last'
).required(getI18nErrorMessage('core.error.person.lastName.required'));
