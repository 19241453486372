import {
  AddressInput,
  AddressTypes,
  UsaStates,
} from '__generated__/globalTypes';

export const INITIAL_ADDRESS: AddressInput = {
  type: AddressTypes.PRIMARY,
  city: '',
  address1: '',
  address2: '',
  state: (undefined as any) as UsaStates,
  zipCode: '',
};
