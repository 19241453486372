import { gql } from '@apollo/client';

export const INVOICE_HISTORY_FRAGMENT = gql`
  fragment InvoiceHistory on InvoiceType {
    history {
      date
      status
      client {
        email
      }
      creditMemo {
        id
        creditAmount
        requestedAmount
        balanceDue
      }
      partialPayment {
        dueAmount
        paymentAmount
      }
    }
  }
`;
