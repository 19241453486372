import { gql } from '@apollo/client';

export const FETCH_DATA = gql`
  query FetchData($id: ID!) {
    invoice: getInvoice(id: $id) {
      id
      invoiceNumber
      contact {
        id
        name
      }
      paymentReminder {
        overdueReminder {
          emailText
          ongoingOverdueDayQty
          overdueDayQty
          stopToRemindAfterDayQty
          stopToRemindAfterSentQty
        }
        prepaymentReminder {
          dayQtyBefore
          emailText
        }
      }
    }
  }
`;

export const SCHEDULE_INVOICE_REMINDERS = gql`
  mutation ScheduleInvoiceReminders(
    $reminders: ScheduleInvoiceRemindersInput!
  ) {
    scheduleInvoiceReminders(scheduleInvoiceRemindersInput: $reminders) {
      id
      paymentReminder {
        overdueReminder {
          emailText
          ongoingOverdueDayQty
          overdueDayQty
          stopToRemindAfterDayQty
          stopToRemindAfterSentQty
          reminderSchedule {
            date
            sent
          }
        }
        prepaymentReminder {
          dayQtyBefore
          emailText
          reminderSchedule {
            date
            sent
          }
        }
      }
    }
  }
`;
