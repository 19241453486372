import React, { useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Offset } from '@appclose/ui';
import { ChevronRightIcon } from '@appclose/icons';
import { Ellipsis, useIsTabletDevice } from '@appclose/core';

import { I18n } from 'i18n';

import { BreadcrumbsPropsType, BreadcrumbType } from './Breadcrumbs.types';
import styles from './Breadcrumbs.module.scss';

export default function Breadcrumbs({
  breadcrumbs,
  loading,
  rootFolderId,
  rootFolderName,
  depth = 2,
  chevron = () => (
    <ChevronRightIcon width={16} height={16} className={styles.chevron} />
  ),
  className,
  onOpenFolder,
}: BreadcrumbsPropsType) {
  const isTabletDevice = useIsTabletDevice();
  const middleCrumbIndex = useMemo(() => Math.round(depth / 2), [depth]);
  const [cachedBreadCrumbs, setCachedBreadCrumbs] = useState<BreadcrumbType[]>(
    []
  );
  const partOfBreadcrumbs = useMemo(
    () =>
      breadcrumbs.length > depth
        ? [breadcrumbs[0], ...breadcrumbs.slice(depth * -1)]
        : breadcrumbs,
    [breadcrumbs, depth]
  );

  useEffect(() => {
    if (!loading && !isEqual(cachedBreadCrumbs, partOfBreadcrumbs)) {
      setCachedBreadCrumbs(partOfBreadcrumbs);
    }
  }, [cachedBreadCrumbs, loading, partOfBreadcrumbs]);

  return (
    <Offset bottom={!isTabletDevice ? 16 : undefined} className={className}>
      <ul className={styles.list}>
        <li onClick={() => onOpenFolder(rootFolderId)}>
          <Ellipsis>
            {rootFolderName || <I18n id="breadcrumbs.allFiles" />}
          </Ellipsis>
        </li>
        {cachedBreadCrumbs.map(({ id, name, parentId }, i, arr) => {
          const isLastCrumb = arr.length === i + 1;
          const isMiddleCrumb = !isLastCrumb && middleCrumbIndex === i;

          return (
            <React.Fragment key={id}>
              <li>{chevron({ id, name, parentId })}</li>
              <li onClick={!isLastCrumb ? () => onOpenFolder(id) : undefined}>
                <Ellipsis>{isMiddleCrumb ? '...' : name}</Ellipsis>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    </Offset>
  );
}
