import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'formik';

import { InputField } from '../../../../..';
import { getFieldError } from '../../../../..';

import {
  PercentageFormFieldPropsType,
  PercentageFormFieldWrapperType,
} from './PercentageFormField.types';

function PercentageFormFieldWrapper({
  field: { value, ...field },
  form,
  min,
  max,
  onChange,
  denyFractional = false,
  ...rest
}: PercentageFormFieldWrapperType) {
  const [isLastCharDot, setIsLastCharDot] = useState(false);

  useEffect(() => {
    if (value > 100) {
      form.setFieldValue(field.name, 100, true);
    }
  }, [field.name, form, value]);

  const formatPercentageValue = useCallback(
    (value: string | number | undefined, isLastCharDot: boolean) =>
      value !== 0 && !value ? '' : `${value}${isLastCharDot ? '.' : ''} %`,
    []
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const { selectionStart, selectionEnd } = event.target;

      const strValue = value
        .replace(/[^0-9.]/g, '')
        .replace(/([0-9]*\.[0-9]*).*/g, '$1');

      const isLastCharDot = !denyFractional
        ? strValue[strValue.length - 1] === '.'
        : false;
      const newValue = strValue
        ? denyFractional
          ? parseInt(strValue, 10)
          : Number(strValue)
        : 0;
      const newValueLength =
        newValue.toString().length + (isLastCharDot ? 1 : 0);

      const isCursorOutOfValue =
        newValueLength > 0 &&
        (newValueLength <= (selectionStart || 0) ||
          newValueLength <= (selectionEnd || 0));

      event.target.value = formatPercentageValue(newValue, isLastCharDot);

      if (isCursorOutOfValue) {
        event.target.selectionStart = newValueLength;
        event.target.selectionEnd = newValueLength;
      }

      setIsLastCharDot(isLastCharDot);

      form.setFieldValue(field.name, newValue > 100 ? 100 : newValue, true);
    },
    [field.name, form, formatPercentageValue, denyFractional]
  );

  return (
    <InputField
      {...{ ...field, ...rest }}
      value={formatPercentageValue(value, isLastCharDot)}
      onChange={handleOnChange}
      error={getFieldError(form, field.name)}
    />
  );
}

const PercentageFormField = (props: PercentageFormFieldPropsType) => {
  return <Field {...props} component={PercentageFormFieldWrapper} />;
};

export default PercentageFormField;
