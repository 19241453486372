import { CreateContactMutation } from './__generated__/ContactPopupModal.gql';

export enum ContactPopupModalTabs {
  INDIVIDUAL,
  ENTITY,
}

export type ContactPopupModalPropsType = {
  onComplete(contact: CreateContactMutation['createContact']): void;
  onCancel(): void;
};
