import { DocumentNode } from '@apollo/client';

import { Entities } from 'constants/entities';
import { FileSubscriptionInput } from '__generated__/globalTypes';

import {
  LIST_CONTACT_FILES,
  LIST_INVOICE_FILES,
  LIST_PAYMENT_FILES,
  LIST_SIGNATURE_FILES,
  LIST_SIGNATURE_TEMPLATE_FILES,
  LIST_VOID_INVOICE_FILES,
} from './useListFiles.gql';

export const Queries: Record<Entities, DocumentNode> = {
  [Entities.CONTACT]: LIST_CONTACT_FILES,
  [Entities.INVOICE]: LIST_INVOICE_FILES,
  [Entities.PAYMENT]: LIST_PAYMENT_FILES,
  [Entities.VOID_INVOICE]: LIST_VOID_INVOICE_FILES,
  [Entities.SIGNATURE]: LIST_SIGNATURE_FILES,
  [Entities.SIGNATURE_TEMPLATE]: LIST_SIGNATURE_TEMPLATE_FILES,
};

export const EntityIdsFieldName: Record<
  Entities,
  keyof Omit<FileSubscriptionInput, 'from' | 'to'>
> = {
  [Entities.CONTACT]: 'contactIds',
  [Entities.INVOICE]: 'invoiceIds',
  [Entities.PAYMENT]: 'paymentIds',
  [Entities.VOID_INVOICE]: 'voidInvoiceIds',
  [Entities.SIGNATURE]: 'signatureIds',
  [Entities.SIGNATURE_TEMPLATE]: 'signatureTemplateIds',
};
