import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import { Grid, Flex } from '@appclose/ui';
import { Amount, useIsDesktopDevice } from '@appclose/core';
import { InvoiceIcon, ArrowDownIcon } from '@appclose/icons';

import Block from 'components/common/Block';
import Date from 'components/common/Date';
import InvoiceStatus from 'components/common/InvoiceStatus';
import InvoiceDateAndPaymentStatus from 'components/common/InvoiceDateAndPaymentStatus';
import useBankAccountClasses from 'hooks/useBankAccountClasses';

import { InvoiceWrapperPropsType } from './InvoiceWrapper.types';
import styles from './InvoiceWrapper.module.scss';

export default function InvoiceWrapper({
  children,
  invoice: {
    invoiceNumber,
    createdAt,
    dueDate,
    destinationAccount,
    status,
    overdueDayQty,
    missedPaymentQty,
  },
  payment: { amount },
}: InvoiceWrapperPropsType) {
  const isDesktop = useIsDesktopDevice();
  const [expanded, setExpanded] = useState(false);
  const onExpandClick = useCallback(() => setExpanded(!expanded), [expanded]);
  const { getBankAccountClassLabel } = useBankAccountClasses();

  return (
    <div
      className={classnames(styles.invoiceWrapper, {
        [styles.expanded]: expanded,
      })}
      onClick={onExpandClick}
    >
      <ArrowDownIcon
        className={classnames(styles.corner, {
          [styles.expandedCorner]: expanded,
        })}
      />
      {expanded && children}
      {!expanded && (
        <Block className={styles.block}>
          {isDesktop ? (
            <Grid gap={[24]} align="center" columns="18px repeat(5, auto)">
              <InvoiceIcon />
              <div>
                <p className={styles.invoiceNumber}>{invoiceNumber || ''}</p>
                <Date className={styles.createdAt} value={createdAt} />
              </div>
              <div className={styles.smallText}>
                <InvoiceDateAndPaymentStatus
                  dueDate={dueDate}
                  overdueDayQty={overdueDayQty}
                  missedPaymentQty={missedPaymentQty}
                />
              </div>
              <span className={styles.account}>
                {getBankAccountClassLabel(destinationAccount)}
              </span>
              <Amount strong className={styles.text} value={amount} />
              <div>
                <InvoiceStatus status={status} />
              </div>
            </Grid>
          ) : (
            <Flex alignItems="center" justify="space-between" wrap="wrap">
              <Flex alignItems="center">
                <div>
                  <InvoiceIcon />
                </div>
                <div>
                  <p className={styles.invoiceNumber}>{invoiceNumber || ''}</p>
                  <div className={styles.account}>
                    {getBankAccountClassLabel(destinationAccount)}
                  </div>
                </div>
              </Flex>
              <div>
                <p>
                  <Amount strong className={styles.text} value={amount} />
                </p>
                <div>
                  <InvoiceStatus status={status} />
                </div>
              </div>
            </Flex>
          )}
        </Block>
      )}
    </div>
  );
}
