import React from 'react';
import classnames from 'classnames';
import { Button } from '@appclose/ui';

import { RoundButtonPropsType } from './RoundButton.types';
import styles from './RoundButton.module.scss';

export default function RoundButton({
  size = 52,
  className,
  children,
  skin,
  loading,
  ...rest
}: RoundButtonPropsType) {
  return (
    <Button
      skin="shell"
      loading={loading}
      className={classnames(styles.roundButton, className, {
        [styles.success]: skin === 'success',
        [styles.primary]: skin === 'primary',
        [styles.secondary]: skin === 'secondary',
        [styles.warning]: skin === 'warning',
        [styles.yellow]: skin === 'yellow',
        [styles.void]: skin === 'void',
        [styles.white]: skin === 'white',
        [styles.loading]: loading,
      })}
      style={{ width: size, height: size }}
      {...rest}
    >
      {children}
    </Button>
  );
}
