import { KybInput } from '__generated__/globalTypes';

import {
  FormGroup,
  InputFormField,
  USAStateSelectFormField,
  ZipCodeFormField,
  UpperFirstInputFormField,
  DatePickerFormField,
  PhoneNumberFormField,
  SSNFormField,
  PercentageFormField,
  Hint,
  MediaQueryDesktop,
  MediaQueryMobile,
  Fieldset,
  EnhancedFormArrayField,
  useFormContext,
} from '@appclose/core';

import { I18n, useIntl } from 'i18n';
import FormGrid from 'components/common/FormGrid/FormGrid';
import PrincipalTitleSelectFormField from 'components/fields/PrincipalTitleSelectFormField';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { sanitizeAmount } from 'controllers/amount';

import { BusinessOwnersFieldsetPropsType } from './BusinessOwnersFieldset.types';
import styles from './BusinessOwnersFieldset.module.scss';
import RelationshipTypeSelectFormField from 'components/fields/RelationshipTypeSelectFormField';
import { Text3 } from '@appclose/ui';
import { PersonRelationship } from '__generated__/globalTypes';

export default function BusinessOwnersFieldset({
  name = 'owners',
  limit = 5,
  owners,
  isDisplayField,
  isExecutive,
}: BusinessOwnersFieldsetPropsType) {
  const { t } = useIntl();

  const { values } = useFormContext<KybInput>();

  const BUSINESS_OWNER_HINT = t(
    'form.merchantApplication.businessOwnersFieldset.hint'
  );

  const totalPercentage =
    owners?.reduce(
      (acc, owner) =>
        sanitizeAmount(acc + Number(owner?.ownershipPercentage) || 0),
      0
    ) || 0;

  const showFooterAndAddMore = totalPercentage <= 75;
  const isOwnerProvide = Boolean(isDisplayField) && isExecutive;
  const isOwners = (owners && owners?.length > 1) || false;

  return (
    <div className={styles.businessOwners}>
      <Fieldset
        title={
          <>
            <I18n id="form.merchantApplication.businessOwnersFieldset.controlPersonTitle" />
            <Hint
              iconClassName={styles.hint}
              content={
                <p className={styles.hintContent}>
                  <I18n id="form.merchantApplication.businessOwnersFieldset.controlPersonHint" />
                </p>
              }
            />
          </>
        }
      >
        <FormGroup>
          <FormGrid>
            <UpperFirstInputFormField
              name="controlPerson.firstName"
              label={t(
                'form.merchantApplication.businessOwnersFieldset.firstName'
              )}
              tooltip={BUSINESS_OWNER_HINT}
            />
            <UpperFirstInputFormField
              name="controlPerson.lastName"
              label={t(
                'form.merchantApplication.businessOwnersFieldset.lastName'
              )}
              tooltip={BUSINESS_OWNER_HINT}
            />
            <DatePickerFormField
              name="controlPerson.dateOfBirth"
              label={t(
                'form.merchantApplication.businessOwnersFieldset.dateOfBirth'
              )}
              tooltip={BUSINESS_OWNER_HINT}
              outputDateFormat={SERVER_DATE_FORMAT}
            />
            <SSNFormField
              isFree
              name="controlPerson.ssn"
              label={t('form.merchantApplication.businessOwnersFieldset.ssn')}
              placeholder="XXXX"
            />
            <PhoneNumberFormField name="controlPerson.phoneNumber" />
            <InputFormField
              name="controlPerson.email"
              label={t('form.merchantApplication.businessOwnersFieldset.email')}
            />

            {isDisplayField && (
              <RelationshipTypeSelectFormField
                tooltip={t(
                  'form.merchantApplication.businessOwnersFieldset.relationship.tooltip'
                )}
                name="controlPerson.relationship"
                excludedValues={
                  !!values.controlPerson?.ownershipPercentage
                    ? [PersonRelationship.EXECUTIVE]
                    : []
                }
              />
            )}

            <PrincipalTitleSelectFormField name="controlPerson.title" />

            <PercentageFormField
              disabled={
                values.controlPerson?.relationship ===
                PersonRelationship.EXECUTIVE
              }
              name="controlPerson.ownershipPercentage"
              label={t(
                'form.merchantApplication.businessOwnersFieldset.ownershipPercent'
              )}
              placeholder="0 %"
              denyFractional
            />
          </FormGrid>
        </FormGroup>
        <FormGroup>
          <MediaQueryDesktop>
            <FormGrid>
              <UpperFirstInputFormField
                name="controlPerson.address1"
                label={t(
                  'form.merchantApplication.businessOwnersFieldset.address1'
                )}
              />
              <InputFormField
                name="controlPerson.address2"
                label={t(
                  'form.merchantApplication.businessOwnersFieldset.address2'
                )}
              />
              <InputFormField
                name="controlPerson.city"
                label={t(
                  'form.merchantApplication.businessOwnersFieldset.city'
                )}
              />
              <USAStateSelectFormField
                showPlaceholder="onFocus"
                name="controlPerson.state"
              />
              <ZipCodeFormField name="controlPerson.zip" />
            </FormGrid>
          </MediaQueryDesktop>
        </FormGroup>
      </Fieldset>
      {isDisplayField && (
        <EnhancedFormArrayField
          name={name}
          addButtonLabel={t(
            'form.merchantApplication.businessOwnersFieldset.addButton'
          )}
          allowAddNew={showFooterAndAddMore}
          deleteButtonPosition="start"
          limit={limit}
          deletable={!isOwnerProvide || isOwners}
          footer={
            showFooterAndAddMore ? (
              <Text3 offset={{ bottom: 12 }}>
                <I18n id="form.merchantApplication.businessOwnersFieldset.footer" />
              </Text3>
            ) : null
          }
        >
          {({ resolveName, index }) => (
            <Fieldset
              title={
                <I18n
                  id="form.merchantApplication.businessOwnersFieldset.businessOwnerTitle"
                  values={{ index: index + 2 }}
                />
              }
            >
              <FormGroup>
                <FormGrid>
                  <UpperFirstInputFormField
                    name={resolveName('firstName')}
                    label={t(
                      'form.merchantApplication.businessOwnersFieldset.firstName'
                    )}
                    tooltip={BUSINESS_OWNER_HINT}
                  />
                  <UpperFirstInputFormField
                    name={resolveName('lastName')}
                    label={t(
                      'form.merchantApplication.businessOwnersFieldset.lastName'
                    )}
                    tooltip={BUSINESS_OWNER_HINT}
                  />
                  <DatePickerFormField
                    name={resolveName('dateOfBirth')}
                    label={t(
                      'form.merchantApplication.businessOwnersFieldset.dateOfBirth'
                    )}
                    tooltip={BUSINESS_OWNER_HINT}
                    outputDateFormat={SERVER_DATE_FORMAT}
                  />
                  <SSNFormField
                    isFree
                    name={resolveName('ssn')}
                    label={t(
                      'form.merchantApplication.businessOwnersFieldset.ssn'
                    )}
                    placeholder="XXXX"
                  />
                  <PhoneNumberFormField name={resolveName('phoneNumber')} />
                  <InputFormField
                    name={resolveName('email')}
                    label={t(
                      'form.merchantApplication.businessOwnersFieldset.email'
                    )}
                  />
                  <PercentageFormField
                    name={resolveName('ownershipPercentage')}
                    label={t(
                      'form.merchantApplication.businessOwnersFieldset.ownershipPercent'
                    )}
                    placeholder="0 %"
                    denyFractional
                  />
                  <PrincipalTitleSelectFormField name={resolveName('title')} />
                </FormGrid>
              </FormGroup>
              <FormGroup>
                <MediaQueryDesktop>
                  <FormGrid>
                    <UpperFirstInputFormField
                      name={resolveName('address1')}
                      label={t(
                        'form.merchantApplication.businessOwnersFieldset.address1'
                      )}
                    />
                    <InputFormField
                      name={resolveName('address2')}
                      label={t(
                        'form.merchantApplication.businessOwnersFieldset.address2'
                      )}
                    />
                    <InputFormField
                      name={resolveName('city')}
                      label={t(
                        'form.merchantApplication.businessOwnersFieldset.city'
                      )}
                    />
                    <USAStateSelectFormField
                      showPlaceholder="onFocus"
                      name={resolveName('state')}
                    />
                    <ZipCodeFormField name={resolveName('zip')} />
                    <br />
                  </FormGrid>
                </MediaQueryDesktop>
                <MediaQueryMobile>
                  <FormGrid>
                    <UpperFirstInputFormField
                      name={resolveName('address1')}
                      label={t(
                        'form.merchantApplication.businessOwnersFieldset.address1'
                      )}
                    />
                    <InputFormField
                      name={resolveName('address2')}
                      label={t(
                        'form.merchantApplication.businessOwnersFieldset.address2'
                      )}
                    />
                    <InputFormField
                      name={resolveName('city')}
                      label={t(
                        'form.merchantApplication.businessOwnersFieldset.city'
                      )}
                    />
                    <USAStateSelectFormField
                      showPlaceholder="onFocus"
                      name={resolveName('state')}
                    />
                    <ZipCodeFormField name={resolveName('zip')} />
                  </FormGrid>
                </MediaQueryMobile>
              </FormGroup>
            </Fieldset>
          )}
        </EnhancedFormArrayField>
      )}
    </div>
  );
}
