// https://github.com/hellosign/hellosign-embedded/wiki/API-Documentation-(v2)#events
export enum HelloSignEvents {
  CANCEL = 'cancel',
  CLOSE = 'close',
  CREATE_TEMPLATE = 'createTemplate',
  DECLINE = 'decline',
  ERROR = 'error',
  FINISH = 'finish',
  MESSAGE = 'message',
  OPEN = 'open',
  READY = 'ready',
  REASSIGN = 'reassign',
  SEND = 'send',
  SIGN = 'sign',
}

// https://github.com/hellosign/hellosign-embedded/wiki/API-Documentation-(v2)#event-error
export enum HelloSignErrorCodes {
  NOTHING_TO_SIGN = 'nothing_to_sign',
}

export interface HelloSignErrorData {
  signatureId: string;
  code: HelloSignErrorCodes;
}
