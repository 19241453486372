import React, { useMemo } from 'react';
import { CoreI18n } from '@appclose/core';

import { VALUE_RESOLVERS } from '../../constants';
import { IntlMessageIdType } from '../../types';
import { I18nPropsType } from './I18n.types';

export default function I18n(props: I18nPropsType) {
  const values = useMemo(
    () => ({
      ...props.values,
      ...VALUE_RESOLVERS,
    }),
    [props.values]
  );

  return <CoreI18n<IntlMessageIdType> id={props.id} values={values} />;
}
