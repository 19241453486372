import { gql } from '@apollo/client';

import { INVOICE_INFO_FRAGMENT } from 'components/common/InvoiceInfo';

export const FETCH_PAYMENT = gql`
  query FetchPayment($id: ID!) {
    getPayment(id: $id) {
      id
      amount
      createdAt
      refundAmount
      type
      status
      paymentDate
      paymentMethod
      status
      internalMemo
      bankAccount {
        id
        class
      }
      contact {
        id
        name
      }
      invoices {
        ...InvoiceInfo
        destinationAccount
        contact {
          isEntity
        }
        transactions {
          id
          amount
          parent {
            id
          }
        }
      }
      truncatedPAN
      qboSyncState
    }
  }
  ${INVOICE_INFO_FRAGMENT}
`;
