import React from 'react';

import { ModalSendEmailPreviewPanelPropsType } from './ModalSendEmailPreviewPanel.types';
import styles from './ModalSendEmailPreviewPanel.module.scss';

export default function ModalSendEmailPreviewPanel({
  children,
}: ModalSendEmailPreviewPanelPropsType) {
  return <div className={styles.emailPanel}>{children}</div>;
}
