import { useState, useEffect, useRef } from 'react';
import { I18n } from 'i18n';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';

import { Loader } from '@appclose/core';
import { Button, ButtonGroup, Flex } from '@appclose/ui';
import { ArrowLeftMediumIcon } from '@appclose/icons';

import { STRIPE_API_KEY } from 'constants/env';
import notification from 'controllers/notification';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';

import { ConfirmPaymentIntentModalPropsType } from './ConfirmPaymentIntentModal.types';

export default function ConfirmPaymentIntentModal({
  clientSecret,
  stripeAccountId,
  onSuccess,
  onCancel,
}: ConfirmPaymentIntentModalPropsType) {
  const stripeRef = useRef<Stripe | null>(null);
  const elementsRef = useRef<StripeElements | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [confirmInProgress, setConfirmInProgress] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const handleSubmit = async () => {
    if (!stripeRef.current || !elementsRef.current) {
      return;
    }

    const { error: submitError } = await elementsRef.current.submit();

    if (submitError) {
      return;
    }

    setConfirmInProgress(true);
    const res = await stripeRef.current.confirmPayment({
      elements: elementsRef.current,
      clientSecret,
      confirmParams: {},
      redirect: 'if_required',
    });
    setConfirmInProgress(false);

    if (res.error) {
      notification().error(new Error(res.error.message));

      return;
    }

    onSuccess();
  };

  useEffect(() => {
    loadStripe(STRIPE_API_KEY, { stripeAccount: stripeAccountId }).then(
      (stripe) => {
        stripeRef.current = stripe;
        elementsRef.current = stripe!.elements({ clientSecret });
        const paymentElement = elementsRef.current.create('payment');
        paymentElement.on('ready', () => setIsLoading(false));

        paymentElement.on('change', (event) => {
          setSubmitDisabled(!event.complete);
        });

        paymentElement.mount('#payment-form');
      }
    );
  }, [clientSecret, stripeAccountId]);

  return (
    <ModalPage closeIcon={<ArrowLeftMediumIcon />} onClose={onCancel}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.confirmPaymentIntent.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <Flex
          style={{ minHeight: 300 }}
          direction="column"
          justify="space-between"
        >
          <div id="payment-form" />
          {isLoading && <Loader />}
          {isLoading ? null : (
            <ButtonGroup>
              <Button
                type="submit"
                skin="brand"
                loading={confirmInProgress}
                disabled={submitDisabled}
                onClick={handleSubmit}
              >
                <I18n id="modal.confirmPaymentIntent.actions.submit" />
              </Button>
            </ButtonGroup>
          )}
        </Flex>
      </ModalPageContent>
    </ModalPage>
  );
}
