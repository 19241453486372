import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Caption3, Flex, Text4 } from '@appclose/ui';
import { AttachmentIcon, ExclamationMediumIcon } from '@appclose/icons';
import { fileSize, Loader } from '@appclose/core';

import { AttachedFilePropsType, AttachedFileType } from './AttachedFile.types';
import styles from './AttachedFile.module.scss';

export default function AttachedFile({
  file,
  processing,
  failed,
  className,
  children,
  onClick,
}: AttachedFilePropsType) {
  const [fileInfo, setFileInfo] = useState<
    Partial<AttachedFileType> | undefined
  >(file instanceof File ? undefined : file);

  useEffect(() => {
    if (file instanceof File) {
      setFileInfo({
        name: file.name,
        size: file.size,
      });
    } else {
      setFileInfo(file);
    }
  }, [file]);

  const AttachComponent = !onClick && fileInfo?.url ? 'a' : 'div';
  const IconComponent = processing
    ? Loader
    : failed ? ExclamationMediumIcon : AttachmentIcon;

  return (
    <AttachComponent
      download={fileInfo?.name}
      className={classnames(styles.attachedFile, className, {
        [styles.clickable]: onClick,
        [styles.processing]: processing,
        [styles.failed]: failed,
      })}
      href={!onClick && fileInfo?.url ? fileInfo?.url : undefined}
      onClick={onClick}
    >
      <IconComponent className={styles.icon} />
      <Flex wrap="wrap" alignItems="center">
        <Text4
          as="span"
          weight="semibold"
          offset={{ right: 8 }}
          className={styles.fileName}
        >
          {fileInfo?.name}
        </Text4>
        <Caption3 as="span" color="secondary" className={styles.fileSize}>
          {fileSize().format(fileInfo?.size || 0)}
        </Caption3>
      </Flex>
      {children}
    </AttachComponent>
  );
}
