import { gql } from '@apollo/client';

import { INVOICE_INFO_FRAGMENT } from 'components/common/InvoiceInfo';
import { FIRM_INFO_FRAGMENT } from 'components/common/FirmInfo';

export const FETCH_CREDIT_MEMO = gql`
  query FetchCreditMemo($id: ID!) {
    creditMemo: getPayment(id: $id) {
      id
      amount
      memoNumber
      memoInvoice {
        ...InvoiceInfo
        contact {
          email
          isEntity
          billingContacts {
            id
            firstName
            lastName
            email
            type
          }
        }
      }
    }
    firm: getFirm {
      ...FirmInfo
    }
  }
  ${INVOICE_INFO_FRAGMENT}
  ${FIRM_INFO_FRAGMENT}
`;

export const SEND_CREDIT_MEMO = gql`
  mutation SendCreditMemo($sendMemoInput: SendCreditMemoInput!) {
    sendCreditMemo(sendMemoInput: $sendMemoInput) {
      id
    }
  }
`;
