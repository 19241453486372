import React from 'react';
import { SelectFormField } from '@appclose/core';

import { DUE_DAYS_OPTIONS } from './DueDaysSelectFormField.constants';
import { DueDaysSelectFormFieldPropsType } from './DueDaysSelectFormField.types';

export default function DueDaysSelectFormField({
  name = 'dueDays',
  label = 'Payment terms',
  ...props
}: DueDaysSelectFormFieldPropsType) {
  return (
    <SelectFormField
      name={name}
      label={label}
      {...props}
      options={DUE_DAYS_OPTIONS}
    />
  );
}
