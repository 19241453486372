import React, { ComponentType, lazy, Suspense } from 'react';

import Loader from '../../common/Loader';

import { LoadableComponentType } from './loadable.types';

export default function loadable<TProps extends Object>(
  component: LoadableComponentType<TProps>
): ComponentType<TProps> {
  const Component = lazy<any>(() => component());

  return (props: TProps) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
}
