import { gql } from '@apollo/client';

export const PUBLIC_FIRM_INFO_FRAGMENT = gql`
  fragment PublicFirmInfo on PublicFirmType {
    address {
      address1
      address2
      city
      state
      type
      zipCode
    }
    phoneNumber {
      number
    }
    logo {
      original
    }
    name
    website
  }
`;

export const FIRM_INFO_FRAGMENT = gql`
  fragment FirmInfo on FirmType {
    id
    name
    website
    phoneNumber {
      number
    }
    address {
      address1
      address2
      city
      state
      type
      zipCode
    }
    logo {
      original
    }
  }
`;
