import React from 'react';
import { Grid, Flex, Button } from '@appclose/ui';
import {
  ExternalLink,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  dateManager,
} from '@appclose/core';

import Block from 'components/common/Block';
import FirmInfo from 'components/common/FirmInfo';
import InvoiceInfo from 'components/common/InvoiceInfo';
import AttachedFile from 'components/common/AttachedFile';
import { I18n } from 'i18n';

import { EmailPreviewPropsType } from './EmailPreview.types';
import styles from './EmailPreview.module.scss';

export default function EmailPreview({
  text,
  invoice,
  firm,
  files,
  includeAccountStatementLink,
}: EmailPreviewPropsType) {
  return (
    <Block as="section" className={styles.preview}>
      <Grid columns="1fr" gap={[0, 40]}>
        <FirmInfo firm={firm} />
        <p className={styles.text}>{text}</p>
        {invoice && <InvoiceInfo invoice={invoice} mode="email" />}
        {includeAccountStatementLink && (
          <Block className={styles.accountStatementBlock}>
            <Flex alignItems="center" direction="column">
              <p className={styles.accountStatementText}>
                Please follow the link below to see your account summary
              </p>
              <Button className={styles.notClickable} skin="success">
                Go to account statement
              </Button>
            </Flex>
          </Block>
        )}
        {files && files.length > 0 && (
          <Flex direction="column">
            {files.map((file, i) => (
              <AttachedFile
                key={i}
                className={styles.file}
                file={{ ...file, url: '' }}
              />
            ))}
          </Flex>
        )}
      </Grid>
      <Flex
        as="footer"
        className={styles.footer}
        alignItems="center"
        justify="space-between"
      >
        <span className={styles.noteText}>
          <I18n
            id="emailPreview.allRightsReserved"
            values={{ year: dateManager().parse().format('YYYY') }}
          />
        </span>
        <div className={styles.footerLinks}>
          <ExternalLink link={PRIVACY_POLICY_ROUTE}>
            <I18n id="emailPreview.privacy" />
          </ExternalLink>
          <ExternalLink link={TERMS_OF_SERVICE_ROUTE}>
            <I18n id="emailPreview.terms" />
          </ExternalLink>
        </div>
      </Flex>
    </Block>
  );
}
