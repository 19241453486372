import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cancelConfirmAction,
  confirmConfirmAction,
  getConfirmSelector,
} from '../../..';
import { Confirm } from '../Confirm';
import { ConfirmProviderContext } from './ConfirmProvider.context';

export default function ConfirmProvider() {
  const dispatch = useDispatch();

  const [state, setState] = useState<Record<string, any>>({});

  const {
    isOpen,
    isProcessing,
    okButtonSkin,
    name,
    title,
    content,
    error,
    className,
    overlayClassName,
    contentClassName,
    okButtonTitle,
    cancelButtonTitle,
  } = useSelector(getConfirmSelector);

  const handleOnConfirm = () => {
    dispatch(confirmConfirmAction(state));
  };

  const handleOnCancel = () => {
    dispatch(cancelConfirmAction());
  };

  const onChangeState = useCallback(
    (next: Record<string, any>) => setState((prev) => ({ ...prev, ...next })),
    []
  );

  useEffect(() => {
    if (!isOpen) {
      setState({});
    }
  }, [isOpen]);

  return (
    <ConfirmProviderContext.Provider value={{ onChangeState }}>
      {isOpen ? (
        <Confirm
          content={content}
          error={error}
          isProcessing={isProcessing}
          name={name}
          cancelButtonTitle={cancelButtonTitle}
          okButtonSkin={okButtonSkin}
          okButtonTitle={okButtonTitle}
          className={className}
          overlayClassName={overlayClassName}
          contentClassName={contentClassName}
          onCancel={handleOnCancel}
          onConfirm={handleOnConfirm}
          title={title}
        />
      ) : null}
    </ConfirmProviderContext.Provider>
  );
}
