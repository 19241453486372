import { gql } from '@apollo/client';

export const QBO_RESYNC_INVOICE = gql`
  mutation QboResyncInvoice($id: ID!) {
    qboResyncInvoice(id: $id) {
      success
    }
  }
`;

export const QBO_RESYNC_CONTACT = gql`
  mutation QboResyncContact($id: ID!) {
    qboResyncContact(id: $id) {
      success
    }
  }
`;

export const QBO_RESYNC_PAYMENT = gql`
  mutation QboResyncPayment($id: ID!) {
    qboResyncPayment(id: $id) {
      success
    }
  }
`;

export const QBO_RESYNC_CREDIT_MEMO = gql`
  mutation QboResyncCreditMemo($id: ID!) {
    qboResyncCreditMemo(id: $id) {
      success
    }
  }
`;

export const QBO_RESYNC_REFUND_PAYMENT = gql`
  mutation QboResyncRefundPayment($id: ID!) {
    qboResyncRefundPayment(id: $id) {
      success
    }
  }
`;
