import { useMemo } from 'react';
import { useQuery, WatchQueryOptions } from '@apollo/client';

import { FETCH_FIRM } from './useFirm.gql';
import {
  FetchFirmQuery,
  FetchFirmQueryVariables,
} from './__generated__/useFirm.gql';
import { FirmType } from '__generated__/globalTypes';

export default function useFirm(
  options?: Pick<
    WatchQueryOptions<FetchFirmQueryVariables>,
    'fetchPolicy' | 'nextFetchPolicy' | 'initialFetchPolicy'
  >
) {
  const { data, refetch, loading } = useQuery<
    FetchFirmQuery,
    FetchFirmQueryVariables
  >(FETCH_FIRM, options);

  const firm = useMemo(() => (data?.firm || {}) as FirmType, [data]);

  return {
    loading,
    firm,
    refetch,
  };
}
