import { useEffect } from 'react';

import { trackSessionStatusChange } from 'controllers/analytics';

export const useAutoTrackSessionStatusChange = () => {
  useEffect(() => {
    const handleVisibilityChanged = () => {
      trackSessionStatusChange(
        document.visibilityState === 'hidden' ? 'end' : 'start'
      );
    };

    document.addEventListener('visibilitychange', handleVisibilityChanged);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChanged);
    };
  }, []);
};
