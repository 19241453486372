import { gql } from '@apollo/client';

export const CALCULATE_SCHEDULED_PAYMENTS = gql`
  query CalculateScheduledPayments($input: CalculateScheduledPaymentsInput!) {
    plan: calculateScheduledPayments(calculateScheduledPaymentsInput: $input) {
      amount
      paymentDate
      status
    }
  }
`;
