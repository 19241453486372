import { useMemo } from 'react';
import { useIntl } from 'i18n';
import { BankAccountTypes } from '__generated__/globalTypes';

export default function useBankAccountType() {
  const { t } = useIntl();
  const bankAccountTypes: Record<BankAccountTypes, string> = useMemo(
    () => ({
      [BankAccountTypes.BUSINESS]: t('enum.bankAccountTypes.business'),
      [BankAccountTypes.PERSONAL]: t('enum.bankAccountTypes.personal'),
    }),
    [t]
  );

  const bankAccountTypesOptions: {
    value: BankAccountTypes;
    label: string;
  }[] = useMemo(
    () =>
      Object.entries(bankAccountTypes).map(([value, label]) => ({
        value: value as BankAccountTypes,
        label,
      })),
    [bankAccountTypes]
  );

  return {
    bankAccountTypesOptions,
  };
}
