export enum PermissionResources {
  ACCOUNT_STATEMENT = 'ACCOUNT_STATEMENT',
  APPCLOSE_CALENDAR_EVENT = 'APPCLOSE_CALENDAR_EVENT',
  ADMIN = 'ADMIN',
  APPCLOSE_NOTE = 'APPCLOSE_NOTE',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BILL = 'BILL', // Invoice from client point of view
  CALENDAR = 'CALENDAR',
  CHECK = 'CHECK',
  CONTACT = 'CONTACT',
  CONTACT_FILES = 'CONTACT_FILES',
  CLIENT = 'CLIENT',
  CLIENT_CONNECTION_REQUEST = 'CLIENT_CONNECTION_REQUEST',
  CREDIT_MEMO = 'CREDIT_MEMO',
  EMAIL = 'EMAIL', // confirm email, auth service
  EXPENSE = 'EXPENSE',
  EXPENSE_FILES = 'EXPENSE_FILES',
  EXPENSES_STAT = 'EXPENSES_STAT',
  FILES = 'FILES',
  FIRM = 'FIRM',
  FLAT_FEE = 'FLAT_FEE',
  FOLDERS = 'FOLDERS',
  GOOGLE_FIRM_INTEGRATION = 'GOOGLE_FIRM_INTEGRATION',
  GOOGLE_PERSONAL_INTEGRATION = 'GOOGLE_PERSONAL_INTEGRATION',
  MICROSOFT_PERSONAL_INTEGRATION = 'MICROSOFT_PERSONAL_INTEGRATION',
  INVOICE = 'INVOICE',
  INVOICE_FILES = 'INVOICE_FILES',
  INVOICES_STAT = 'INVOICES_STAT',
  MATTER = 'MATTER',
  MATTER_FILES = 'MATTER_FILES',
  PASSWORD = 'PASSWORD', // create password resource, auth service
  PAYMENT = 'PAYMENT',
  PAYMENT_FILES = 'PAYMENT_FILES',
  PAYMENT_REMINDER = 'PAYMENT_REMINDER',
  PAYMENT_SOURCE = 'PAYMENT_SOURCE',
  PAYMENTS_STAT = 'PAYMENTS_STAT',
  PRIVATE = 'PRIVATE',
  PROFILE = 'PROFILE',
  QBO = 'QBO',
  REFUND_PAYMENT = 'REFUND_PAYMENT',
  SIGNATURE = 'SIGNATURE',
  SIGNATURE_FILES = 'SIGNATURE_FILES',
  SIGNATURE_TEMPLATE = 'SIGNATURE_TEMPLATE',
  SIGNATURE_TEMPLATE_FILES = 'SIGNATURE_TEMPLATE_FILES',
  SITE_BUILDER = 'SITE_BUILDER',
  SUB_EXPENSE = 'SUB_EXPENSE',
  SUB_EXPENSE_CATEGORY = 'SUB_EXPENSE_CATEGORY',
  TEAM_MANAGEMENT = 'TEAM_MANAGEMENT',
  THIRD_PARTY_PAYEES = 'THIRD_PARTY_PAYEES',
  TIME_ENTRY = 'TIME_ENTRY',
  TIMER = 'TIMER',
  TIMER_ACTIVITY = 'TIMER_ACTIVITY',
  TRANSACTION = 'TRANSACTION',
  TRANSACTION_LEDGER = 'TRANSACTION_LEDGER',
  USER = 'USER',
  VOID_INVOICE = 'VOID_INVOICE',
  VOID_INVOICE_FILES = 'VOID_INVOICE_FILES',
  MODE = 'MODE',
  PUBLIC_API = 'PUBLIC_API',
  ANALYTIC_EVENT = 'ANALYTIC_EVENT',
  CHAT = 'CHAT',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
}

export enum PermissionActions {
  CLOSE = 'CLOSE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  LIST = 'LIST',
  PAY = 'PAY',
  PUBLIC_READ = 'PUBLIC_READ',
  READ = 'READ',
  REMIND = 'REMIND',
  REPORT = 'REPORT',
  RESET = 'RESET',
  SEND = 'SEND',
  SIGN = 'SIGN',
  SIGN_OUT = 'SIGN_OUT',
  UPDATE = 'UPDATE',
}
