import { FocusEvent, useCallback } from 'react';
import { Field } from 'formik';
import _upperFirst from 'lodash/upperFirst';

import { InputField } from '../..';
import { getFieldError } from '../../../../../utils/formik';

import {
  InputFormFieldType,
  InputFormFieldWrapperType,
} from './InputFormField.types';
import { useFormContext } from '../../../../common/Form';

const InputFormFieldWrapper = ({
  field: { value, ...field },
  form,
  onChange,
  mask,
  type,
  upperFirst,
  trimSpaces = true,
  onBlur,
  ...props
}: InputFormFieldWrapperType) => {
  const { handleBlur } = useFormContext();
  const isTypeNumber = type === 'number';

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (trimSpaces && !isTypeNumber && value) {
        form.setFieldValue(field.name, value.trim(), true);
      }

      onBlur?.(e);
      handleBlur(e);
    },
    [field.name, form, handleBlur, isTypeNumber, onBlur, trimSpaces, value]
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);

      const { selectionStart, selectionEnd } = event.target;

      let value: string | number | null = event.target.value;

      if (isTypeNumber) {
        const strValue = value.replace(/[^0-9]/g, '');
        value = strValue || strValue === '0' ? Number(strValue) : null;
      } else if (upperFirst) {
        value = value
          .split(' ')
          .map((part: string) => _upperFirst(part))
          .join(' ');
      }

      if (isTypeNumber || !type) {
        event.target.value = String(value);
        event.target.selectionStart = selectionStart;
        event.target.selectionEnd = selectionEnd;
      }

      form.setFieldValue(field.name, value, true);
    },
    [onChange, isTypeNumber, upperFirst, type, form, field.name]
  );

  return (
    <InputField
      {...{ ...field, ...props }}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      value={value || value === 0 ? value : ''}
      mask={mask}
      type={isTypeNumber ? undefined : type}
      error={getFieldError(form, field.name)}
    />
  );
};

export default function InputFormField(props: InputFormFieldType) {
  return <Field {...props} component={InputFormFieldWrapper} />;
}
