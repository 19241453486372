import { gql } from '@apollo/client';

import { ADDRESS_FRAGMENT } from 'components/common/Address';
import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const FETCH_PROFILE = gql`
  query FetchProfile {
    profile: getProfile {
      accessLevel
      active
      additionalEmails
      addresses {
        ...Address
      }
      email
      firstName
      gender
      hourlyRate
      id
      isOwner
      lastLogin
      lastName
      licenseNumber
      licensedInStates
      licensedSince
      limitedAccessDayQty
      middleName
      onboardStatus
      phoneNumbers {
        isPrimary
        number
        type
      }
      picture {
        ...Avatar
      }
      practiceAreas
      role
      searchable
      status
      timezone
      title
      website
    }
  }
  ${ADDRESS_FRAGMENT}
  ${AVATAR_FRAGMENT}
`;
