import { gql } from '@apollo/client';

import { FIRM_INFO_FRAGMENT } from 'components/common/FirmInfo';

export const FETCH_PAYMENT = gql`
  query FetchPayment($id: ID!) {
    payment: getPayment(id: $id) {
      id
      amount
      status
      contact {
        id
        name
        email
        isEntity
        billingContacts {
          id
          firstName
          lastName
          email
          type
        }
      }
    }
    firm: getFirm {
      ...FirmInfo
    }
  }
  ${FIRM_INFO_FRAGMENT}
`;

export const SEND_PAYMENT_RECEIPT = gql`
  mutation SendPaymentReceipt($sendPaymentInput: SendPaymentInput!) {
    sendPayment(sendPaymentInput: $sendPaymentInput) {
      id
    }
  }
`;
