import React, { ReactElement } from 'react';

import { InvoiceStatusesTypes } from '__generated__/globalTypes';
import Label, { LabelPropsType } from 'components/common/Label';
import { I18n } from 'i18n';

import { InvoiceStatusPropsType } from './InvoiceStatus.types';

const InvoiceStatusLabels: Record<
  InvoiceStatusesTypes,
  ReactElement<LabelPropsType>
> = {
  [InvoiceStatusesTypes.CREATED]: (
    <Label theme="info">
      <I18n id="enum.invoiceStatuses.notSent" />
    </Label>
  ),
  [InvoiceStatusesTypes.PAID]: (
    <Label theme="success">
      <I18n id="enum.invoiceStatuses.paid" />
    </Label>
  ),
  [InvoiceStatusesTypes.SENT]: (
    <Label theme="primary">
      <I18n id="enum.invoiceStatuses.sent" />
    </Label>
  ),
  [InvoiceStatusesTypes.VIEWED]: (
    <Label theme="primary">
      <I18n id="enum.invoiceStatuses.viewed" />
    </Label>
  ),
  [InvoiceStatusesTypes.VOIDED]: (
    <Label theme="tertiary">
      <I18n id="enum.invoiceStatuses.voided" />
    </Label>
  ),
  [InvoiceStatusesTypes.PARTIALLY_PAID]: (
    <Label theme="success">
      <I18n id="enum.invoiceStatuses.partiallyPaid" />
    </Label>
  ),
};

export default function InvoiceStatus({ status }: InvoiceStatusPropsType) {
  return InvoiceStatusLabels[status] || null;
}
