import { gql } from '@apollo/client';

export const ATTACHED_FILE_FRAGMENT = gql`
  fragment AttachedFile on S3FileType {
    id
    name
    size
    url
  }
`;
export const ATTACHED_FILE_FULL_FRAGMENT = gql`
  fragment AttachedFileFull on S3FileType {
    id
    dateAdded
    mimetype
    name
    size
    url
  }
`;
