import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Loader, Modal } from '@appclose/core';

import { useIntl } from 'i18n';
import useProfile from 'hooks/useProfile';
import notification from 'controllers/notification';
import { track } from 'controllers/analytics';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';
import { INITIAL_BASE_CONTACT, INITIAL_BASE_ENTITY } from 'constants/contacts';

import {
  CreateContactMutation,
  CreateContactMutationVariables,
} from './__generated__/ContactPopupModal.gql';
import ContactPopupForm, {
  ContactPopupFormValuesType,
} from './components/ContactPopupForm';
import { CREATE_CONTACT } from './ContactPopupModal.gql';
import {
  ContactPopupModalPropsType,
  ContactPopupModalTabs,
} from './ContactPopupModal.types';

export default function ContactPopupModal({
  onCancel,
  onComplete,
}: ContactPopupModalPropsType) {
  const { t } = useIntl();
  const { profile, loading } = useProfile();
  const [createContact] = useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CREATE_CONTACT);

  const handleOnCancel = useCallback(() => {
    track(EventNames.CANCEL_ENTRY, {
      entry_type: EntryTypes.CONTACT,
      source_type: SourceTypes.POPUP_MODAL,
    });

    onCancel();
  }, [onCancel]);

  const handleOnSubmit = useCallback(
    async ({ contact, entity, selectedTab }: ContactPopupFormValuesType) => {
      const variables =
        selectedTab === ContactPopupModalTabs.ENTITY
          ? {
              input: {
                entity: {
                  ...entity,
                  originatingAttorney: profile?.id,
                },
              },
            }
          : {
              input: {
                contact: {
                  ...contact,
                  originatingAttorney: profile?.id,
                },
              },
            };

      const result = await createContact({
        variables: variables as CreateContactMutationVariables,
      });

      notification().entityCreated(
        t('modal.popup.contact.notification.create.success')
      );

      result.data?.createContact && onComplete(result.data.createContact);
    },
    [createContact, onComplete, profile?.id, t]
  );

  let initialValues = {
    selectedTab: ContactPopupModalTabs.INDIVIDUAL,
    entity: {
      ...INITIAL_BASE_ENTITY,
    },
    contact: {
      ...INITIAL_BASE_CONTACT,
    },
  } as ContactPopupFormValuesType;

  return (
    <Modal onClose={handleOnCancel} title={t('modal.popup.contact.title')}>
      {loading ? (
        <Loader />
      ) : (
        <ContactPopupForm
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
        />
      )}
    </Modal>
  );
}
