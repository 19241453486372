import { gql } from '@apollo/client';

export const FETCH_INVOICE = gql`
  query FetchInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      id
      link
    }
  }
`;
