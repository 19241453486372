import React from 'react';
import { SelectFormField } from '@appclose/core';

import useAccessLevels from 'hooks/useAccessLevels';
import { useIntl } from 'i18n';

import { AccessLevelSelectFormFieldPropsType } from './AccessLevelSelectFormField.types';

export default function AccessLevelSelectFormField({
  name = 'accessLevel',
  label,
  ...rest
}: AccessLevelSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { accessLevelsOptions } = useAccessLevels();

  return (
    <SelectFormField
      name={name}
      label={label || t('field.accessLevelSelect.label')}
      options={accessLevelsOptions}
      {...rest}
    />
  );
}
