import { gql } from '@apollo/client';

export const FETCH_QBO = gql`
  query FetchQBO {
    qbo: getQboIntegrationDetails {
      connected
      syncStatus
      syncQty
      errorQty
      connectionError
      expectedCompanyName
    }
  }
`;

export const ON_QBO_SYNC_FINISHED = gql`
  subscription QboSyncFinished {
    qboSyncFinished {
      success
      firmId
    }
  }
`;
