import React from 'react';
import { useQuery } from '@apollo/client';
import { Loader, Tabs, TabList, TabPanel, Tab } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import InvoiceInfo from 'components/common/InvoiceInfo';
import { I18n } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import {
  FetchInvoiceQuery,
  FetchInvoiceQueryVariables,
} from './__generated__/InvoiceDetailsModal.gql';
import InvoiceActions from './components/InvoiceActions';
import Info from './components/Info';
import InvoiceHistory from './components/InvoiceHistory';
import InvoiceReminders from './components/InvoiceReminders';
import InvoiceTransactionsAsync from './components/InvoiceTransactions/InvoiceTransactionsAsync';
import { FETCH_INVOICE } from './InvoiceDetailsModal.gql';
import {
  InvoiceDetailsModalPropsType,
  InvoiceDetailsModalTabs,
} from './InvoiceDetailsModal.types';
import styles from './InvoiceDetailsModal.module.scss';

export default function InvoiceDetailsModal({
  id,
  onClose,
  defaultTab = InvoiceDetailsModalTabs.INFO,
}: InvoiceDetailsModalPropsType) {
  const { loading, data } = useQuery<
    FetchInvoiceQuery,
    FetchInvoiceQueryVariables
  >(FETCH_INVOICE, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const invoice = data?.invoice;
  const { filesLoading, files } = useListFiles({
    entityType: Entities.INVOICE,
    input: { filter: { entityIds: [id], strictFilter: false } },
  });

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader className={styles.header}>
        <ModalPageTitle>
          <I18n
            id="modal.invoiceDetails.title"
            values={{
              number: invoice?.invoiceNumber,
            }}
          />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading || filesLoading ? (
          <Loader />
        ) : (
          invoice && (
            <>
              <InvoiceActions invoice={invoice} />
              <InvoiceInfo
                invoice={invoice}
                mode="active"
                className={styles.invoiceInfo}
              />
              <Tabs defaultIndex={defaultTab}>
                <TabList className={styles.tabList}>
                  <Tab>
                    <I18n id="modal.invoiceDetails.tab.info" />
                  </Tab>
                  <Tab>
                    <I18n id="modal.invoiceDetails.tab.transactions" />
                  </Tab>
                  <Tab>
                    <I18n id="modal.invoiceDetails.tab.history" />
                  </Tab>
                  <Tab>
                    <I18n id="modal.invoiceDetails.tab.reminders" />
                  </Tab>
                </TabList>
                <TabPanel>
                  <Info invoice={invoice} files={files?.items} />
                </TabPanel>
                <TabPanel>
                  <InvoiceTransactionsAsync id={id} />
                </TabPanel>
                <TabPanel>
                  <InvoiceHistory invoice={invoice} />
                </TabPanel>
                <TabPanel>
                  <InvoiceReminders invoice={invoice} />
                </TabPanel>
              </Tabs>
            </>
          )
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
